import { telemetryService } from "@amadeus-cytric/cytric-teams-react-common-library";
import { useTranslation } from "react-i18next";
import RedirectionTypeEnum from "../../../../../../../../common/models/redirection/RedirectionTypeEnum";
import RedirectionService from "../../../../../../../../services/redirection/RedirectionService";
import { useAppSelector } from "../../../../../../../../store/StoreHooks";
import { getRailPermissions } from "../../../../../../../../store/flows/FlowsPermissionsSelector";
import { getProductsByTypeV1 } from "../../../../../../../../store/trip-details-v1/selectors/trip-detail-v1-selectors";
import { getRailDetailsGroupedByDateV1 } from "../../../../../../../../store/trip-details-v1/selectors/trip-detail-v1-selectors-rail";
import { isValidUrlAndDomain } from "../../../../../../../../utils/validation.utils";
import { SegmentType } from "../../../../../../UpcomingTrips/CardBookTripButtons/model/Permissions";
import { TRIP_PRODUCT_RAIL } from "../../../../../../data/constants";
import ShowRail from "./ShowRail";

function Rail({ pastTrip }: any) {
  const { t } = useTranslation();

  const tripDetailV1 = useAppSelector(
    (state) => state.tripDetailV1.tripDetails
  );

  const railSegments = useAppSelector((state) =>
    getProductsByTypeV1(state, TRIP_PRODUCT_RAIL)
  );

  const permissions: SegmentType = useAppSelector((state) =>
    getRailPermissions(state)
  );

  const railDetails = useAppSelector(getRailDetailsGroupedByDateV1);

  const bookRail = async () => {
    const tripId = tripDetailV1.id;

    telemetryService.trackEvent(
      { name: "travel-booking-bookTrain" },
      {
        numOfTrains: 0,
        tripId: tripDetailV1.id,
      }
    );

    const redirectionInput = {
      segmentType: RedirectionTypeEnum.ADD_RAIL,
      tripId,
    };

    const urlObject = await RedirectionService.getCytricUrl(redirectionInput);
    if (urlObject?.data?.data?.cytricUrl) {
      const url = urlObject.data.data.cytricUrl as string;
      if (isValidUrlAndDomain(url)) {
        window.open(url, "_blank", "noopener noreferrer");
      }
    }
  };

  const containsRail =
    tripDetailV1?.products?.filter(
      (product: any) => product.productType === "Trip_Product_Train"
    ).length > 0;

  return (
    <ShowRail
      containsRail={containsRail}
      railDetails={railDetails}
      pastTrip={pastTrip}
      railSegments={railSegments}
      permissions={permissions}
      bookRail={bookRail}
      t={t}
    />
  );
}

export default Rail;
