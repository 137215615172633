import { Flex, Loader } from "@fluentui/react-northstar";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../store/StoreHooks";
import { contextSelector } from "../../store/app-settings/AppSettingsSelector";
import ShareJoin from "./ShareJoin";
import Styles from "./ShareJoinContainer.module.scss";
import EmptyChat from "./components/empty-chat/EmptyChat";
import ShareJoinProvider from "./context/ShareJoinContext";

function ShareJoinContainer(props: any) {
  const context = useAppSelector((state) => contextSelector(state));
  const propValue = props;
  const [isEmptyChat, setIsEmptyChat] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    setTimeout(() => {
      setIsEmptyChat(
        context?.chat?.id.includes("preview") ||
          (!context?.chat && !context?.sharePointSite)
      );
      setIsLoading(false);
    }, 1000);
  }, [context]);

  return isLoading ? (
    <Flex
      className={Styles.overlay}
      styles={(theme) => ({
        backgroundColor:
          theme.theme.siteVariables?.colorScheme?.default.background,
      })}
    >
      <Loader
        className={Styles.spinner}
        aria-label={t("shareTrip.loaderLabel")}
      />
    </Flex>
  ) : (
    <ShareJoinProvider>
      {!isLoading && isEmptyChat ? (
        <EmptyChat
          themeName={context?.app?.theme === "default" ? "light" : "dark"}
        />
      ) : (
        <ShareJoin propValue={propValue} />
      )}
    </ShareJoinProvider>
  );
}

export default ShareJoinContainer;
