/* eslint-disable no-plusplus */
import DateUtils from "../../../utils/date.utils";
import ValidationUtils from "../../../utils/validation.utils";
import { decodeIfSharedFromCytric } from "../../share-from-classic/functions/utils";
import {
  DESTINATION_FALLBACK_IMAGE,
  PROVIDER_FALLBACK_LOGO,
  UPCOMING_TRIPS,
  UPCOMING_TRIP_DETAIL,
} from "../data/constants";
import { ISegmentV1 } from "../UpcomingTripDetail/Widgets/BookingDetailsV1/SegmentTabs/Tabs/components/Accordion/Accordion.model";
import { IFlightV1 } from "../UpcomingTripDetail/Widgets/BookingDetailsV1/SegmentTabs/Tabs/Flight/Flight.model";
import { IRailV1 } from "../UpcomingTripDetail/Widgets/BookingDetailsV1/SegmentTabs/Tabs/Train/Rail.model";
import { ITrip } from "../UpcomingTrips/UpcomingTrips.model";
import { IRailTrip, ItineraryTypes, Segments } from "./trip-utils.model";

export const hasItineraryTypes = (trip: ITrip, ...types: ItineraryTypes[]) =>
  trip?.products?.[0]?.hasCar === types.includes("car") &&
  trip?.products?.[0]?.hasRail === types.includes("rail") &&
  trip?.products?.[0]?.hasAir === types.includes("air") &&
  trip?.products?.[0]?.hasHotel === types.includes("hotel");

export const composeBounds = (segments: Segments[]) => {
  const bounds: any[] = [];

  let direction = "1";
  let temp: Segments[] = [];
  segments?.forEach((segment: any) => {
    if (
      temp.length > 0 &&
      (segment?.id === "0" || segment?.direction > direction)
    ) {
      bounds.push(temp);
      direction = segment?.direction;
      temp = [];
    }
    temp.push(segment);
  });
  bounds.push(temp);

  return bounds;
};

export const composeBoundsV1 = (segments: any) => {
  const bounds: any[] = [];

  let boundNumber = "1";
  let temp: any[] = [];

  segments?.forEach((segment: any) => {
    if (
      temp.length > 0 &&
      (segment?.id === "0" || segment?.boundNumber > boundNumber)
    ) {
      bounds.push(temp);
      boundNumber = segment?.boundNumber;
      temp = [];
    }
    temp.push(segment);
  });
  bounds.push(temp);

  return bounds;
};

export const composeRailBoundsV1 = (segments: any) => {
  const bounds: any[] = [];

  let stepNumber = "1";
  let temp: any[] = [];

  segments?.forEach((segment: any) => {
    if (
      temp.length > 0 &&
      (segment?.id === "0" || segment?.stepNumber > stepNumber)
    ) {
      bounds.push(temp);
      stepNumber = segment?.stepNumber;
      temp = [];
    }
    temp.push(segment);
  });
  bounds.push(temp);

  return bounds;
};

const getRailStations = (trip: IRailTrip) => {
  try {
    const segments = trip?.products?.[0]?.products?.filter(
      (product: { productType: string }) =>
        product.productType === "Trip_Product_Train"
    );
    const bounds = composeBounds(segments);

    const destinationStations = bounds.reduce((acc: string[], bound: any[]) => {
      const lastIndex = bound.length - 1 || 0;
      const lastStation = bound?.[lastIndex]?.arrival?.name;

      acc.push(lastStation);
      return acc;
    }, []);

    const firstStation = trip?.products[0]?.products?.[0].departure?.name;
    const lastStation = destinationStations[destinationStations.length - 1];

    if (firstStation === lastStation) {
      destinationStations.pop();
    }

    return destinationStations;
  } catch {
    return [];
  }
};

const formatName = (name: string, beforeText?: string) =>
  beforeText ? `${beforeText} ${name}` : name;

export const composeTripName = (trip: any, beforeText?: string) => {
  const DELIMITER = UPCOMING_TRIPS.delimiterForDestinations;
  const TRIP_NAME_FALLBACK = UPCOMING_TRIP_DETAIL.breadcrumb.currentPage;

  try {
    if (trip?.name) {
      return trip?.name;
    }
    if (hasItineraryTypes(trip, "car")) {
      return UPCOMING_TRIPS.tripTitle;
    }
    if (
      hasItineraryTypes(trip, "rail") ||
      hasItineraryTypes(trip, "rail", "car")
    ) {
      const destinationRailStations = getRailStations(trip)?.filter(Boolean);
      if (destinationRailStations?.length > 0) {
        return formatName(destinationRailStations.join(DELIMITER), beforeText);
      }
      return TRIP_NAME_FALLBACK;
    }

    const destinationCities: string[] =
      trip?.destinationCities?.filter(Boolean);
    if (destinationCities?.length > 0) {
      return formatName(destinationCities.join(DELIMITER), beforeText);
    }
  } catch {
    return TRIP_NAME_FALLBACK;
  }

  return TRIP_NAME_FALLBACK;
};

export const getTotalTravelDurationV1 = (durations: string[]) => {
  const withoutPT = durations.map((durat) =>
    durat.toLowerCase().split("pt").pop()
  );

  const days = withoutPT.map((durat) =>
    Number(durat?.toLowerCase().slice(0, durat.lastIndexOf("d")))
  );
  const hours = withoutPT.map((durat) =>
    Number(
      durat
        ?.toLowerCase()
        .slice(durat.lastIndexOf("d") + 1, durat.lastIndexOf("h"))
    )
  );
  const minutes = withoutPT.map((durat) =>
    Number(
      durat
        ?.toLowerCase()
        .slice(durat.lastIndexOf("h") + 1, durat.lastIndexOf("m"))
    )
  );

  const sumMillisFromMins = minutes.reduce(
    (total, minute) => total + minute * 60 * 1000,
    0
  );

  const sumMillisFromHours = hours.reduce(
    (total, hour) => total + hour * 60 * 60 * 1000,
    0
  );

  const sumMillisFromDays = days.reduce(
    (total, day) => total + day * 24 * 60 * 60 * 1000,
    0
  );

  const durationInMilliseconds =
    sumMillisFromMins + sumMillisFromHours + sumMillisFromDays;

  return DateUtils.getHoursFromMilliseconds(durationInMilliseconds);
};

export const getStopDuration = (first: string, second: string) => {
  const arrivalDateTime = new Date(first).getTime();
  const departureDateTime = new Date(second).getTime();

  return DateUtils.getHoursFromMilliseconds(
    departureDateTime - arrivalDateTime
  );
};

export const getCountryCode = (segment: any): string => {
  if ("arrivalAirportLocation" in segment) {
    return segment.arrivalAirportLocation.address.countryCode;
  }
  if ("countryCode" in segment.arrival) {
    return segment.arrival.countryCode;
  }
  if ("address" in segment.arrival) {
    return segment.arrival.address.countryCode;
  }

  return "";
};

export const getCityName = (segment: any): string | undefined => {
  if ("arrivalAirportLocation" in segment) {
    const { cityName } = segment.arrivalAirportLocation.address;

    return cityName !== "" ? cityName : segment.arrivalAirportLocation.name;
  }
  if ("address" in segment.arrival) {
    const { address } = segment.arrival;

    return typeof address === "string" ? address : segment.arrival.name;
  }
  return "";
};

export const getStopDetailsV1 = (
  firstSegment: ISegmentV1 | undefined,
  secondSegment: ISegmentV1 | undefined,
  getters: any
) => {
  try {
    const {
      getDepartureTime,
      getArrivalTime,
      getDepartureStation,
      getArrivalStation,
    } = getters;

    const stopDuration = getStopDuration(
      getArrivalTime(firstSegment),
      getDepartureTime(secondSegment)
    );

    const stopInCity =
      firstSegment?.arrival &&
      `${getCityName(firstSegment)}, ${getCountryCode(firstSegment)}`;

    const isTheSameStation =
      getArrivalStation(firstSegment) === getDepartureStation(secondSegment);

    return [stopDuration, stopInCity, isTheSameStation];
  } catch (e: any) {
    return [];
  }
};

export const getAllStopsDetailsV1 = (bound: any, getters: {}) => {
  const stopsIn = [];

  for (let i = 0; i < bound.length - 1; i++) {
    stopsIn.push(getStopDetailsV1(bound[i], bound[i + 1], getters));
  }

  return stopsIn;
};

export const getServiceProvidersV1 = (
  bound: IFlightV1[] | IRailV1[],
  getProviderName: any,
  getProviderNumber: any
) => {
  const serviceProviders = bound.map((segment: IFlightV1 | IRailV1) =>
    getProviderName(segment)
  );

  const serviceProvidersSet = new Set(serviceProviders);

  const serviceProvidersWithNumbers: { name: string; numbers: string[] }[] = [];
  serviceProvidersSet.forEach((item) => {
    const providerWithNumbers: { name: string; numbers: string[] } = {
      name: item,
      numbers: [],
    };
    serviceProvidersWithNumbers.push(providerWithNumbers);
  });

  bound.forEach((segment: IFlightV1 | IRailV1) =>
    serviceProvidersWithNumbers.map(
      (item: { name: string; numbers: string[] }, index) => {
        if (item.name === getProviderName(segment)) {
          serviceProvidersWithNumbers[index].numbers.push(
            getProviderNumber(segment)
          );
        }
        return serviceProvidersWithNumbers;
      }
    )
  );
  return serviceProvidersWithNumbers;
};

export const handleProviderLogoError = (e: React.BaseSyntheticEvent) => {
  e.currentTarget.src = PROVIDER_FALLBACK_LOGO;
};

export const validateProviderLogoUrl = (logoUrl: string) =>
  ValidationUtils.isValidHttpUrl(logoUrl) ? logoUrl : PROVIDER_FALLBACK_LOGO;

export const handleDestinationImageError = (e: React.BaseSyntheticEvent) => {
  e.currentTarget.src = DESTINATION_FALLBACK_IMAGE;
};

export const validateDestinationImageUrl = (imageUrl = "") =>
  ValidationUtils.isValidHttpUrl(imageUrl)
    ? imageUrl
    : DESTINATION_FALLBACK_IMAGE;

export const enterEventHandler = (event: any) => {
  if (event.keyCode === 32 || event.keyCode === 13) {
    if (event?.target) {
      event.target.click();
    }
  }
};

export const saveToSessionStorage = (key: string, value: object) => {
  sessionStorage.setItem(key, JSON.stringify(value));
};

export const getFromSessionStorage = (key: string) => {
  const dataFromStorage = sessionStorage.getItem(key);
  return dataFromStorage ? JSON.parse(dataFromStorage) : {};
};

const getTripIdFromJSON = (subEntityId: any) => {
  try {
    const subEntityParsed = JSON.parse(subEntityId);
    // eslint-disable-next-line prefer-destructuring
    return subEntityParsed.tripId;
  } catch (error) {
    return subEntityId;
  }
};

const getTripIdFromParams = (subEntityId: any) => {
  const subEntityParams = subEntityId.split("?");
  return subEntityParams[0];
};

// NOTE: There is space for unification, if done, it will be a separate PR.
export const extractTripId = (subEntityId: any) => {
  if (subEntityId.includes("?")) {
    return getTripIdFromParams(subEntityId);
  }
  if (subEntityId.includes("tripId")) {
    return getTripIdFromJSON(subEntityId);
  }
  return subEntityId;
};

export const getSubEntityId = (subPageId: string | undefined) => {
  const isTripIdOnly = subPageId != null ? /^\d+$/.test(subPageId) : false;
  return isTripIdOnly ? subPageId : decodeIfSharedFromCytric(subPageId);
};
