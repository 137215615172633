/* eslint-disable react/react-in-jsx-scope */
import { telemetryService } from "@amadeus-cytric/cytric-teams-react-common-library";
import { Share20Regular } from "@fluentui/react-icons";
import {
  Box,
  Breadcrumb,
  Button,
  ChevronDownIcon,
  ChevronEndIcon,
  Flex,
  MenuButton,
  Text,
} from "@fluentui/react-northstar";
import { BotUrlDialogInfo, app, dialog } from "@microsoft/teams-js";
import { KeyboardEvent } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Responsive from "../../../../common/components/responsive/Responsive";
import { complexTitleNameManager } from "../../../../helpers/TripTitleName";
import { useAppDispatch, useAppSelector } from "../../../../store/StoreHooks";
import { updateTravelTripId } from "../../../../store/travel-trip-id/TravelTripIdSlice";
import GetAppRoot from "../../../../utils/GetAppRoot";
import { KeepSessionActions } from "../../../../utils/KeepSessionFunctions";
import ActivityClass from "../../../../utils/KeepUserActivityContext";
import HostSettings from "../../../../utils/host.settings";
import ConfirmationPageButton from "../../ConfirmationPageButton";
import { CancelPopUpProvider } from "../../cancel/context/CancelPopUpContext";
import ContainsString from "../../utils/ContainsString";
import { extractTripId, getSubEntityId } from "../../utils/trip-utils";
import Styles from "./Breadcrumb.module.scss";

export function Buttons(
  showText: boolean,
  trip: any,
  tripName: string,
  onShowShareTrip: () => void,
  t: any
) {
  const { id: tripId } = trip;
  return (
    <Flex className={showText ? Styles.actions : Styles.actionsMobile}>
      <Button
        aria-label={t(
          "upcoming_trips_dashboard.tripDetails.breadcrumb.buttons.share"
        )}
        data-testid="shareButtonTrip"
        content={
          showText
            ? t("upcoming_trips_dashboard.tripDetails.breadcrumb.buttons.share")
            : ""
        }
        text
        className={
          showText ? Styles.actions__shareTrip : Styles.actions__shareTripMobile
        }
        onClick={onShowShareTrip}
        icon={<Share20Regular />}
      />
      <CancelPopUpProvider>
        <ConfirmationPageButton
          tripId={tripId}
          tripName={tripName}
          showText={showText}
        />
      </CancelPopUpProvider>
    </Flex>
  );
}

function TripBreadcrumb({ pastTrip }: any) {
  const trip = useAppSelector((state) => state.tripDetailV1.tripDetails);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const breadcrumbClickHandler = () => {
    localStorage.removeItem("KeepSessionCache");
    localStorage.setItem("tripDetailTab", "");
    const activity = new ActivityClass();
    activity.removeElementOrChildren(KeepSessionActions.GENERIC_DETAILS);
    localStorage.removeItem("breadCrumbInformation");
    // when arriving from the chat if we do a redirection to travel the context still has the trip id the first time
    let deepLink = "";
    let shouldUseDeeplink = false;
    app.getContext().then((context: app.Context) => {
      if (context) {
        const subEntityId = getSubEntityId(context?.page?.subPageId);
        if (subEntityId) {
          const tripId = extractTripId(subEntityId);
          dispatch(updateTravelTripId({ tripId }));
          // This is a workaround to avoid the subentityId to have value when going to the travel dahsboard,
          // since it is not needed and it will trigger a redirection to the trip details all the time.
          if (
            ContainsString(subEntityId, [
              "segmentType",
              "viewTripDetails",
              "shareTransferEnableAll",
            ])
          ) {
            deepLink = `https://teams.microsoft.com/l/entity/${HostSettings.teamsAppId}/travel`;
            shouldUseDeeplink = true;
          }
        }
      }
      if (shouldUseDeeplink) {
        app.openLink(deepLink);
      } else {
        history.push(`/travel`);
      }
    });
  };

  const tripName = complexTitleNameManager(t, trip);

  const taskModuleUrl = "share-join";

  const onShowShareTrip = (): void => {
    if (trip) {
      telemetryService.trackEvent({ name: "travel-tripDetails-sharePopup" });

      const taskModuleInfo: BotUrlDialogInfo = {
        title: t("upcoming_trips_dashboard.upcomingTrips.tripCardShareTitle"),
        url: `${GetAppRoot()}/index.html#/${taskModuleUrl}?origin=sharetrip&tripId=${
          trip.id
        }`,
        completionBotId: HostSettings.getBotId,
        size: {
          width: 600,
          height: 570,
        },
      };
      dialog.url.bot.open(taskModuleInfo);
    }
  };

  const breadcrumbMobile = (
    <div className={Styles.breadcrumb__mobileMenuWrapper}>
      <Text
        content={tripName}
        aria-label={tripName}
        className={Styles.breadcrumb__mobileMenu}
        styles={(theme) => ({
          color: theme.theme.siteVariables.tripBreadcrumb?.currentPage,
        })}
      />
      <ChevronDownIcon
        size="small"
        className={Styles.breadcrumb__divider}
        styles={(theme) => ({
          color: theme.theme.siteVariables.tripBreadcrumb?.path,
        })}
      />
    </div>
  );

  const handleBreadcrumbKeyDown = (
    e: React.KeyboardEvent<HTMLDivElement>,
    onClickHandler: () => void
  ) => {
    if (e.key === "Enter" || e.key === " ") {
      onClickHandler();
    }
  };

  return (
    <Box
      className={Styles.breadcrumb}
      styles={(theme) => ({
        background: theme.theme.siteVariables.tripBreadcrumb?.background,
        borderBottomColor:
          theme.theme.siteVariables.tripBreadcrumb?.mobileBorderBottom,
      })}
    >
      <Responsive forMinSize="desktopSmall">
        <div className={Styles.desktop}>
          <Breadcrumb aria-label="breadcrumb">
            <Breadcrumb.Item
              onClick={breadcrumbClickHandler}
              onKeyDown={(e: KeyboardEvent<HTMLDivElement>) =>
                handleBreadcrumbKeyDown(e, breadcrumbClickHandler)
              }
              className={Styles["breadcrumb__my-trips"]}
              styles={(theme) => ({
                color: theme.theme.siteVariables.tripBreadcrumb?.path,
              })}
            >
              <Breadcrumb.Link
                title={t("tripDetails.optionBreadcrumb1")}
                className={Styles.link}
              >
                {t(
                  "upcoming_trips_dashboard.tripDetails.breadcrumb.previousPage"
                )}
              </Breadcrumb.Link>
            </Breadcrumb.Item>
            <Breadcrumb.Divider>
              <ChevronEndIcon
                size="small"
                className={Styles.breadcrumb__divider}
                styles={(theme) => ({
                  color: theme.theme.siteVariables.tripBreadcrumb?.path,
                })}
              />
            </Breadcrumb.Divider>
            <Breadcrumb.Item
              style={{ gap: "4px" }}
              className={Styles.breadcrumb__trip}
              styles={(theme) => ({
                color: theme.theme.siteVariables.tripBreadcrumb?.currentPage,
              })}
            >
              <div title={`${tripName} ${t("tripDetails.optionBreadcrumb2")}`}>
                <span
                  aria-hidden
                  className={Styles["breadcrumb__destination-name"]}
                  data-testid="breadcrumb-trip-name"
                >
                  {tripName}
                </span>
              </div>
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </Responsive>
      <Responsive forMaxSize="tablet">
        <MenuButton
          className={Styles.mobile}
          trigger={breadcrumbMobile}
          menu={[
            t("upcoming_trips_dashboard.tripDetails.breadcrumb.previousPage"),
          ]}
          on="click"
          onMenuItemClick={breadcrumbClickHandler}
        />
      </Responsive>

      {!pastTrip && (
        <>
          <Responsive forMaxSize="mobile">
            {Buttons(false, trip, tripName, onShowShareTrip, t)}
          </Responsive>
          <Responsive forMinSize="tablet">
            {Buttons(true, trip, tripName, onShowShareTrip, t)}
          </Responsive>
        </>
      )}
    </Box>
  );
}

export default TripBreadcrumb;
