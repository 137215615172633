/* eslint-disable react/require-default-props */
import {
  Alert,
  Button,
  ExclamationCircleIcon,
  Flex,
  Text,
} from "@fluentui/react-northstar";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useAppDispatch } from "../../../store/StoreHooks";
import HandleTravelCytricRedirection from "../../../utils/HandleTravelCytricRedirection";
import RedirectionTypeEnum from "../../models/redirection/RedirectionTypeEnum";
import styles from "./TaskModuleResponseError.module.scss";

interface TaskModuleResponseProps {
  alertText: string;
  id: string | null;
}

function TaskModuleResponseError({ alertText, id }: TaskModuleResponseProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [isVisible, setIsVisible] = useState(true);

  if (!isVisible) {
    return null;
  }

  const redirectToConfirmationPage = () => {
    localStorage.removeItem("KeepSessionCache");
    localStorage.setItem("tripDetailTab", "");

    const redirectionInput = {
      segmentType: RedirectionTypeEnum.CONFIRMATION_PAGE,
      tripId: id,
    };

    const breadcrumbData = [
      {
        label: "upcoming_trips_dashboard.tripDetails.breadcrumb.previousPage",
        url: "/travel",
        translation: true,
      },
      {
        label: "upcoming_trips_dashboard.cancel.cancelButton",
        url: "",
        translation: true,
      },
    ];

    HandleTravelCytricRedirection({ breadcrumbData, t, dispatch });

    const context = encodeURIComponent(JSON.stringify(redirectionInput));
    history.push(`/cytricweb?context=${context}&type=travel`);
  };

  const contentCancel = () => (
    <Flex
      space="between"
      vAlign="center"
      gap="gap.small"
      styles={{ padding: "6px" }}
    >
      <Text content={alertText} />
      <div>
        <Button
          flat
          data-testid="button-manage-trip-alert"
          content={t("upcoming_trips_dashboard.cancel.manageTrip")}
          onClick={redirectToConfirmationPage}
        />
      </div>
    </Flex>
  );

  return (
    <Alert
      styles={{ minHeight: "44px" }}
      content={contentCancel()}
      danger
      dismissible
      dismissAction={{
        "aria-label": t("moduleResponse.error.closeButton"),
      }}
      icon={<ExclamationCircleIcon styles={{ minWidth: "16px" }} />}
      onVisibleChange={() => setIsVisible(false)}
      className={styles.errorMessage}
      data-testid="cancel-alert-error"
      visible
      role="alert"
      autoFocus
    />
  );
}

export default TaskModuleResponseError;
