import { Button, Dropdown } from "@fluentui/react-northstar";
import { BookingTargets } from "../../../../../../UpcomingTrips/CardBookTripButtons/model/Permissions";
import Styles from "./Car.module.scss";

function showComponent(
  carPerm: BookingTargets[] | null,
  t: any,
  getCarValue: (_e: any, val: any) => void,
  bookCar: () => void,
  bookTransfer: () => void
) {
  if (carPerm && carPerm.length > 1) {
    return (
      <Dropdown
        inverted
        styles={(theme) => ({
          borderColor: theme.theme.siteVariables?.colorScheme?.default.border,
          backgroundColor:
            theme.theme.siteVariables?.colorScheme?.default.background2,
        })}
        className={Styles.dropDown}
        items={[
          t("upcoming_trips_dashboard.upcomingTrips.newBook.Car"),
          t("upcoming_trips_dashboard.upcomingTrips.newBook.Transfer"),
        ]}
        data-showValueid="button-card-book-car"
        data-testid="button-card-book-car-dropdown"
        placeholder={t(
          "upcoming_trips_dashboard.tripDetails.placeholder.carTransfer"
        )}
        onSearchQueryChange={getCarValue}
      />
    );
  }
  if (carPerm && carPerm[0].type === "CAR_BOOKING") {
    return (
      <Button
        aria-label={t(
          "upcoming_trips_dashboard.tripDetails.newProduct.buttons.car"
        )}
        content={t(
          "upcoming_trips_dashboard.tripDetails.newProduct.buttons.car"
        )}
        onClick={() => bookCar()}
        data-testid="button-card-book-car"
        flat
      />
    );
  }
  return (
    <Button
      aria-label={t(
        "upcoming_trips_dashboard.tripDetails.newProduct.buttons.transfer2"
      )}
      className={Styles.rightButton}
      content={t(
        "upcoming_trips_dashboard.tripDetails.newProduct.buttons.transfer2"
      )}
      onClick={() => bookTransfer()}
      data-testid="button-card-book-transfer"
      flat
    />
  );
}

export default showComponent;
