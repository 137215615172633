export const TRIP_TYPE = {
  roundTrip: { code: "roundtrip", label: "Roundtrip" },
  oneWay: { code: "oneway", label: "Oneway" },
};

export const TRIP_TYPE_TIME = {
  departure: { code: "DEPARTURE", label: "Departure" },
  arrival: { code: "ARRIVAL", label: "Arrival" },
};

// Endpoints
export const LOCATION = "loc/v0/corporation/reference-data/locations";
export const SETTINGS = "system/v0/corporation/settings/trip-purposes";
export const RISK_ADVISORY =
  "travel-safety/v0/duty-of-care/diseases/covid19-area-report";
export const USER_PREFERENCES = "um/v0/corporation/users/current/preferences";
export const FLIGHT_OFFERS = "fo/v0/corporation/shopping/flight-offers";
export const USER_TIME_PREFERENCES =
  "system/v0/corporation/settings/flight-search";
export const COVID_SERVICES = "system/v0/corporation/settings/covid-services";

// expenses dashboard constants
export const SERVER = "http://localhost:3000";
export const TRAVELER = "Traveler";
export const APPROVER = "Approver";
export const EXPENSE_STATEMENT_OWNER = "ExpenseStatementOwner";
export const TYPE_OF_SPENDING = "Type of spending";
export const STATUS_OF_EXPENSES = "Status of expenses";
export const APPROVER_CREDENTIALS =
  "pin=RFjD8U&psc=51098759&role=expenseApproverRestricted";
// "pin=KLj711&psc=64572822&role=expenseApproverFinal";
export const TRAVELER_CREDENTIALS = "pin=KNj21K&psc=20113039&role=traveller";
export const MERGE_TYPE = "Merge";
export const DONT_MERGE_TYPE = "Don't merge";
export const MERGE_ERROR_TYPE = "Merge error";
export const DONT_MERGE_TYPE_EVENT = "reject";
export const CLOSE_TYPE = "Close";
export const MONTH_CHART_FILTER_TITLE = "Last 30 days";
export const QUARTER_CHART_FILTER_TITLE = "Last 90 days";
export const YEAR_CHART_FILTER_TITLE = "Last 12 months";
export const TODO_ROUTE_ITEM = "todo";

export const EXPENSE_STATEMENTS_TO_SUBMIT = "expense-statements-to-submit";
export const EXPENSE_STATEMENTS_DUPLICATES = "expense-statements-duplicates";
export const EXPENSE_STATEMENTS_UNASSIGNED = "expense-statements-unassigned";
export const EXPENSE_STATEMENTS_TO_APPROVE_FINAL =
  "expense-statements-to-approve-final";
export const EXPENSE_STATEMENTS_TO_APPROVE_FIRST =
  "expense-statements-to-approve-first";
export const EXPENSE_APPROVER_FIRST = "ExpenseApproverRestricted";
export const EXPENSE_APPROVER_FINAL = "ExpenseApproverFinal";

export const EXPENSE_DENIED_ACCESS_CODE = 39002;
export const MSNT_DENIED_ACCESS_CODE = 39001;
export const CYTRIC_EASY_DENIED_ACCESS_CODE = 39000;
export const DOCUMENT_NOT_FOUND_ERROR_CODE = 41995;
export const KEYCLOAK_MISSING_MICROSOFT_LINK_ERROR_CODE = 40000;
export const KEYCLOAK_MICROSOFT_TOKEN_ERROR_CODE = 41000;

export const HOTEL_STOP_ON_START_PAGE_OBSOLETE = true;
export const HOTEL_STOP_ON_START_PAGE = false;

export const FLAG_LOGIN = "ok";
export const KEYCLOAK_LOGIN = "keycloakLogin";
export const KEYCLOAK_QS_URL = "keycloakUrl";

export const CYTRIC_EASY_LOGOUT = "cytricEasyLogout";
export const CYTRIC_EASY_USER_DID_LOGOUT = "userLoggedOut";
export const CYTRIC_EASY_USER_DID_LOGOUT_TO_SWITCH_ACCOUNT =
  "userLoggedOutToSwitchAccount";
export const CYTRIC_EASY_USER_DID_LOGIN = "userLoggedIn";

export const USER_OBJECT_ID = "userObjectId";
export const USER_LOG_OUT_FLAG = "userLogOutFlag";

export enum FeatureToggleType {
  TenantId = "tenantId",
  Boolean = "boolean",
  Other = "other",
}

export const FeatureToggleDefinition = {
  feature1: {
    id: "feature1",
    type: FeatureToggleType.Boolean,
  },
  coachmarks: {
    id: "travel-expense.27798.coachmarks",
    type: FeatureToggleType.Boolean,
  },
  JoinHotel: {
    id: "redirection.15303.join-hotel",
    type: FeatureToggleType.Boolean,
  },
  OverlappingLocations: {
    id: "match-my-trip.27581.overlapping-locations",
    type: FeatureToggleType.Boolean,
  },
  MMTArrivalTimes: {
    id: "match-my-trip.28953.matching-arrival-times",
    type: FeatureToggleType.Boolean,
  },
  shareTripBugFix: {
    id: "share-trip.22788.infinite-loading-after-sharing-trip-fix",
    type: FeatureToggleType.Boolean,
  },
  customizedExpenseDashboard: {
    id: "expenses.34762.cytric-customized-expense-dashboard",
    type: FeatureToggleType.Other,
  },
  enableAIChatBotForListOfSystems: {
    id: "travel-expense.50730.cytric-ai-chatbot-system-list",
    type: FeatureToggleType.Other,
  },
  enableAIChatBotForListOfUsers: {
    id: "travel-expense.26047.cytric-ai-chatbot-beta-testing-users-list",
    type: FeatureToggleType.Other,
  },
  buttonEventTemplate: {
    id: "event-template.32459.event-template-team-creation-enabled",
    type: FeatureToggleType.TenantId,
  },
  eventInTeamChannel: {
    id: "events.28991.easy-app-in-teams-channel-disabled",
    type: FeatureToggleType.TenantId,
  },
  releaseVersionNumber: {
    id: "settings.30951.version-number",
    type: FeatureToggleType.Boolean,
  },
  redirectionCytricUserProfile: {
    // this feature toggle is removed in new code of EASY-38 but still present in old code
    // Delete it when AccountSettingsOptionsLegacy.tsx is removed
    id: "redirection.37307.user-profile",
    type: FeatureToggleType.Boolean,
  },
  TRIPPSettings: {
    id: "settings.46919.tripp",
    type: FeatureToggleType.TenantId,
  },
  TRIPPUpcomingTrips: {
    id: "upcoming-trips-dashboard.46920.tripp",
    type: FeatureToggleType.TenantId,
  },
  TRIPPTakeATour: {
    id: "take-a-tour.46922.tripp",
    type: FeatureToggleType.TenantId,
  },
  TRIPPAppCatalogId: {
    id: "appCatalogId.46921.tripp",
    type: FeatureToggleType.TenantId,
  },
  TRIPPPersonalTab: {
    id: "personal-tab.46923.tripp",
    type: FeatureToggleType.TenantId,
  },
  BookSameTrain: {
    id: "redirection.33629.book-same-train",
    type: FeatureToggleType.Boolean,
  },
  redirectionSameCar: {
    id: "redirection.33632.book-same-car",
    type: FeatureToggleType.Boolean,
  },
  travelArrangerShareJoin: {
    id: "personal-tab.EASY-296.travel-arranger-join-a-trip",
    type: FeatureToggleType.Boolean,
  },
  travelArrangerShareJoinPagination: {
    id: "personal-tab.EASY-46.travel-arranger-join-a-trip-pagination",
    type: FeatureToggleType.Boolean,
  },
  tripApproval: {
    id: "notifications.44404.approval-flow",
    type: FeatureToggleType.Boolean,
  },
  seamlessSwitchAccount: {
    id: "settings.EASY-38.seamless-switch-account",
    type: FeatureToggleType.Boolean,
  },
  eventManageRoles: {
    id: "events.302.manage-roles",
    type: FeatureToggleType.Boolean,
  },
  closeCollaboratorsCustomizeText: {
    id: "closeCollaborators.EASY-65.customizeText",
    type: FeatureToggleType.Boolean,
  },
  eventManagerGroupChats: {
    id: "events.EASY-30.group-chat-event-manager",
    type: FeatureToggleType.TenantId,
  },
  tripLogisticsAttendeeView: {
    id: "tripLogistics.EASY-52.attendeeView",
    type: FeatureToggleType.Boolean,
  },
  eventAttendees: {
    id: "events.EASY-53.event-attendees",
    type: FeatureToggleType.Boolean,
  },
  eventsPermissionCheck: {
    id: "events.EASY-70.permissions-check-enabler",
    type: FeatureToggleType.Boolean,
  },
  eventRecommendHotelDeletion: {
    id: "events.EASY-79.recommend-hotel-deletion",
    type: FeatureToggleType.Boolean,
  },
  eventMeetingRoomsBooking: {
    id: "events.EASY-563.meeting-rooms-button",
    type: FeatureToggleType.Boolean,
  },
  onHoldStatus: {
    id: "travel-trip-details.EASY-496.on-hold-flight",
    type: FeatureToggleType.Boolean,
  },
  tmsSettingsBookings: {
    id: "travel-trip-details.EASY-1059.tms-settings-booking-options",
    type: FeatureToggleType.TenantId,
  },
  eventGroupingTrains: {
    id: "events.EASY-62.grouping-trains",
    type: FeatureToggleType.Boolean,
  },
};

// Settings tab constants
export const CYTRIC_EASY_BUILD = "Cytric Easy Build";

export const CP_FLOW_IDENTIFIER = "confirmation-page";

export const EXPENSE_KEEP_CONTEXT_LOCAL_STORAGE_KEY =
  "easy.expense.lastVisitedParams";
