import i18n from "../../../i18n/i18n";
import HostSettings from "../../../utils/host.settings";

export const UPCOMING_TRIPS = {
  tripTitle: "Trip by car",
  delimiterForDestinations: " \u00B7 ",
};

export const UPCOMING_TRIP_DETAIL = {
  breadcrumb: {
    currentPage: "Trip",
  },
  bookingDetails: {
    space: "\u00A0",
  },
  detailCard: {
    operatedBy: i18n.t("tripDetails.detailCard.operatedBy"),
  },
  stopDuration: {
    background: "background",
    backgroundAlert: "backgroundAlert",
    border: "border",
    borderAlert: "borderAlert",
    color: "color",
    colorAlert: "colorAlert",
  },
  carDetailCard: {
    equipments: {
      manualTransmission: "Manual transmission",
      airCondition: "Air condition",
      automaticTransmission: "Automatic transmission",
      electricCar: "Electric Car",
      hybridCar: "Hybrid car",
      luggage: "Luggage Rack / Roof Rack",
      dieselEngine: "Diesel engine",
      youngDriver: "Young driver",
      crossBorderFee: "Cross-border fee",
      navigationSystem: "Navigation system",
      satelliteRadio: "Satellite radio",
      bikeRack: "Bike rack",
      skiRack: "Ski rack",
      snowChains: "Snow chains",
      snowTyres: "Snow tyres",
      winterPackage: "Winter Package",
      wifi: "Wifi access",
    },
    pickUp: "Pick up at",
    dropOff: "Drop off at",
    reservationID: "Reservation ID",
  },
};

export const TRIP_PRODUCT_AIR = "Trip_Product_Air";
export const TRIP_PRODUCT_RAIL = "Trip_Product_Train";
export const TRIP_PRODUCT_CAR = "Trip_Product_Car";
export const TRIP_PRODUCT_HOTEL = "Trip_Product_Hotel";

export const PROVIDER_FALLBACK_LOGO = `${HostSettings.getCDNIcons}providers-default.svg`;

export const DESTINATION_FALLBACK_IMAGE = `${HostSettings.getCDNImages}trip-default-image.png`;

export default {};
