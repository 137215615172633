/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import { telemetryService } from "@amadeus-cytric/cytric-teams-react-common-library";
import {
  Box,
  Button,
  Flex,
  RadioGroup,
  RadioGroupItemProps,
  Text,
} from "@fluentui/react-northstar";
import { dialog } from "@microsoft/teams-js";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteProps } from "react-router-dom";
import GenericInfo from "../../common/components/generic-info-component/GenericInfo";
import { InfoImage } from "../../common/components/generic-info-component/GenericInfo.model";
import RedirectionTypeEnum from "../../common/models/redirection/RedirectionTypeEnum";
import Traveler from "../../common/models/Traveler";
import { getDevice } from "../../utils/device.utils";
import MatchMyTrip from "../match-my-trip/MatchMyTrip";
import SearchModel from "../match-my-trip/models/SearchModel";
import matchMyTrip from "../match-my-trip/styles/match-my-trip.module.scss";
import TravelersList from "./components/TravelersList";
import {
  preparePayloadForCarBooking,
  preparePayloadForFlightBooking,
  preparePayloadForHotelBooking,
  preparePayloadForRailBooking,
} from "./functions/redirection-helpers";
import Styles from "./styles/join-a-trip.module.scss";

function JoinATripTaskModule({ location }: RouteProps) {
  const { t } = useTranslation();
  const [bookingFor, setBookingFor] = useState("");
  const [device, setDevice] = useState<any>();
  const [subEntityId, setSubEntityId] = useState<any>();
  const [isBookFlight, setIsBookFlight] = useState<boolean>(false);
  const [isBookOther, setIsBookOther] = useState<boolean>(false);
  const [travelerUser, setTravelerUser] = useState<Traveler>();
  const [error, setError] = useState<any>();

  const getThemeStyles = (theme: any) => ({
    backgroundColor: theme.theme.siteVariables.colorScheme?.default.background,
  });

  let isMobileOrTablet: boolean | undefined;
  let isMobile: boolean | undefined;

  if (device === "tablet") {
    isMobileOrTablet = true;
    isMobile = false;
  } else if (device === "mobile") {
    isMobileOrTablet = true;
    isMobile = true;
  } else {
    isMobileOrTablet = false;
    isMobile = false;
  }

  const BOOKING_FOR = {
    myself: { code: "myself", label: t("shareJoin.bookMyselfButtonLabel") },
    colleague: {
      code: "colleague",
      label: t("shareJoin.bookForColleagueButtonLabel"),
    },
  };

  // TODO: Translate labels
  const bookingOptions = [
    {
      key: BOOKING_FOR.myself.code,
      value: BOOKING_FOR.myself.code,
      label: BOOKING_FOR.myself.label,
    },
    {
      key: BOOKING_FOR.colleague.code,
      value: BOOKING_FOR.colleague.code,
      label: BOOKING_FOR.colleague.label,
    },
  ];

  const isBookFlightRedirectionType = (
    segmentType: RedirectionTypeEnum
  ): boolean => segmentType === RedirectionTypeEnum.FLIGHT;

  const isBookOtherRedirectionType = (
    segmentType: RedirectionTypeEnum
  ): boolean =>
    segmentType === RedirectionTypeEnum.HOTEL ||
    segmentType === RedirectionTypeEnum.RAIL ||
    segmentType === RedirectionTypeEnum.CAR;

  useEffect(() => {
    setDevice(getDevice());
  }, [location]);

  useEffect(() => {
    if (location?.search != null) {
      const rawSubEntityId = location.search.split("?")[1];
      const subEntityObj = JSON.parse(decodeURIComponent(rawSubEntityId));
      setSubEntityId(subEntityObj);
      setIsBookFlight(isBookFlightRedirectionType(subEntityObj.segmentType));
      setIsBookOther(isBookOtherRedirectionType(subEntityObj.segmentType));
    }
  }, [location]);

  const submitFlightTask = (airState: SearchModel): void => {
    telemetryService.trackEvent(
      { name: "travel-mmt-submit" },
      {
        numberOfSegments: airState?.numberOfFlights?.length,
      }
    );
    dialog.url.submit(airState);
  };

  document.onkeyup = function close(event) {
    if (event.key === "27") {
      telemetryService.trackEvent({ name: "travel-mmt-cancel" });
      dialog.url.submit();
    }
  };

  const closeTask = (): void => {
    telemetryService.trackEvent({ name: "travel-mmt-cancel" });
    dialog.url.submit();
  };

  function handleRadioChange(data: RadioGroupItemProps | undefined) {
    if (data && data?.value) {
      setBookingFor(data?.value as string);
      if (data?.value === "myself") {
        setTravelerUser(undefined);
      }
    }
  }

  const handleTAClick = async () => {
    if (subEntityId != null) {
      const segmentType = subEntityId?.segmentType;
      const payloadPreparationMapping: {
        [key: string]: (
          bookingFor: string,
          subEntityId: any,
          userId: string
        ) => any;
      } = {
        [RedirectionTypeEnum.FLIGHT]: preparePayloadForFlightBooking,
        [RedirectionTypeEnum.HOTEL]: preparePayloadForHotelBooking,
        [RedirectionTypeEnum.RAIL]: preparePayloadForRailBooking,
        [RedirectionTypeEnum.CAR]: preparePayloadForCarBooking,
      };
      const dataToSubmit: any = payloadPreparationMapping[segmentType]
        ? payloadPreparationMapping[segmentType](
            bookingFor,
            subEntityId,
            travelerUser?.userName ?? ""
          )
        : undefined;

      if (bookingFor === "myself") {
        telemetryService.trackEvent({
          name: `chat-notification-book${dataToSubmit?.segmentType}`,
        });
      } else {
        const bookingSegmentType =
          dataToSubmit?.segmentType.split("TravelArranger")[1];
        telemetryService.trackEvent(
          { name: `travel-arrange-book${bookingSegmentType}` },
          { numberOfTravelers: dataToSubmit?.travelerUserId?.length }
        );
      }

      dialog.url.submit(dataToSubmit);
    } else {
      console.error("subEntityId is null", subEntityId);
      dialog.url.submit();
    }
  };

  const searchButton = (segmentType: string, isDisabled: boolean) => {
    let btnContent = t("shareJoin.bookFlightButtonLabel");
    if (segmentType === RedirectionTypeEnum.FLIGHT) {
      btnContent = t("shareJoin.bookFlightButtonLabel");
    } else if (segmentType === RedirectionTypeEnum.HOTEL) {
      btnContent = t("shareJoin.bookHotelButtonLabel");
    } else if (segmentType === RedirectionTypeEnum.RAIL) {
      btnContent = t("shareJoin.bookTrainButtonLabel");
    } else if (segmentType === RedirectionTypeEnum.CAR) {
      btnContent = t("shareJoin.bookCarButtonLabel");
    }
    return (
      <Flex
        hAlign="end"
        gap="gap.small"
        className={[Styles.actions, isMobileOrTablet && Styles.mobile].join(
          " "
        )}
        styles={getThemeStyles}
      >
        <Button
          content={t("shareJoin.cancelButtonLabel")}
          flat
          tinted={isMobileOrTablet}
          fluid={isMobile}
          className={[Styles.buttons, isMobileOrTablet && Styles.mobile].join(
            " "
          )}
          onClick={() => dialog.url.submit()}
        />
        <Button
          content={btnContent}
          primary
          flat
          fluid={isMobile}
          className={[Styles.buttons, isMobileOrTablet && Styles.mobile].join(
            " "
          )}
          onClick={handleTAClick}
          disabled={isDisabled}
        />
      </Flex>
    );
  };

  const bookingForMyself = () => (
    <Box>
      {isBookFlight ? (
        <Flex
          column
          gap="gap.large"
          styles={getThemeStyles}
          className={[matchMyTrip.content, matchMyTrip["share-join"]].join(" ")}
        >
          <MatchMyTrip
            location={location}
            t={t}
            closeTask={closeTask}
            submitTask={submitFlightTask}
            isMobileOrTablet={isMobileOrTablet}
            deprecatedMMT={false}
            isShareJoin
          />
        </Flex>
      ) : null}
    </Box>
  );

  if (error) {
    return (
      <Box styles={getThemeStyles} data-testid="error component">
        <GenericInfo
          infoData={{
            title: t(`messages.ERR_Generic`),
            detail: t(`messages.ERR_Request`),
          }}
          image={InfoImage.ERROR}
          action={{
            text: t("App.Try_again"),
            command: () => window.location.reload(),
          }}
        />
      </Box>
    );
  }

  return (
    <Flex
      column
      gap="gap.smaller"
      styles={getThemeStyles}
      className={[
        Styles["share-content"],
        isMobileOrTablet && Styles.mobile,
      ].join(" ")}
    >
      <Flex
        column
        className={[Styles.header, isMobileOrTablet && Styles.mobile].join(" ")}
      >
        <Text
          content={t("shareJoin.bookForWhoLabel")}
          className={[
            Styles["text-major"],
            isMobileOrTablet && Styles.mobile,
          ].join(" ")}
        />
        <RadioGroup
          checkedValue={bookingFor}
          items={bookingOptions}
          onCheckedValueChange={(_, data) => {
            handleRadioChange(data);
          }}
          className={[
            Styles["radio-buttons"],
            Styles["text-major"],
            isMobileOrTablet && Styles.mobile,
          ].join(" ")}
        />

        <Box
          className={Styles["join-a-trip__line"]}
          styles={(theme) => ({
            color: theme.theme.siteVariables.colorScheme?.default.border3,
          })}
        />
      </Flex>

      {bookingFor === "myself" && bookingForMyself()}

      {bookingFor === "colleague" && (
        <TravelersList
          subEntityId={subEntityId}
          searchButton={searchButton}
          selectedUser={travelerUser}
          setClickedUser={setTravelerUser}
          isMobileOrTablet={isMobileOrTablet}
          setError={setError}
        />
      )}
      {!error && (
        <Box
          className={[
            Styles["footer-shadow"],
            isMobileOrTablet && Styles.mobile,
          ].join(" ")}
        />
      )}
      <Flex>
        {subEntityId &&
          bookingFor === "" &&
          searchButton(subEntityId.segmentType, true)}
        {subEntityId &&
          bookingFor === "myself" &&
          isBookOther &&
          searchButton(subEntityId.segmentType, false)}
      </Flex>
    </Flex>
  );
}

export default JoinATripTaskModule;
