/* eslint-disable react/react-in-jsx-scope */

import {
  Airplane20Regular,
  Airplane24Regular,
  Bed20Regular,
  Bed24Regular,
  VehicleCar20Regular,
  VehicleCar24Regular,
  VehicleSubway20Regular,
  VehicleSubway24Regular,
} from "@fluentui/react-icons";
import {
  ComponentStyleFunctionParam,
  Flex,
  Text,
} from "@fluentui/react-northstar";
import { useTranslation } from "react-i18next";
import Responsive from "../../../../../common/components/responsive/Responsive";
import { ISegmentIconsPropsV1 } from "./SegmentIcons.model";
import Styles from "./SegmentIcons.module.scss";

function SegmentIcons({ trip, title }: ISegmentIconsPropsV1) {
  const { t } = useTranslation("translation", {
    keyPrefix: "upcoming_trips_dashboard.upcomingTrips",
  });
  const ICON_STYLE = (theme: ComponentStyleFunctionParam) => ({
    color: theme.theme.siteVariables
      ? theme.theme.siteVariables?.segmentIcons?.icons
      : "",
  });

  const retrieveIcons = (size: string, isForText: boolean = false) => {
    const buffer = [];
    let concatenatedTitle = t("segmentsText");
    if (trip?.overview?.containsAir) {
      concatenatedTitle = concatenatedTitle.concat(
        " ",
        `${t("newBook.Flight")},`
      );

      buffer.push(
        <Flex styles={ICON_STYLE} key="Flight1" role="img" aria-hidden>
          {size === "tablet" ? <Airplane24Regular /> : <Airplane20Regular />}
        </Flex>
      );
    }

    if (trip?.overview?.containsRail) {
      concatenatedTitle = concatenatedTitle.concat(
        " ",
        `${t("newBook.Rail")},`
      );
      buffer.push(
        <Flex styles={ICON_STYLE} key="Rail2" role="img" aria-hidden>
          {size === "tablet" ? (
            <VehicleSubway24Regular />
          ) : (
            <VehicleSubway20Regular />
          )}
        </Flex>
      );
    }
    if (trip?.overview?.containsHotel) {
      concatenatedTitle = concatenatedTitle.concat(
        " ",
        `${t("newBook.Hotel")},`
      );
      buffer.push(
        <Flex styles={ICON_STYLE} key="Hotel3" role="img" aria-hidden>
          {size === "tablet" ? <Bed24Regular /> : <Bed20Regular />}
        </Flex>
      );
    }
    if (trip?.overview?.containsCar) {
      concatenatedTitle = concatenatedTitle.concat(" ", `${t("newBook.Car")}`);
      buffer.push(
        <Flex styles={ICON_STYLE} key="Car4" role="img" aria-hidden>
          {size === "tablet" ? (
            <VehicleCar24Regular />
          ) : (
            <VehicleCar20Regular />
          )}
        </Flex>
      );
    }
    if (concatenatedTitle.endsWith(",")) {
      concatenatedTitle = concatenatedTitle.slice(0, -1);
    }
    if (isForText) {
      return concatenatedTitle;
    }

    return buffer;
  };

  const iconsForMobile = (
    <Responsive forMaxSize="tablet">{retrieveIcons("tablet")}</Responsive>
  );
  const iconsForDesktop = (
    <Responsive forMinSize="desktopSmall">
      {retrieveIcons("desktop")}
    </Responsive>
  );
  return (
    <>
      <p id="segmentIconsId" style={{ display: "none" }}>
        {title ?? retrieveIcons("", true)}
      </p>
      <Flex
        className={Styles.segments}
        aria-describedby="segmentIconsId"
        title={title ?? (retrieveIcons("", true) as any)}
        role="region"
      >
        <Text
          aria-hidden="true"
          content={title ?? t("segmentsText")}
          styles={(theme) => ({
            color: theme.theme.siteVariables?.segmentIcons?.text,
          })}
        />
        <div className={Styles.segments__icons} data-testid="segment-icons">
          {iconsForMobile}
          {iconsForDesktop}
        </div>
      </Flex>
    </>
  );
}
export default SegmentIcons;
