import HandleTravelCytricRedirection from "../../../../../utils/HandleTravelCytricRedirection";

export default function BookRedirection(
  t: any,
  dispatch: any,
  history: any,
  redirectionInput: any,
  toTravelArrangerDashboard: boolean = false
) {
  const breadcrumbData = [
    {
      label: "upcoming_trips_dashboard.tripDetails.breadcrumb.previousPage",
      url: "/travel",
      translation: true,
    },
    {
      label: toTravelArrangerDashboard
        ? "upcoming_trips_dashboard.breadcrumb.travelArrangerDashboard"
        : "upcoming_trips_dashboard.breadcrumb.planATrip",
      url: "",
      translation: true,
    },
  ];

  HandleTravelCytricRedirection({ breadcrumbData, t, dispatch });

  const context = encodeURIComponent(JSON.stringify(redirectionInput));
  history.push(`/cytricweb?context=${context}&type=travel`);
}
