/* eslint-disable arrow-body-style */
import moment from "moment";
import RedirectionTypeEnum from "../../../common/models/redirection/RedirectionTypeEnum";
import i18n from "../../../i18n/i18n";
import HostSettings from "../../../utils/host.settings";
import ValidationUtils from "../../../utils/validation.utils";

/**
 * Gets image url if it was provided, otherwise gets default image url.
 *
 * @param imageUrl image url
 */
export function getAdaptiveCardImageUrl(imageUrl: any): string {
  return ValidationUtils.isValidHttpUrl(imageUrl)
    ? imageUrl
    : `${HostSettings.getCDNImages}default/square.png`;
}

/**
 * Creates a deeplink for search according to given data.
 *
 * @param deepLinkData search data - flight, hotel, car, rail
 * @returns deeplink url for search
 */
export function getSearchDeeplink(deepLinkData: any): string {
  const urlEncodedDeepLinkData: any = {
    subEntityId: JSON.stringify(deepLinkData),
  };

  const tabName = "travel";
  return `https://teams.microsoft.com/l/entity/${
    HostSettings.teamsAppId
  }/${tabName}?context=${encodeURIComponent(
    JSON.stringify(urlEncodedDeepLinkData)
  )}`;
}

/**
 * Creates formatted duration string in format "xxh yym" in case duration is valid, otherwise returns "Currently not able to retrieve duration".
 *
 * @param totalDuration total duration of stops
 * @returns duration string
 */
export function getFormattedDuration(totalDuration: moment.Duration) {
  if (totalDuration.asMilliseconds() >= 0) {
    const formattedHours = totalDuration.hours().toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
    const formattedMinutes = totalDuration.minutes().toLocaleString("en-US", {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
    return `${formattedHours}${i18n.t(
      "shareTrip.adaptiveCard.hourAbbreviation"
    )} ${formattedMinutes}${i18n.t(
      "shareTrip.adaptiveCard.minuteAbbreviation"
    )}`;
  }
  return i18n.t("shareTrip.adaptiveCard.invalidDuration");
}

/**
 * Gets data segments based on adaptive card data .
 *
 * @param cardData adaptive card data
 * @returns segments data
 */
export function getDataSegments(cardData: any) {
  switch (cardData.segmentType) {
    case RedirectionTypeEnum.SHARE_FLIGHT:
      return cardData?.itineraries?.map((itinerary: any) => {
        return {
          segmentType: "FLIGHT",
          segmentId: null,
          segmentData: JSON.stringify(itinerary),
        };
      });

    case RedirectionTypeEnum.SHARE_HOTEL:
      return [
        {
          segmentType: "HOTEL",
          segmentId: null,
          segmentData: JSON.stringify(cardData),
        },
      ];

    case RedirectionTypeEnum.SHARE_RAIL:
      return cardData?.itineraries?.map((itinerary: any) => {
        return {
          segmentType: "RAIL",
          segmentId: null,
          segmentData: JSON.stringify(itinerary),
        };
      });

    case RedirectionTypeEnum.SHARE_CAR:
      return [
        {
          segmentType: "CAR",
          segmentId: null,
          segmentData: JSON.stringify(cardData),
        },
      ];

    default:
      return {};
  }
}

/**
 * Gets formatted date string in format locale fromat - dayweek, day month year.
 *
 * @param dateTime date time string from deeplink
 * @returns string representation of date time
 */
export function getformattedDateTime(dateTime: string) {
  const dateOptions: Intl.DateTimeFormatOptions = {
    weekday: "short",
    month: "short",
    day: "numeric",
    year: "numeric",
  };

  return new Date(dateTime).toLocaleDateString(i18n.language, dateOptions);
}
