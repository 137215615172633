import { telemetryService } from "@amadeus-cytric/cytric-teams-react-common-library";
import { Box, Button, Label } from "@fluentui/react-northstar";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import RedirectionTypeEnum from "../../../../../common/models/redirection/RedirectionTypeEnum";
import { useAppDispatch } from "../../../../../store/StoreHooks";
import { breadcrumbActions } from "../../../../../store/breadcrumb/breadcrumb-slice";
import { expenseStatementsToSubmitActions } from "../../../../../store/expense-statements-to-submit/ExpenseStatementsToSubmitSlice";
import {
  EXPENSE_APPROVER_FINAL,
  EXPENSE_APPROVER_FIRST,
  EXPENSE_STATEMENTS_DUPLICATES,
  EXPENSE_STATEMENTS_TO_APPROVE_FINAL,
  EXPENSE_STATEMENTS_TO_APPROVE_FIRST,
  EXPENSE_STATEMENTS_TO_SUBMIT,
} from "../../../../../utils/constants";
import NO_LABEL_NO_URL from "../../../../personal-tab/breadcrumb/constants";
import { getExpenseStatements } from "../../../ExpenseDashboardRequests";
import { ItemInterface } from "../../TodoInterfaces";
import styles from "./TodoItem.module.scss";

function TodoItem({
  item,
  openDialog,
  setDidMergeAction,
  dispatchDialog,
}: any) {
  const { t } = useTranslation("translation", {
    keyPrefix: "expenses-dashboard.todo",
  });

  const history = useHistory();

  const dispatch = useAppDispatch();

  const getExpenseStatementsToSubmit = async () => {
    dispatch(expenseStatementsToSubmitActions.setIsLoading(true));
    try {
      const response = await getExpenseStatements();
      const data = (await response.data.data) || (await response.data);
      dispatch(expenseStatementsToSubmitActions.setIsLoading(false));
      dispatch(expenseStatementsToSubmitActions.setExpensesToSubmit(data));
    } catch (error: any) {
      dispatch(expenseStatementsToSubmitActions.setIsLoading(false));
      dispatch(expenseStatementsToSubmitActions.setIsError(true));
    }
    return undefined;
  };

  const onListItemClick = (todoItem: ItemInterface) => {
    telemetryService.trackEvent({
      name: todoItem.track,
    });

    if (todoItem.id === EXPENSE_STATEMENTS_TO_SUBMIT) {
      getExpenseStatementsToSubmit();
      dispatchDialog({
        type: EXPENSE_STATEMENTS_TO_SUBMIT,
        payload: getExpenseStatementsToSubmit,
      });
    } else if (todoItem.id === EXPENSE_STATEMENTS_DUPLICATES) {
      openDialog(true);
      setDidMergeAction(false);
    } else {
      switch (todoItem.id) {
        case EXPENSE_STATEMENTS_TO_APPROVE_FIRST:
          dispatch(
            breadcrumbActions.setExpenseLevel2({
              label: t("breadcrumb.firstLevelApproval"),
              url: "",
            })
          );
          dispatch(breadcrumbActions.setExpenseLevel3(NO_LABEL_NO_URL));
          history.push(
            `/classic?segmentType=${RedirectionTypeEnum.EXPENSE_DASHBOARD}&userRole=${EXPENSE_APPROVER_FIRST}`
          );
          break;
        case EXPENSE_STATEMENTS_TO_APPROVE_FINAL:
          dispatch(
            breadcrumbActions.setExpenseLevel2({
              label: t("breadcrumb.finalLevelApproval"),
              url: "",
            })
          );
          dispatch(breadcrumbActions.setExpenseLevel3(NO_LABEL_NO_URL));
          history.push(
            `/classic?segmentType=${RedirectionTypeEnum.EXPENSE_DASHBOARD}&userRole=${EXPENSE_APPROVER_FINAL}`
          );
          break;
        default:
          dispatch(
            breadcrumbActions.setExpenseLevel2({
              label: t("breadcrumb.yourExpenses"),
              url: "",
            })
          );
          dispatch(breadcrumbActions.setExpenseLevel3(NO_LABEL_NO_URL));
          history.push(
            `/classic?segmentType=${RedirectionTypeEnum.EXPENSE_DASHBOARD}`
          );
      }
    }
  };

  const isApproval =
    item.id === EXPENSE_STATEMENTS_TO_APPROVE_FIRST ||
    item.id === EXPENSE_STATEMENTS_TO_APPROVE_FINAL;

  return (
    <div className={styles["list-item"]}>
      <Box
        className={styles["list-item__text-wrapper"]}
        styles={(theme) => ({
          color: theme.theme.siteVariables.colorScheme?.default.foreground,
        })}
      >
        <div className={styles["list-item__title-container"]}>
          <div className={styles["list-item__title-wrapper"]}>
            <span className={styles["list-item__count"]}>{item.count}</span>
            <span>{item.header}</span>
          </div>
          {isApproval && (
            <Label
              circular
              content={t("approval")}
              className={styles["list-item__label"]}
              styles={(theme) => ({
                color:
                  theme.theme.siteVariables.expenseDashboard?.todoList?.label
                    ?.text,
                backgroundColor:
                  theme.theme.siteVariables.expenseDashboard?.todoList?.label
                    ?.background,
              })}
            />
          )}
        </div>
      </Box>
      <Button
        aria-label={`View list of ${item.header}`}
        data-testid={`todo__action-button-${item.id}`}
        text
        primary
        iconOnly
        styles={(theme) => ({
          color: theme.theme.siteVariables
            ? theme.theme.siteVariables.expenseDashboard?.todoList?.button
            : "",
          height: "auto",
        })}
        content={
          item.id !== EXPENSE_STATEMENTS_DUPLICATES
            ? t("viewList")
            : t("viewDuplicates")
        }
        onClick={() => {
          onListItemClick(item);
        }}
      />
    </div>
  );
}

export default TodoItem;
