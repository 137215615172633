import { Security } from "@amadeus-cytric/cytric-teams-react-common-library";

export default async function HandleAppPermissions() {
  const requiredPermissions = [
    "Channel.ReadBasic.All",
    "Team.Create",
    "Team.ReadBasic.All",
  ];

  const hasPermissions = await Security.checkAllScopesGranted(
    requiredPermissions
  ).then((isAllowed: boolean) => isAllowed);

  return hasPermissions;
}
