import { Receipt24Regular } from "@fluentui/react-icons";
import {
  Box,
  ComponentStyleFunctionParam,
  Flex,
  Text,
} from "@fluentui/react-northstar";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Divider from "../../../../../common/components/divider/Divider";
import StatusPill from "../../../../../common/components/status-pill/StatusPill";
import RedirectionTypeEnum from "../../../../../common/models/redirection/RedirectionTypeEnum";
import { useAppDispatch } from "../../../../../store/StoreHooks";
import { breadcrumbActions } from "../../../../../store/breadcrumb/breadcrumb-slice";
import { IReimbursement } from "../ExpenseStatementInterface";
import { IExpenseItemProps } from "./ExpenseItem.model";
import styles from "./ExpenseItem.module.scss";

const ICON_STYLE = (theme: ComponentStyleFunctionParam) => ({
  color: theme.theme.siteVariables.colorScheme?.default.foreground,
  width: "21px",
});

function ExpenseItem({ expense }: IExpenseItemProps): React.ReactElement {
  const { t } = useTranslation("translation", {
    keyPrefix: "expenses-dashboard",
  });
  const dispatch = useAppDispatch();

  const history = useHistory();

  const getReimbursementAmount = (reimbursement: IReimbursement) =>
    reimbursement === null ? "-.--" : reimbursement.amount;

  const getReimbursementAmountAccessibility = (
    reimbursement: IReimbursement
  ) =>
    reimbursement === null
      ? t(`accessibility.noDataAvailable`)
      : `${expense.reimbursement.amount} ${expense.reimbursement.currency.name}`;

  const redirectToExpenseStatementHandler = (expenseId: number) => {
    dispatch(
      breadcrumbActions.setExpenseLevel2({
        label: t("yourExpenses"),
        url: `/classic?segmentType=${RedirectionTypeEnum.EXPENSE_DASHBOARD}`,
      })
    );
    dispatch(
      breadcrumbActions.setExpenseLevel3({
        label: expense.title || t("popup.viewList.untitledStatement"),
        url: "",
      })
    );
    history.push(
      `/classic?segmentType=${RedirectionTypeEnum.EXPENSE_DASHBOARD}&expenseId=${expenseId}`
    );
  };

  const getStatusTitleAccessibility = (status: string) => {
    const expenseStatus = t(`popup.viewList.status.${status}`);
    const statusTitle = t("popup.viewList.accessibility.status");
    return `${statusTitle} ${expenseStatus}`;
  };

  const getStatusColor = (status: string) => {
    let color;

    switch (status.toLowerCase()) {
      case "draft":
        color = "grey";
        break;
      case "calculated":
        color = "green";
        break;
      case "submitting":
        color = "blue";
        break;
      case "rejected":
        color = "red";
        break;
      case "invalid":
        color = "red";
        break;
      default:
        color = "unknown";
    }

    return color;
  };

  const getSingularOrPluralReceipt = (numberOfReceipts: number) =>
    numberOfReceipts === 1
      ? t("popup.viewList.accessibility.receipt")
      : t("popup.viewList.accessibility.receipts");

  const displayDate = (_startDate: Date, _endDate: Date) => {
    const dateFormat = "DD.MM.YYYY";
    const startDate = moment(_startDate).format(dateFormat);
    const endDate = moment(_endDate).format(dateFormat);
    const dateFormatAccessible = "DD MMMM YYYY";
    const startDateAccessible = moment(_startDate).format(dateFormatAccessible);
    const endDateAccessible = moment(_endDate).format(dateFormatAccessible);

    if (startDate === endDate) {
      return {
        text: startDate,
        ariaLabel: `${t(
          "popup.viewList.accessibility.on"
        )} ${startDateAccessible}`,
      };
    }
    if (_startDate === null) {
      return {
        text: endDate,
        ariaLabel: `${t(
          "popup.viewList.accessibility.on"
        )} ${endDateAccessible}`,
      };
    }

    return {
      text: `${startDate} - ${endDate}`,
      ariaLabel: `${t(
        "popup.viewList.accessibility.from"
      )} ${startDateAccessible} ${t("accessibility.to")} ${endDateAccessible}`,
    };
  };

  return (
    <li>
      <div className={styles["expense-item"]}>
        <div>
          <Box
            as="div"
            className={styles.image}
            styles={(theme) => ({
              background: theme.theme.siteVariables
                ? theme.theme.siteVariables.expenseDashboard?.popUps
                    ?.folderImage
                : "",
            })}
          />
        </div>
        <div className={styles.details}>
          <div className={styles["heading-row"]}>
            <Text
              className={styles["trip-name"]}
              styles={(theme) => ({
                color: theme.theme.siteVariables.colorScheme?.brand.foreground2,
              })}
              as="a"
              tabIndex={0}
              onClick={() => redirectToExpenseStatementHandler(expense.id)}
            >
              {expense.title || t("popup.viewList.untitledStatement")}
            </Text>
            <StatusPill
              text={
                getStatusColor(expense.status) === "unknown"
                  ? expense.status
                  : t(`popup.viewList.status.${expense.status}`)
              }
              color={getStatusColor(expense.status)}
              title={getStatusTitleAccessibility(expense.status)}
            />
          </div>
          <Text
            as="div"
            styles={(theme) => ({
              color: theme.theme.siteVariables.colorScheme?.default.foreground2,
            })}
            className={styles.date}
            aria-label={displayDate(expense.minDate, expense.maxDate).ariaLabel}
          >
            {displayDate(expense.minDate, expense.maxDate).text}
          </Text>
          {expense.description && (
            <Text
              as="div"
              styles={(theme) => ({
                color:
                  theme.theme.siteVariables.colorScheme?.default.foreground,
              })}
              className={styles.description}
            >
              {expense.description}
            </Text>
          )}
          <div className={styles.reimbursement}>
            <div className={styles.receipts}>
              <Flex styles={ICON_STYLE}>
                <Receipt24Regular />
              </Flex>
              <Text
                as="p"
                styles={(theme) => ({
                  color:
                    theme.theme.siteVariables.colorScheme?.default.foreground,
                })}
                aria-label={`${t("popup.viewList.accessibility.includes")} ${
                  expense.overview.receiptsNumber
                } ${getSingularOrPluralReceipt(
                  expense.overview.receiptsNumber
                )}`}
              >{` ${expense.overview.receiptsNumber}`}</Text>
            </div>
            <Text
              className={styles.amount}
              as="p"
              styles={(theme) => ({
                color:
                  theme.theme.siteVariables.colorScheme?.default.foreground,
              })}
              aria-label={`${t(
                "accessibility.totalAmount"
              )} ${getReimbursementAmountAccessibility(expense.reimbursement)}`}
            >
              {`${getReimbursementAmount(expense.reimbursement)} ${
                expense.reimbursement
                  ? expense.reimbursement.currency.code
                  : expense.referenceCurrency?.code || ""
              }`}
            </Text>
          </div>
        </div>
      </div>
      <Divider />
    </li>
  );
}

export default ExpenseItem;
