/* eslint-disable react/destructuring-assignment */
import { Flex, Text } from "@fluentui/react-northstar";
import moment from "moment";
import { useEffect } from "react";
import ProductAir from "../../../../../common/models/ProductAir";
import TripHelperV1 from "../../../../../helpers/TripHelperV1";
import {
  IArrivalDepartureFlight,
  IProductV1,
} from "../../../../upcoming-trips-dashboard/UpcomingTripDetail/UpcomingTripDetailV1.model";
import { useDispatch } from "../../../context/ShareJoinContext";
import { types } from "../../../context/ShareJoinReducer";
import SegmentWrapper from "../segments/SegmentWrapper";

interface AirSegmentContentProps {
  airSegment: IProductV1[];
  id: number;
}

function AirSegmentContentV1({ airSegment, id }: AirSegmentContentProps) {
  const dispatch = useDispatch();
  const firstSegment = airSegment[0];
  const lastSegment = airSegment[airSegment.length - 1];
  const departure = firstSegment.departure as IArrivalDepartureFlight;
  const arrival = lastSegment.arrival as IArrivalDepartureFlight;
  const { range } = TripHelperV1.getStartEndDate(
    departure.dateTime,
    arrival.dateTime
  );

  function GetBoundStopsAirText(): string {
    return TripHelperV1.getStopsNameAndDuration(airSegment)
      .toString()
      .replace(",", ", ");
  }

  function GetAirInfo(): string {
    const carrierInfo: string[] = [];
    let prevCarrierName = airSegment[0].marketing?.carrier.name;
    if (prevCarrierName) {
      carrierInfo.push(prevCarrierName);
    }
    airSegment.forEach((segment) => {
      if (segment.marketing?.carrier.name !== prevCarrierName) {
        prevCarrierName = segment.marketing?.carrier.name;
        carrierInfo.push(
          ` ${prevCarrierName} ${segment.carrierCode}${segment.operating?.flightDesignator.flightNumber}`
        );
      } else {
        carrierInfo.push(
          ` ${segment.carrierCode}${segment.operating?.flightDesignator.flightNumber}`
        );
      }
    });

    return carrierInfo.toString().replace(",", ", ");
  }

  const travelDuration = TripHelperV1.getTravelDuration(
    departure.dateTime,
    arrival.dateTime
  );
  const numOfStops = TripHelperV1.getNumOfStopsText(airSegment.length - 1);
  const boundText = GetBoundStopsAirText();
  const airInfo = GetAirInfo();

  const header = (
    <Flex>
      <Text>
        <Text weight="bold">
          {moment.utc(departure.dateTime).format("HH:mm")}
          &nbsp;
          {departure.iataCode}
        </Text>
        &nbsp; - &nbsp;
        <Text weight="bold">
          {moment.utc(arrival.dateTime).format("HH:mm")}
          &nbsp;
          {arrival.iataCode}
        </Text>
        &nbsp; | &nbsp;
        <Text weight="bold">{numOfStops}</Text>
        {airSegment.length > 1 && boundText}
        &nbsp; | &nbsp;
        {airInfo}
      </Text>
    </Flex>
  );

  const customInfo = {
    timeAndName: boundText,
    nameAndNumber: airInfo,
    travelDuration,
    numberOfStops: numOfStops,
  };

  const getAirProductId = (): string => {
    const idDeparture = `${departure.dateTime}#${firstSegment.departureAirportLocation?.iataCode}`;
    const idArrival = `${arrival.dateTime}#${lastSegment.arrivalAirportLocation?.iataCode}`;
    const idAirlineFlight = `${firstSegment.operating?.flightDesignator.flightNumber}#${firstSegment.carrierCode}`;

    return `${idDeparture}#${idArrival}#${idAirlineFlight}`;
  };

  const productToShare: ProductAir = {
    productType: "air",
    id: "",
    direction: "1",
    arrival: {
      airportName: lastSegment.arrivalAirportLocation?.name ?? "",
      iataCode: lastSegment.arrivalAirportLocation?.iataCode ?? "",
      countryCode: lastSegment.arrivalAirportLocation?.country_codes[0] ?? "",
      cityName: lastSegment.arrivalAirportLocation?.address.cityName ?? "",
      countryName: lastSegment.arrivalAirportLocation?.country_codes[0] ?? "",
      dateTime: arrival.dateTime,
    },
    departure: {
      airportName: firstSegment.departureAirportLocation?.name ?? "",
      iataCode: firstSegment.departureAirportLocation?.iataCode ?? "",
      countryCode:
        firstSegment.departureAirportLocation?.country_codes[0] ?? "",
      cityName: firstSegment.departureAirportLocation?.address.cityName ?? "",
      countryName:
        firstSegment.departureAirportLocation?.country_codes[0] ?? "",
      dateTime: departure.dateTime,
    },
    bookingClass: {
      fareBasis: firstSegment.marketing?.bookingClass.fareBasis,
      levelOfService: firstSegment.marketing?.bookingClass.levelOfService ?? "",
    },
    duration: travelDuration,
    number: "",
  };

  useEffect(() => {
    dispatch({
      types: types.setTrip,
      payload: productToShare,
    });
    dispatch({
      type: types.addSegment,
      payload: {
        Trip_Product_Air: productToShare,
        customInfo,
        id: getAirProductId(),
      },
    });
    dispatch({
      type: types.setInitialSegments,
      payload: { Trip_Product_Air: productToShare, id: getAirProductId() },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <SegmentWrapper
      segment={{ Trip_Product_Air: productToShare, id: getAirProductId() }}
      topDate={range}
      segmentType="air"
    >
      {header}
    </SegmentWrapper>
  );
}

export default AirSegmentContentV1;
