/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { telemetryService } from "@amadeus-cytric/cytric-teams-react-common-library";
import { Flex } from "@fluentui/react-northstar";
import { app, dialog } from "@microsoft/teams-js";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteProps } from "react-router-dom";
import stylesShareFlight from "../../common/styles/share-flight.module.scss";
import { getDevice } from "../../utils/device.utils";
import MatchMyTrip from "./MatchMyTrip";
import SearchModel from "./models/SearchModel";
import matchMyTrip from "./styles/match-my-trip.module.scss";

function MatchMyTripTaskModule({ location }: RouteProps) {
  const { t } = useTranslation();
  const [device, setDevice] = useState<any>();
  const [deprecatedMMT, setDeprecatedMMT] = useState<boolean>(false);

  const getThemeStyles = (theme: any) => ({
    backgroundColor: theme.theme.siteVariables.colorScheme?.default.background,
  });

  const closeTask = (): void => {
    telemetryService.trackEvent({ name: "travel-mmt-cancel" });
    dialog.url.submit();
  };

  const submitTask = (airState: SearchModel): void => {
    telemetryService.trackEvent(
      { name: "travel-mmt-submit" },
      {
        numberOfSegments: airState?.numberOfFlights?.length,
      }
    );
    dialog.url.submit(airState);
  };

  document.onkeyup = function close(event) {
    if (event.key === "27") {
      telemetryService.trackEvent({ name: "travel-mmt-cancel" });
      dialog.url.submit();
    }
  };

  useEffect(() => {
    app.initialize().then(() => {
      app.getContext().then((ctx: app.Context) => {
        if (ctx?.page?.subPageId === "") {
          // NOTE: To support MMT from the old cards already in the 1:1 chat
          // we distinguish between deprecated MMT (old) that opens in 1:1 chat
          // and new MMT that redirects to Cytric Easy app and opens popup there
          //
          // This can be removed after 1-2 Cytric Easy releases
          setDeprecatedMMT(true);
        }
      });
    });
    setDevice(getDevice());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let isMobileOrTablet: boolean | undefined;
  let isMobile: boolean | undefined;

  if (device === "tablet") {
    isMobileOrTablet = true;
    isMobile = false;
  } else if (device === "mobile") {
    isMobileOrTablet = true;
    isMobile = true;
  } else {
    isMobileOrTablet = false;
    isMobile = false;
  }

  return (
    <Flex
      column
      gap="gap.large"
      styles={getThemeStyles}
      className={[stylesShareFlight.shareContent, matchMyTrip.content].join(
        " "
      )}
    >
      <MatchMyTrip
        t={t}
        closeTask={closeTask}
        isMobileOrTablet={isMobileOrTablet}
        location={location}
        submitTask={submitTask}
        deprecatedMMT={deprecatedMMT}
        isShareJoin={false}
      />
    </Flex>
  );
}

export default MatchMyTripTaskModule;
