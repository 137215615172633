import { ITripV1 } from "../components/upcoming-trips-dashboard/UpcomingTrips/UpcomingTripsV1.model";

export default function tripTitleName(
  t: any,
  title: string,
  customTitle?: string
) {
  return customTitle || (title ? `${t("tripDetails.tripTo")} ${title}` : "");
}

function checkTripCarByProducts(trip: ITripV1) {
  let carProduct: string[] = [];
  if (trip?.products) {
    carProduct = trip.products
      .map((product: { productType: string }) => product.productType)
      .filter(
        (value: string, index: number, self: string[]) =>
          self.indexOf(value) === index
      );
  }
  return carProduct.length === 1 && carProduct[0] === "Trip_Product_Car";
}

function checkTripCarByOverview(trip: ITripV1) {
  const { containsAir, containsHotel, containsRail, containsCar } =
    trip?.overview || {};
  return containsCar && !containsAir && !containsHotel && !containsRail;
}

function checkOnlyCarTrip(trip: ITripV1) {
  return checkTripCarByOverview(trip) || checkTripCarByProducts(trip);
}

function getNotOnlyCarProduct(t: any, trip: ITripV1, isDashboard = false) {
  if (isDashboard) {
    return trip.customTitle || trip.title;
  }
  return tripTitleName(t, trip?.title, trip?.customTitle);
}

function getCarTitleName(t: any, trip: ITripV1) {
  return (
    trip.customTitle || t("upcoming_trips_dashboard.upcomingTrips.tripTitleCar")
  );
}

export function complexTitleNameManager(
  t: any,
  trip: any,
  isDashboard = false
) {
  if (checkOnlyCarTrip(trip)) {
    return getCarTitleName(t, trip);
  }
  return getNotOnlyCarProduct(t, trip, isDashboard);
}
