import { Flex, Image, Text } from "@fluentui/react-northstar";
import { useTranslation } from "react-i18next";
import HostSettings from "../../../../utils/host.settings";
import TripApprovalFooter from "../footer/TripApprovalFooter";
import NoCommentsStyles from "./NoCommentsContainer.module.scss";

function NoCommentsContainer({
  action,
  city,
  traveler,
  handleClick,
  themeStyle,
}: any) {
  const { t } = useTranslation("translation", {
    keyPrefix: `tripApproval.noComments`,
  });

  const getTitle = () => {
    let title = "";
    const travelerFirstName =
      traveler !== "" && traveler !== undefined
        ? traveler.trim().split(" ")[0]
        : "";
    switch (action) {
      case "approve":
        title =
          travelerFirstName !== ""
            ? t("approve.title", { traveler: travelerFirstName })
            : t("approve.titleNoName", { city });
        break;
      case "reject":
        title = t("reject.title");
        break;
      default:
        title = "";
    }

    return title;
  };

  return (
    <div className={NoCommentsStyles.content}>
      <Flex
        column
        vAlign="center"
        hAlign="center"
        className={NoCommentsStyles.container}
        styles={(theme) => ({
          backgroundColor:
            theme.theme.siteVariables?.colorScheme?.default.background,
        })}
        id="not-display-comments"
      >
        <Flex className={NoCommentsStyles.imageContainer}>
          <Image
            src={`${HostSettings.getCDNImages}approval-pending.svg`}
            className={NoCommentsStyles.image}
          />
        </Flex>
        <Flex className={NoCommentsStyles.textContainer}>
          <Text
            className={NoCommentsStyles.title}
            data-testid="no-comments-title"
            content={getTitle()}
            aria-label={getTitle()}
          />
          <Text
            className={NoCommentsStyles.description}
            data-testid="no-comments-description"
            content={t("description")}
            aria-label={t("description")}
          />
        </Flex>
      </Flex>
      <TripApprovalFooter
        noComments="true"
        action={action}
        handleClick={handleClick}
        themeStyle={themeStyle}
      />
    </div>
  );
}

export default NoCommentsContainer;
