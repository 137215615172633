/* eslint-disable react/react-in-jsx-scope */
import { ComponentStyleFunctionParam, Text } from "@fluentui/react-northstar";
import { useTranslation } from "react-i18next";
import Responsive from "../../../../common/components/responsive/Responsive";
import { complexTitleNameManager } from "../../../../helpers/TripTitleName";
import { useAppSelector } from "../../../../store/StoreHooks";
import DateComponent from "../../UpcomingTrips/Date/DateComponent";
import Styles from "./Header.module.scss";

function HeaderV1() {
  const { t } = useTranslation();
  const trip = useAppSelector((state) => state.tripDetailV1.tripDetails);

  const statusList = [
    "to-be-approved",
    "preapproved",
    "approved",
    "rejected",
    "rejected-not-cancelled",
    "rejected-not-canceled",
    "",
  ];

  const statusTripV1 = trip
    ? trip?.workflow?.status.replace(/_/g, "-").toLowerCase()
    : "";

  const statusStyleName = statusList.includes(statusTripV1)
    ? statusTripV1
    : "unknown";

  const STATUS_STYLE = (theme: ComponentStyleFunctionParam) => ({
    color: theme.theme.siteVariables.colors
      ? theme.theme.siteVariables.status[statusStyleName]?.color
      : "",
    borderColor: theme.theme.siteVariables.colors
      ? theme.theme.siteVariables.status[statusStyleName]?.borderColor
      : "",
    background: theme.theme.siteVariables.colors
      ? theme.theme.siteVariables.status[statusStyleName]?.background
      : "",
  });

  const status =
    trip?.workflow?.status.toLowerCase() === "rejected_not_canceled" ||
    trip?.workflow?.status.toLowerCase() === "rejected_not_cancelled"
      ? "rejected"
      : trip?.workflow?.status.replace(/_/g, " ").toLowerCase();

  const tripName = complexTitleNameManager(t, trip);

  const getTitle = () => {
    const tripTitle = t("tripDetails.trip");
    const tripStatus = t(`tripDetails.${status}`);
    return `${tripTitle} ${tripStatus}`;
  };

  const getStatus = () => {
    if (status !== null && status !== "") {
      return (
        <Text
          className={Styles[`header--status-${statusStyleName}`]}
          content={t(`tripDetails.${status}`)}
          aria-hidden
          styles={STATUS_STYLE}
        />
      );
    }
    return null;
  };

  return (
    <div className={Styles.header} data-testid="trip-details-header">
      <Responsive forRange="mobile">
        <Text
          className={Styles.header__titleMobile}
          as="div"
          styles={(theme) => ({
            color: theme.theme.siteVariables.upcomingTrips?.header.title,
          })}
          title={tripName}
        >
          {tripName}
        </Text>
      </Responsive>
      <Responsive forMinSize="tablet">
        <Text
          className={Styles.header__title}
          as="div"
          styles={(theme) => ({
            color: theme.theme.siteVariables.upcomingTrips?.header.title,
          })}
          title={tripName}
          data-testid="trip-name"
        >
          <h2>{tripName}</h2>
        </Text>
      </Responsive>

      <div className={Styles["header__date-and-status-container"]}>
        <div className={Styles.header__date}>
          <DateComponent
            start_date={trip?.start?.localDateTime}
            end_date={trip?.end?.localDateTime}
          />
        </div>
        <div title={getTitle()}>{getStatus()}</div>
      </div>
    </div>
  );
}

export default HeaderV1;
