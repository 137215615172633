import moment from "moment";
import {
  ShareHotelCardData,
  ShareHotelParamsModel,
} from "../models/ShareHotelModel";

import HotelRedirectionInput from "../../../common/models/redirection/HotelRedirectionInput";
import RedirectionTypeEnum from "../../../common/models/redirection/RedirectionTypeEnum";
import i18n from "../../../i18n/i18n";
import {
  HOTEL_STOP_ON_START_PAGE,
  HOTEL_STOP_ON_START_PAGE_OBSOLETE,
} from "../../../utils/constants";
import { ShareHotelParamsModelShort } from "../models/ShareHotelShortModel";
import {
  getAdaptiveCardImageUrl,
  getSearchDeeplink,
  getformattedDateTime,
} from "./ShareHelper";

function GetHotelSearchURL(
  hotel: ShareHotelParamsModel,
  isFeatureJoinHotelActive: boolean
) {
  const deeplinkData: HotelRedirectionInput = {
    segmentType: RedirectionTypeEnum.HOTEL,
    locationCode: hotel.cityCode,
    locationName: hotel.hotelName,
    arrival: moment(hotel.checkinDate).format("yyyy-MM-DD").toString(),
    departure: moment(hotel.checkoutDate).format("yyyy-MM-DD").toString(),
    latitude: hotel.latitude,
    longitude: hotel.longitude,
    name: isFeatureJoinHotelActive ? hotel.hotelName : undefined,
    stopOnStartPage: isFeatureJoinHotelActive
      ? HOTEL_STOP_ON_START_PAGE
      : HOTEL_STOP_ON_START_PAGE_OBSOLETE,
    buttonType: "bookHotel",
    purpose: hotel.tripPurposeId,
  };

  return getSearchDeeplink(deeplinkData);
}

export function isValidHotelData(data: any): data is ShareHotelParamsModel {
  return (
    data.hotelName !== undefined &&
    data.roomType !== undefined &&
    data.checkinDate !== undefined &&
    data.checkoutDate !== undefined &&
    data.mediaHref !== undefined &&
    data.street !== undefined &&
    data.cityName !== undefined &&
    data.stateCode !== undefined &&
    data.cityCode !== undefined &&
    data.postalCode !== undefined &&
    data.countryName !== undefined &&
    data.distanceToCenter !== undefined
  );
}

/**
 * Returns translated string representing room type.
 * In theory, there should be only 2 types of rooms: single and double.
 *
 * @param roomType room type from deeplink data
 * @returns translated string representing room type
 */
function getRoomTypeText(roomType: string) {
  const roomTypeLc = roomType.toLocaleLowerCase();
  if (
    roomTypeLc.includes("single") ||
    roomTypeLc.includes(
      i18n.t("shareTrip.adaptiveCard.hotel.singleRoom").toLocaleLowerCase()
    )
  ) {
    return i18n.t("shareTrip.adaptiveCard.hotel.singleRoom");
  }
  return i18n.t("shareTrip.adaptiveCard.hotel.doubleRoom");
}

export function getShareHotelCardData(
  hotel: ShareHotelParamsModel,
  isFeatureJoinHotelActive: boolean,
  isPreview: boolean
) {
  const startDate = moment(hotel.checkinDate);
  const endDate = moment(hotel.checkoutDate);
  const dayInterval = endDate.diff(startDate, "days");

  let labelBreakfast = "";
  if (hotel.breakfast !== "") {
    labelBreakfast =
      hotel.breakfast === "true"
        ? i18n.t("shareTrip.adaptiveCard.hotel.breakfastIncluded")
        : i18n.t("shareTrip.adaptiveCard.hotel.breakfastNotIncluded");
  } else {
    labelBreakfast = i18n.t("shareTrip.adaptiveCard.hotel.breakfastUnknown");
  }

  let labelPerson = "";
  if (hotel.maxOccupancy === undefined || hotel.maxOccupancy === "") {
    labelPerson = hotel.roomType === "Single room" ? "1" : "2";
  } else {
    labelPerson = hotel.maxOccupancy;
  }

  const shareHotelCardData: ShareHotelCardData = {
    hotelName: hotel.hotelName,
    chainName: hotel.chainName,
    roomType: hotel.roomType,
    checkinDate: getformattedDateTime(hotel.checkinDate),
    checkoutDate: getformattedDateTime(hotel.checkoutDate),
    mediaHref: getAdaptiveCardImageUrl(hotel.mediaHref),
    street: hotel.street,
    cityName: hotel.cityName,
    cityCode: hotel.cityCode,
    stateCode: hotel.stateCode,
    postalCode: hotel.postalCode,
    countryName: hotel.countryName,
    distanceToCenter: hotel.distanceToCenter,
    latitude: hotel.latitude,
    longitude: hotel.longitude,
    breakfast: hotel.breakfast,
    maxOccupancy: hotel.maxOccupancy,
    stayDuration: dayInterval.toString(),
    occupancyLabel: labelPerson,
    breakfastLabel: labelBreakfast,
    redirectionURL: GetHotelSearchURL(hotel, isFeatureJoinHotelActive),
    isPreview,
    bookingOptionText: i18n.t("shareTrip.adaptiveCard.bookingOption"),
    bookingDetailsText: i18n.t("shareTrip.adaptiveCard.bookingOptionDetails"),
    bookingAvailabilityText: i18n.t(
      "shareTrip.adaptiveCard.bookingAvailability"
    ),
    bookingButtonText: i18n.t("shareTrip.hotelDeepLink"),
    nightText:
      dayInterval.valueOf() > 1
        ? i18n.t("shareTrip.adaptiveCard.hotelNights")
        : i18n.t("shareTrip.adaptiveCard.hotelOneNight"),
    maxOccupancyText: i18n.t("shareTrip.adaptiveCard.hotel.maxOccupancy"),
    distanceFromCenterText: i18n.t(
      "shareTrip.adaptiveCard.hotel.distanceFromCenter"
    ),
    roomTypeText: getRoomTypeText(hotel.roomType),
  };

  return shareHotelCardData;
}

/**
 * Ensures correct mapping from data shared from Cytric Classic to Cytric Easy.
 * Links shared from Cytric Classic have limitation of 1900 characters, so all keys were shortened to only 3 characters.
 *
 * Example: hotelName -> hna
 * One exception: segmentType key is not shortened..
 *
 * @param data  from Cytric Classic
 * @returns The mapped ShareHotelParamsModel or null if an error occurs.
 */
export function mapToShareHotelParamsModel(
  data: ShareHotelParamsModelShort
): ShareHotelParamsModel | null {
  try {
    return {
      segmentType: RedirectionTypeEnum.SHARE_HOTEL,
      tripPurposeId: data.tpi,
      hotelName: data.hna,
      chainName: data.cna,
      roomType: data.rty,
      checkinDate: data.cid,
      checkoutDate: data.cod,
      mediaHref: data.mhr,
      street: data.str,
      cityName: data.cin,
      cityCode: data.cco,
      stateCode: data.sco,
      postalCode: data.pco,
      countryName: data.con,
      distanceToCenter: data.dtc,
      longitude: parseFloat(data.lon),
      latitude: parseFloat(data.lat),
      breakfast: data.bre,
      maxOccupancy: data.moc,
    };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Error in mapToShareHotelParamsModel: ", error);
    return null;
  }
}
