import { telemetryService } from "@amadeus-cytric/cytric-teams-react-common-library";
import { Flex } from "@fluentui/react-northstar";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import RedirectionTypeEnum from "../../../../../../../../common/models/redirection/RedirectionTypeEnum";
import { complexTitleNameManager } from "../../../../../../../../helpers/TripTitleName";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/StoreHooks";
import { getCarPermissions } from "../../../../../../../../store/flows/FlowsPermissionsSelector";
import { getProductsByTypeV1 } from "../../../../../../../../store/trip-details-v1/selectors/trip-detail-v1-selectors";
import { getCarDetailsV1 } from "../../../../../../../../store/trip-details-v1/selectors/trip-detail-v1-selectors-car";
import CheckFeatureToggle from "../../../../../../../../utils/CheckFeatureToggle";
import { FeatureToggleDefinition } from "../../../../../../../../utils/constants";
import { SegmentType } from "../../../../../../UpcomingTrips/CardBookTripButtons/model/Permissions";
import { TRIP_PRODUCT_CAR } from "../../../../../../data/constants";
import BookingRedirections from "../../../../BookingRedirections";
import CheckFeatureEnabled from "../../../../CheckFeatureEnabled";
import Placeholder from "../../Placeholder/Placeholder";
import HandleSegmentPermissions from "../Common/HandleSegmentPermissions";
import NewProduct from "../components/NewProduct/NewProduct";
import { ICarV1 } from "./Car.model";
import CarSegment from "./CarSegment/CarSegment";
import showComponent from "./ShowComponent";

function Car({ pastTrip }: any) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const cars = useAppSelector((state) =>
    getProductsByTypeV1(state, TRIP_PRODUCT_CAR)
  );

  const permissions: SegmentType = useAppSelector((state) =>
    getCarPermissions(state)
  );

  const isBlackListed = CheckFeatureToggle(
    FeatureToggleDefinition.tmsSettingsBookings.id
  );

  const trip = useAppSelector((state) => state.tripDetailV1.tripDetails);

  const bookCar = () => {
    const name = complexTitleNameManager(t, trip);
    const tripId = trip.id;

    localStorage.removeItem("KeepSessionCache");
    localStorage.setItem("tripDetailTab", "");

    telemetryService.trackEvent(
      { name: "travel-booking-bookCar" },
      {
        numOfCars: 0,
        tripId: trip.id,
      }
    );

    const redirectionInput = {
      segmentType: RedirectionTypeEnum.ADD_CAR,
      tripId,
    };

    BookingRedirections(t, dispatch, history, redirectionInput, tripId, name);
  };

  const bookTransfer = () => {
    const name = complexTitleNameManager(t, trip);
    const tripId = trip.id;

    localStorage.removeItem("KeepSessionCache");
    localStorage.setItem("tripDetailTab", "");

    telemetryService.trackEvent(
      { name: "travel-booking-bookTransfer" },
      {
        numOfCars: 0,
        tripId: trip.id,
      }
    );

    const redirectionInput = {
      segmentType: RedirectionTypeEnum.ADD_TRANSFER,
      tripId,
    };

    BookingRedirections(t, dispatch, history, redirectionInput, tripId, name);
  };

  const getCarValue = (_e: any, val: any) => {
    if (val.searchQuery !== "") {
      const trad = t("upcoming_trips_dashboard.upcomingTrips.newBook.Car");
      if (val.searchQuery === trad) {
        bookCar();
      } else {
        bookTransfer();
      }
    }
  };

  if (cars?.length) {
    return (
      <>
        {cars &&
          cars.map((car: ICarV1, index: number) => {
            const details = getCarDetailsV1(car);
            const carKey = `${car.confirmationNumber}-${index}`;
            return (
              <CarSegment
                details={details}
                key={carKey}
                index={index + 1}
                totalitems={cars.length}
              />
            );
          })}
        {CheckFeatureEnabled() &&
          !pastTrip &&
          NewProduct({ productType: "car", numOfProducts: cars.length })}
      </>
    );
  }
  return (
    <Flex column hAlign="center" vAlign="center">
      <Placeholder
        text={t("upcoming_trips_dashboard.tripDetails.placeholder.car")}
      />
      {CheckFeatureEnabled() &&
        HandleSegmentPermissions(permissions, isBlackListed) && (
          <Flex gap="gap.small">
            {showComponent(
              permissions?.bookingTargets,
              t,
              getCarValue,
              bookCar,
              bookTransfer
            )}
          </Flex>
        )}
    </Flex>
  );
}
export default Car;
