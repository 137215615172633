/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/rules-of-hooks */
import { telemetryService } from "@amadeus-cytric/cytric-teams-react-common-library";
import { Button, Flex } from "@fluentui/react-northstar";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import RedirectionTypeEnum from "../../../../../../../../common/models/redirection/RedirectionTypeEnum";
import { complexTitleNameManager } from "../../../../../../../../helpers/TripTitleName";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/StoreHooks";
import { getHotelPermissions } from "../../../../../../../../store/flows/FlowsPermissionsSelector";
import { getProductsByTypeV1 } from "../../../../../../../../store/trip-details-v1/selectors/trip-detail-v1-selectors";
import { getHotelDetails } from "../../../../../../../../store/trip-details-v1/selectors/trip-detail-v1-selectors-hotel";
import CheckFeatureToggle from "../../../../../../../../utils/CheckFeatureToggle";
import { FeatureToggleDefinition } from "../../../../../../../../utils/constants";
import { SegmentType } from "../../../../../../UpcomingTrips/CardBookTripButtons/model/Permissions";
import { TRIP_PRODUCT_HOTEL } from "../../../../../../data/constants";
import BookingRedirections from "../../../../BookingRedirections";
import CheckFeatureEnabled from "../../../../CheckFeatureEnabled";
import Placeholder from "../../Placeholder/Placeholder";
import HandleSegmentPermissions from "../Common/HandleSegmentPermissions";
import NewProduct from "../components/NewProduct/NewProduct";
import { IHotelSegmentV1 } from "./Hotel.model";
import HotelSegment from "./HotelSegment/HotelSegment";

function Hotel({ pastTrip }: any) {
  const { t } = useTranslation();
  const hotelsV1 = useAppSelector((state) =>
    getProductsByTypeV1(state, TRIP_PRODUCT_HOTEL)
  );
  const dispatch = useAppDispatch();
  const history = useHistory();

  const trip = useAppSelector((state) => state.tripDetailV1.tripDetails);

  const permissions: SegmentType = useAppSelector((state) =>
    getHotelPermissions(state)
  );
  const isBlackListed = CheckFeatureToggle(
    FeatureToggleDefinition.tmsSettingsBookings.id
  );

  const bookHotel = () => {
    const name = complexTitleNameManager(t, trip);
    const tripId = trip.id;

    localStorage.removeItem("KeepSessionCache");
    localStorage.setItem("tripDetailTab", "");

    telemetryService.trackEvent(
      { name: "travel-booking-bookHotel" },
      {
        numOfHotels: 0,
        tripId: trip.id,
      }
    );
    const redirectionInput = {
      segmentType: RedirectionTypeEnum.ADD_HOTEL,
      tripId: trip.id,
    };

    BookingRedirections(t, dispatch, history, redirectionInput, tripId, name);
  };

  if (hotelsV1?.length) {
    return (
      <>
        {hotelsV1 &&
          hotelsV1.map((hotel: IHotelSegmentV1, index: number) => {
            const details = getHotelDetails(hotel);
            const key = `${hotel.name}-${index}`;
            return (
              <HotelSegment
                details={details}
                key={key}
                index={index + 1}
                totalitems={hotelsV1.length}
              />
            );
          })}
        {CheckFeatureEnabled() &&
          !pastTrip &&
          NewProduct({ productType: "hotel", numOfProducts: hotelsV1.length })}
      </>
    );
  }

  return (
    <Flex column hAlign="center" vAlign="center">
      <Placeholder
        text={t("upcoming_trips_dashboard.tripDetails.placeholder.hotel")}
      />
      {CheckFeatureEnabled() &&
        HandleSegmentPermissions(permissions, isBlackListed) && (
          <Button
            aria-label={t(
              "upcoming_trips_dashboard.tripDetails.newProduct.buttons.hotel"
            )}
            content={t(
              "upcoming_trips_dashboard.tripDetails.newProduct.buttons.hotel"
            )}
            onClick={() => bookHotel()}
            flat
          />
        )}
    </Flex>
  );
}

export default Hotel;
