import { useTeams } from "msteams-react-base-component";
import CheckFeatureToggle from "../../../../utils/CheckFeatureToggle";
import { FeatureToggleDefinition } from "../../../../utils/constants";
import OldCard from "./OldCard";

function CardBookTrip() {
  const [{ themeString }] = useTeams();
  const themeName = themeString === "default" ? "light" : "dark";
  const isTRIPPUser = CheckFeatureToggle(
    FeatureToggleDefinition.TRIPPUpcomingTrips.id
  );

  const renderCardBookTrip = () => {
    if (isTRIPPUser) {
      return OldCard(false, themeName);
    }
    return OldCard(false, themeName);
  };

  return renderCardBookTrip();
}

export default CardBookTrip;
