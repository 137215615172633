/* eslint-disable no-nested-ternary */
import {
  Alert,
  CheckmarkCircleIcon,
  InfoIcon,
  Text,
} from "@fluentui/react-northstar";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import RedirectionTypeEnum from "../../../../common/models/redirection/RedirectionTypeEnum";
import { useAppDispatch } from "../../../../store/StoreHooks";
import { breadcrumbActions } from "../../../../store/breadcrumb/breadcrumb-slice";
import {
  DONT_MERGE_TYPE,
  MERGE_ERROR_TYPE,
  MERGE_TYPE,
} from "../../../../utils/constants";
import NO_LABEL_NO_URL from "../../../personal-tab/breadcrumb/constants";
import styles from "./MergeAlert.module.scss";

interface AlertInterface {
  mergeType: string | null;
  setMergeType: React.Dispatch<React.SetStateAction<string | null>>;
}

function MergeAlert({ mergeType, setMergeType }: AlertInterface) {
  const { t } = useTranslation("translation", {
    keyPrefix: "expenses-dashboard",
  });
  const dispatch = useAppDispatch();
  const history = useHistory();

  const totalMergedExpenses = Number(
    localStorage.getItem("totalMergedExpenses")
  );

  const alertTextClass = "merge-alert__text";

  const viewYourExpensesHandler = () => {
    dispatch(
      breadcrumbActions.setExpenseLevel2({
        label: t("yourExpenses"),
        url: "",
      })
    );
    dispatch(breadcrumbActions.setExpenseLevel3(NO_LABEL_NO_URL));
    history.push(
      `/classic?segmentType=${RedirectionTypeEnum.EXPENSE_DASHBOARD}`
    );
  };

  const getAlertContent = () => {
    let alertContent;
    switch (mergeType) {
      case MERGE_TYPE:
        alertContent = (
          <Text className={styles[alertTextClass]}>
            {t("alert.successMergeMessage", {
              count: totalMergedExpenses,
            })}
          </Text>
        );
        break;
      case DONT_MERGE_TYPE:
        alertContent = (
          <>
            <Text className={styles[alertTextClass]}>
              {t("alert.cancelMergeMessage")}
            </Text>
            <Text
              className={styles["merge-alert__link"]}
              as="button"
              role="link"
              data-testid="link-to-unassigned-expenses"
              onClick={() => viewYourExpensesHandler()}
            >
              {t("alert.cancelMergeMessageLink")}
            </Text>
          </>
        );
        break;
      case MERGE_ERROR_TYPE:
        alertContent = (
          <Text className={styles[alertTextClass]}>
            {t("alert.errorMergeMessage")}
          </Text>
        );
        break;
      default:
        break;
    }
    return alertContent;
  };

  return (
    <Alert
      id="alert__message"
      aria-live="assertive"
      role="alert"
      success={mergeType === MERGE_TYPE}
      info={mergeType === DONT_MERGE_TYPE}
      danger={mergeType === MERGE_ERROR_TYPE}
      icon={mergeType === MERGE_TYPE ? <CheckmarkCircleIcon /> : <InfoIcon />}
      content={mergeType && getAlertContent()}
      dismissible
      dismissAction={{
        "aria-label": "close",
        onClick: () => {
          setMergeType(null);
        },
      }}
    />
  );
}

export default MergeAlert;
