import { SegmentType } from "../../../../../../UpcomingTrips/CardBookTripButtons/model/Permissions";

export default function HandleSegmentPermissions(
  permission: SegmentType,
  blacklisted: boolean
) {
  if (permission && blacklisted === false) {
    return true;
  }
  return false;
}
