import { telemetryService } from "@amadeus-cytric/cytric-teams-react-common-library";
import { Flex, Loader } from "@fluentui/react-northstar";
import { BotUrlDialogInfo, app, dialog } from "@microsoft/teams-js";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import GenericInfo from "../../common/components/generic-info-component/GenericInfo";
import {
  IGenericInfo,
  IGenericInfoAction,
  InfoImage,
} from "../../common/components/generic-info-component/GenericInfo.model";
import doLogout from "../../common/components/logout/logout-component";
import { IError } from "../../common/models/Error";
import RedirectionInput from "../../common/models/redirection/RedirectionInput";
import RedirectionTypeEnum from "../../common/models/redirection/RedirectionTypeEnum";
import Store from "../../store/Store";
import { useAppDispatch, useAppSelector } from "../../store/StoreHooks";
import { isLoggedInSelector } from "../../store/authentication/AuthenticationSelector";
import { breadcrumbActions } from "../../store/breadcrumb/breadcrumb-slice";
import {
  resetCytricExpenseContext,
  updateCytricExpenseContext,
} from "../../store/cytric-context/CytricExpenseContextSlice";
import { expenseDashboardActions } from "../../store/expense-dashboard/ExpenseDashboardSlice";
import { selectFeatureToggle } from "../../store/feature-toggles/FeatureTogglesSelector";
import { loadCytricUrl } from "../../store/redirection/RedirectionActions";
import {
  errorInformation,
  selectCytricUrl,
  selectRedirectionIsError,
  selectRedirectionIsPending,
} from "../../store/redirection/RedirectionSelector";
import GetAppRoot from "../../utils/GetAppRoot";
import {
  CYTRIC_EASY_LOGOUT,
  EXPENSE_APPROVER_FINAL,
  EXPENSE_APPROVER_FIRST,
  EXPENSE_KEEP_CONTEXT_LOCAL_STORAGE_KEY,
  FeatureToggleDefinition,
  HOTEL_STOP_ON_START_PAGE,
  HOTEL_STOP_ON_START_PAGE_OBSOLETE,
} from "../../utils/constants";
import HostSettings from "../../utils/host.settings";
import { isExpenseRelatedSegmentType } from "../expense-dashboard/redirection/ExpenseRedirectionHelper";
import styles from "./CytricClassic.module.scss";
import Breadcrumb from "./breadcrumb/Breadcrumb";
import NO_LABEL_NO_URL from "./breadcrumb/constants";

function CytricClassic() {
  const sessionExpiredPageName = "page-session-Expired";
  const viewExpenseInCytric = "viewExpenseInCytric";
  const viewExpenseEventName = "expenses-notification-approverSeeStatement";

  const pagesDisplayedAfterLogout = [
    "page-session-Logout",
    "page-portal-PortalPublic",
    "page-session-Login",
  ];

  const dispatch = useAppDispatch();

  const dashboardInputExpense: RedirectionInput = {
    segmentType: RedirectionTypeEnum.EXPENSE_DASHBOARD,
  };

  const [isCytricSessionExpired, setIsCytricSessionExpired] = useState(false);
  const [isCytricLoaded, setIsCytricLoaded] = useState(false);

  const cytricUrl: string = useAppSelector((state) => selectCytricUrl(state));

  const errorInfo: IError = useAppSelector((state) => errorInformation(state));
  const redirectionIsPending: boolean = useAppSelector((state) =>
    selectRedirectionIsPending(state)
  );
  const redirectionIsError: boolean = useAppSelector((state) =>
    selectRedirectionIsError(state)
  );
  const isLoggedIn: boolean = useAppSelector((state) =>
    isLoggedInSelector(state)
  );

  const featureJoinHotel = useAppSelector((state) =>
    selectFeatureToggle(state, FeatureToggleDefinition.JoinHotel.id)
  );

  const subEntityStored = useAppSelector(
    (state) => state.expenseDashbard.subEntity
  );

  const { t } = useTranslation();

  const isFromTrustedOrigin = (event: MessageEvent<any>) => {
    const trustedOrigins = HostSettings.iFrameTrustedOrigins;
    return (
      trustedOrigins.length > 0 &&
      trustedOrigins.some((pattern) => event.origin.match(pattern) != null)
    );
  };

  const isValidUrl = (urlString: string) => {
    try {
      const url = new URL(urlString);
      return url.protocol === "http:" || url.protocol === "https:";
    } catch (_) {
      return false;
    }
  };

  const refreshActionButton = () => {
    setIsCytricSessionExpired(false);
    Store.dispatch(resetCytricExpenseContext());
    Store.dispatch(loadCytricUrl(dashboardInputExpense));
  };

  const containsRequiredData = (event: MessageEvent<any>) =>
    event.data &&
    event.data.currentUrl &&
    event.data.currentUrl.length > 0 &&
    isValidUrl(event.data.currentUrl);

  const containsNotificationData = (event: MessageEvent<any>) =>
    event.data &&
    event.data.url &&
    event.data.url.length > 0 &&
    isValidUrl(event.data.url);

  const DisplaySharePopup = (deepLinkData: any) => {
    const appRoot = GetAppRoot();

    const popupData = JSON.stringify(deepLinkData);

    let taskModuleInfo: BotUrlDialogInfo;

    if (deepLinkData.segmentType === RedirectionTypeEnum.CHAT_TRAVELER) {
      taskModuleInfo = {
        title: t("chatWithTraveler.title"),
        url: `${appRoot}/index.html#/open-chat?${popupData}`,
        size: {
          width: 600,
          height: 600,
        },
        completionBotId: HostSettings.getBotId,
      };
    } else {
      taskModuleInfo = {
        title: "Share my trip",
        url: `${appRoot}/index.html#/share-from-classic?${popupData}`,
        size: {
          width: 600,
          height: 600,
        },
        completionBotId: HostSettings.getBotId,
      };
    }

    dialog.url.bot.open(taskModuleInfo);
  };

  const postMessageListener = (event: MessageEvent<any>) => {
    if (
      containsRequiredData(event) &&
      (isFromTrustedOrigin(event) || event.isTrusted)
    ) {
      setIsCytricLoaded(true);
      const url = event.data.currentUrl;
      const pageName = event.data.currentPage;

      if (sessionExpiredPageName === pageName) {
        setIsCytricSessionExpired(true);
        Store.dispatch(resetCytricExpenseContext());
        return;
      }

      if (pagesDisplayedAfterLogout.includes(pageName)) {
        doLogout();

        // signal to the settings tab, that it should perform a logout
        localStorage.setItem(CYTRIC_EASY_LOGOUT, "performLogout");

        const encodedContext = encodeURIComponent('{"subEntityId": "logout"}');
        app.openLink(
          `https://teams.microsoft.com/l/entity/${HostSettings.teamsAppId}/settings?context=${encodedContext}`
        );
        return;
      }

      Store.dispatch(
        updateCytricExpenseContext({
          urlExpense: url,
          pageNameExpense: pageName,
        })
      );
    }

    if (
      containsNotificationData(event) &&
      (isFromTrustedOrigin(event) || event.isTrusted)
    ) {
      const matches = event.data.url.match(/context=([^&]*)/);
      if (matches && matches.length >= 2) {
        const decodedContextData = decodeURIComponent(matches[1]);
        const parsedContext = JSON.parse(decodedContextData);
        DisplaySharePopup(JSON.parse(parsedContext.subEntityId));
      }
    }
  };

  const dispatchExpenseBreadcrumbs = (level2: any, level3: any) => {
    dispatch(breadcrumbActions.setExpenseLevel2(level2));
    dispatch(breadcrumbActions.setExpenseLevel3(level3));
  };

  const checkIsTaskModuleRequest = (params: any) => {
    if (params.segmentType === RedirectionTypeEnum.CHAT_TRAVELER) {
      DisplaySharePopup(params);
    } else if (params.source === viewExpenseInCytric) {
      switch (params.userRole) {
        case EXPENSE_APPROVER_FIRST:
          dispatchExpenseBreadcrumbs(
            {
              label: t("expenses-dashboard.todo.breadcrumb.firstLevelApproval"),
              url: `/classic?segmentType=${RedirectionTypeEnum.EXPENSE_DASHBOARD}&userRole=${EXPENSE_APPROVER_FIRST}`,
            },
            {
              label: params.expenseTitle,
              url: "",
            }
          );
          break;
        case EXPENSE_APPROVER_FINAL:
          dispatchExpenseBreadcrumbs(
            {
              label: t("expenses-dashboard.todo.breadcrumb.finalLevelApproval"),
              url: `/classic?segmentType=${RedirectionTypeEnum.EXPENSE_DASHBOARD}&userRole=${EXPENSE_APPROVER_FINAL}`,
            },
            {
              label: params.expenseTitle,
              url: "",
            }
          );
          break;
        default:
          if (params.expenseTitle) {
            dispatchExpenseBreadcrumbs(
              {
                label: t("expenses-dashboard.yourExpenses"),
                url: `/classic?segmentType=${RedirectionTypeEnum.EXPENSE_DASHBOARD}`,
              },
              {
                label: params.expenseTitle,
                url: "",
              }
            );
          } else {
            dispatchExpenseBreadcrumbs(
              {
                label: t("expenses-dashboard.yourExpenses"),
                url: "",
              },
              NO_LABEL_NO_URL
            );
          }
      }

      Store.dispatch(loadCytricUrl(params as any));
    } else {
      Store.dispatch(loadCytricUrl(params as any));
    }
  };

  const amend = (params: any) => {
    const amendedParams = params;
    if (amendedParams.segmentType === RedirectionTypeEnum.HOTEL) {
      amendedParams.name = featureJoinHotel?.isActive
        ? amendedParams.name
        : undefined;
      amendedParams.stopOnStartPage = featureJoinHotel?.isActive
        ? HOTEL_STOP_ON_START_PAGE
        : HOTEL_STOP_ON_START_PAGE_OBSOLETE;
    }

    return amendedParams;
  };

  const trackExpenseEvent = (params: any) => {
    if (
      params.segmentType === RedirectionTypeEnum.EXPENSE_DASHBOARD &&
      params.source === viewExpenseInCytric
    ) {
      const properties = {
        statementId: params.expenseId,
      };

      telemetryService.trackEvent({
        name: viewExpenseEventName,
        properties,
      });
    }
  };

  /**
   * Stores the iframe URL parameters in local storage.
   * @param params - The URL parameters to be stored.
   */
  const storeIframeUrlParamsInLocalStorage = (params: string) => {
    const expiryTime =
      new Date().getTime() + parseInt(HostSettings.getKeepSessionTimeout, 10);

    const lastVisitedExpenseParams = {
      expenseURLParams: params,
      expiryTime,
    };

    localStorage.setItem(
      EXPENSE_KEEP_CONTEXT_LOCAL_STORAGE_KEY,
      JSON.stringify(lastVisitedExpenseParams)
    );
  };

  const handleUrlParams = () => {
    const currentUrl = window.location.href;
    const urlParams = new URLSearchParams(currentUrl.split("?")[1]);
    if (urlParams && Array.from(urlParams).length > 0) {
      const params = Object.fromEntries(urlParams.entries());
      checkIsTaskModuleRequest(amend(params));
      storeIframeUrlParamsInLocalStorage(urlParams.toString());
    } else {
      Store.dispatch(loadCytricUrl(dashboardInputExpense));
      storeIframeUrlParamsInLocalStorage(
        `segmentType=${dashboardInputExpense.segmentType.toString()}`
      );
    }
  };

  const handleSubEntity = (subEntity: string) => {
    const entityJson = JSON.parse(subEntity);
    if (isExpenseRelatedSegmentType(entityJson.segmentType)) {
      try {
        checkIsTaskModuleRequest(amend(entityJson));
      } catch (error) {
        Store.dispatch(loadCytricUrl(dashboardInputExpense));
      }
      Store.dispatch(expenseDashboardActions.setSubEntity(subEntity));
      trackExpenseEvent(entityJson);
      storeIframeUrlParamsInLocalStorage(entityJson);
    } else {
      handleUrlParams();
    }
  };

  // use effect on load
  useEffect(() => {
    window.addEventListener("message", postMessageListener, false);
    if (isLoggedIn) {
      app.getContext().then((context: app.Context) => {
        const subEntity = context?.page?.subPageId;
        if (subEntity && subEntity !== subEntityStored) {
          handleSubEntity(subEntity);
        } else {
          handleUrlParams();
        }
      });

      telemetryService.trackPageView({ name: "cytric-web" });
    }
    // cleanup this component
    return () => {
      window.removeEventListener("message", postMessageListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  const actionRefresh: IGenericInfoAction = {
    text: t("PersonalTab.Refresh"),
    command: refreshActionButton,
  };

  const refreshInfo: IGenericInfo = {
    title: t("PersonalTab.Cytric_session_expired_title"),
    detail: t("PersonalTab.Cytric_session_expired_detail"),
  };

  if (isCytricSessionExpired) {
    return (
      <GenericInfo
        infoData={refreshInfo}
        action={actionRefresh}
        image={InfoImage.SESSION_EXPIRED}
      />
    );
  }

  const loaderComponent = (
    <Loader
      size="large"
      className={styles["cytric-classic__loader"]}
      data-testid="loadingSpinner"
      label={t("spinner.waitAMoment")}
    />
  );

  if (redirectionIsError) {
    return <GenericInfo infoData={errorInfo} image={InfoImage.ERROR} />;
  }

  return (
    <Flex
      className={styles["cytric-classic"]}
      id="cytric-classic__container"
      data-testid="cytric-classic__container"
    >
      <div className={styles["cytric-classic__container"]}>
        <Breadcrumb type="expense" />
        {(redirectionIsPending || !isCytricLoaded) && loaderComponent}
        <iframe
          className={
            isCytricLoaded
              ? `${styles["cytric-classic__iframe"]}`
              : `${styles["cytric-classic__iframe--none"]}`
          }
          id="dynamic-tab"
          title="cytric"
          src={cytricUrl}
          scrolling="true"
          sandbox="allow-forms allow-popups allow-scripts allow-top-navigation allow-modals allow-presentation allow-same-origin allow-downloads allow-popups-to-escape-sandbox"
        />
      </div>
    </Flex>
  );
}

export default CytricClassic;
