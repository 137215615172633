import RedirectionInput from "../../../common/models/redirection/RedirectionInput";
import RedirectionTypeEnum from "../../../common/models/redirection/RedirectionTypeEnum";
import RedirectionService from "../../../services/redirection/RedirectionService";
import { resetCytricContext } from "../../../store/cytric-context/CytricContextSlice";
import store from "../../../store/Store";
import { isValidUrlAndDomain } from "../../../utils/validation.utils";

export default async function GetUrlCytricNewTab(
  segmentType: RedirectionTypeEnum = RedirectionTypeEnum.DASHBOARD
) {
  store.dispatch(resetCytricContext());

  const dashboardInput: RedirectionInput = {
    segmentType,
  };
  const urlObject = await RedirectionService.getCytricUrl(dashboardInput);
  if (urlObject?.data?.data?.cytricUrl) {
    const url = urlObject.data.data.cytricUrl as string;
    if (isValidUrlAndDomain(url)) {
      window.open(url, "_blank", "noopener noreferrer");
    }
  }
}
