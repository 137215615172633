import { Http } from "@amadeus-cytric/cytric-teams-react-common-library";
import ApprovalReasonsSettings from "../../common/models/ApprovalReasonsSettings";
import NotificationSettings, {
  InitiallyIsChatAssistantEnabled,
  ScheduleExpenseStatements,
  ScheduleShareMyTransfer,
  ScheduleUnassignedExpenses,
} from "../../common/models/NotificationSettings";
import HostSettings from "../../utils/host.settings";

export default class NotificationSettingsService {
  private static readonly SERVER_URL = HostSettings.getBaseAPI;

  private static readonly GET_NOTIFICATION_SETTINGS_PATH =
    "us/v0/corporation/user/current/settings";

  private static readonly SET_NOTIFICATION_SETTINGS_PATH =
    "us/v0/corporation/user/settings";

  private static readonly APPROVAL_REASONS = "/approval/reasons";

  public static async getUserNotificationSettings(): Promise<any> {
    try {
      const response = await Http.get(
        `${this.SERVER_URL}${this.GET_NOTIFICATION_SETTINGS_PATH}`
      );

      if (response.status === 204) {
        const initialSettings: NotificationSettings = {
          scheduleUnassignedExpenses: ScheduleUnassignedExpenses.Always,
          scheduleExpenseStatements: ScheduleExpenseStatements.Always,
          scheduleShareMyTransfer: ScheduleShareMyTransfer.Never,
          isChatAssistantEnabled: InitiallyIsChatAssistantEnabled,
        };
        return await this.setUserNotificationSettings(initialSettings);
      }
      return response;
    } catch (error: any) {
      return error;
    }
  }

  public static async setUserNotificationSettings(
    notificationSettings: NotificationSettings
  ): Promise<any> {
    return Http.put(
      `${this.SERVER_URL}${this.SET_NOTIFICATION_SETTINGS_PATH}`,
      notificationSettings
    );
  }

  public static async getUserApprovalSettings(): Promise<any> {
    try {
      const response = await Http.get(
        `${this.SERVER_URL}${this.GET_NOTIFICATION_SETTINGS_PATH}${this.APPROVAL_REASONS}`
      );
      if (response.status === 200 && response.data) {
        return response.data as unknown as ApprovalReasonsSettings;
      }
      return response;
    } catch (error: any) {
      return error;
    }
  }
}
