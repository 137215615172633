/* eslint-disable react-hooks/exhaustive-deps */
import { Security } from "@amadeus-cytric/cytric-teams-react-common-library";
import { Header, Loader } from "@fluentui/react-northstar";
import React, { useEffect, useReducer, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  EXPENSE_STATEMENTS_DUPLICATES,
  EXPENSE_STATEMENTS_TO_APPROVE_FINAL,
  EXPENSE_STATEMENTS_TO_APPROVE_FIRST,
  EXPENSE_STATEMENTS_TO_SUBMIT,
  EXPENSE_STATEMENTS_UNASSIGNED,
  TODO_ROUTE_ITEM,
} from "../../../utils/constants";
import { ErrorAccessInterface } from "../ExpenseDashboardInterfaces";
import { getDashboardInfo } from "../ExpenseDashboardRequests";
import DialogPopup from "../duplicates/pop-up/dialog-popup/DialogPopup";
import ExpenseDetailsDialog from "../pop-up/ExpenseDetailsDialog";
import DialogBody from "../pop-up/dialog-body/DialogBody";
import styles from "./Todo.module.scss";
import { ItemInterface } from "./TodoInterfaces";
import TodoContent from "./todo-content/TodoContent";

interface TodoPropsInterface {
  setMergeType: React.Dispatch<React.SetStateAction<string | null>>;
  setAccessError: React.Dispatch<
    React.SetStateAction<ErrorAccessInterface | null>
  >;
}

interface CountersInterface {
  toSubmit: number;
  duplicated: number;
  notAssigned: number;
  isActivatedForDuplicates: boolean;
  toApproveFinal: number;
  toApproveFirst: number;
}

function Todo({ setMergeType, setAccessError }: TodoPropsInterface) {
  const { t } = useTranslation("translation", {
    keyPrefix: "expenses-dashboard",
  });

  const [countOfTodos, setCountOfTodos] = useState<number>(0);
  const [didMergeAction, setDidMergeAction] = useState<boolean>(false);

  const [counters, setCounters] = React.useState<CountersInterface>({
    toSubmit: -1,
    duplicated: -1,
    notAssigned: -1,
    isActivatedForDuplicates: false,
    toApproveFinal: -1,
    toApproveFirst: -1,
  });

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const isMounted = useRef(false);

  const items: ItemInterface[] = [
    {
      id: EXPENSE_STATEMENTS_TO_APPROVE_FIRST,
      header: t("todo.expensesToApproveFirst"),
      count: counters.toApproveFirst,
      track: "expenses-dashboard-viewExpensesToApproveFirstLevel",
    },
    {
      id: EXPENSE_STATEMENTS_TO_APPROVE_FINAL,
      header: t("todo.expensesToApproveFinal"),
      count: counters.toApproveFinal,
      track: "expenses-dashboard-viewExpensesToApproveFinalLevel",
    },
    {
      id: EXPENSE_STATEMENTS_TO_SUBMIT,
      header: t("todo.expensesToSubmit"),
      count: counters.toSubmit,
      track: "expenses-dashboard-viewExpensesToSubmit",
    },
    {
      id: EXPENSE_STATEMENTS_DUPLICATES,
      header: t("todo.duplicatesFound"),
      count: counters.duplicated,
      track: "expenses-dashboard-viewDuplicateExpenses",
    },
    {
      id: EXPENSE_STATEMENTS_UNASSIGNED,
      header: t("todo.unassignedExpenses"),
      count: counters.notAssigned,
      track: "expenses-dashboard-viewUnassignedExpenses",
    },
  ];

  const getCounters = async (userRoles: string[]) => {
    setIsLoading(true);
    const rolesQuery = userRoles
      .map((role: string) => `roles=${role}`)
      .join("&");
    const allInfo = await getDashboardInfo(TODO_ROUTE_ITEM, rolesQuery);

    if (allInfo.isError) {
      setAccessError(allInfo);
    } else {
      const receivedCounters = allInfo.toDoCounter;
      setCounters(receivedCounters);
    }

    if (isMounted.current) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    isMounted.current = true;

    const isFirstLevelApprover = Security.isSecured(
      "EXPENSE-ExpenseApproverRestricted"
    ).then((res) => {
      if (res) {
        return "expenseApproverRestricted";
      }
      return "";
    });

    const isFinalLevelApprover = Security.isSecured(
      "EXPENSE-ExpenseApproverFinal"
    ).then((res) => {
      if (res) {
        return "expenseApproverFinal";
      }
      return "";
    });

    Promise.all([isFirstLevelApprover, isFinalLevelApprover]).then((res) => {
      const approverRoles = res.filter((role) => role !== "");
      const userRoles = ["traveler", ...approverRoles];
      getCounters(userRoles);
    });

    return () => {
      isMounted.current = false;
    };
  }, [didMergeAction]);

  useEffect(() => {
    if (items) {
      let totalTodos: number = 0;
      items.forEach((item) => {
        totalTodos += item.count;
      });
      setCountOfTodos(totalTodos);
    }
  }, [counters]);

  const dialogReducer = (_state: any, action: any) => {
    if (action?.type === EXPENSE_STATEMENTS_TO_SUBMIT) {
      return {
        isVisible: true,
        dialogBodyComponent: <DialogBody />,
        tryAgain: action.payload,
        headerTitle: t("popup.viewList.headerTitle"),
        confirmButtonText: null,
        cancelButtonText: t("popup.cancel"),
      };
    }

    if (action?.type === "close") {
      return {
        isVisible: false,
        dialogBodyComponent: null,
        tryAgain: null,
        headerTitle: "",
        confirmButtonText: "",
        cancelButtonText: "",
      };
    }

    return {
      isVisible: false,
      dialogBodyComponent: null,
      tryAgain: null,
      headerTitle: "",
      confirmButtonText: "",
      cancelButtonText: "",
    };
  };

  const [dialogInfo, dispatchDialog] = useReducer(dialogReducer, {
    isVisible: false,
    dialogBodyComponent: null,
    tryAgain: null,
    headerTitle: "",
    confirmButtonText: "",
    cancelButtonText: "",
  });

  return (
    <>
      <Header
        id="expense__todo-list--header"
        as="h3"
        content={
          countOfTodos && !isLoading
            ? `${t("todo.listTitle")} (${countOfTodos})`
            : t("todo.listTitle")
        }
        className={styles["todo-list__title"]}
        data-testid="expense__todo-list--header"
      />
      {isLoading ? (
        <Loader />
      ) : (
        <TodoContent
          items={items}
          counts={countOfTodos}
          openDialog={setIsDialogOpen}
          setDidMergeAction={setDidMergeAction}
          dispatchDialog={dispatchDialog}
        />
      )}
      {isDialogOpen && (
        <DialogPopup
          openDialog={isDialogOpen}
          setOpenDialog={setIsDialogOpen}
          setMergeType={setMergeType}
          setDidMergeAction={setDidMergeAction}
        />
      )}
      <ExpenseDetailsDialog
        dispatchDialog={dispatchDialog}
        dialogInfo={dialogInfo}
      />
    </>
  );
}

export default Todo;
