import { telemetryService } from "@amadeus-cytric/cytric-teams-react-common-library";
import {
  EmptyStatePastTrips,
  ErrorPastTrip,
  PastTripsList,
  PastTripsSkeleton,
} from "@amadeus-cytric/msnt-cytric-frontend-cplibrary-travel";
import { useEffect, useState } from "react";
import RedirectionTypeEnum from "../../../../common/models/redirection/RedirectionTypeEnum";
import GetUrlCytricNewTab from "../../utils/GetUrlCytricNewTab";
import getPastTrips from "../CardBookTripButtons/services/getPastTrips";

interface PastTripProps {
  setPastLength: React.Dispatch<React.SetStateAction<undefined>>;
  isSidebarHidden: boolean;
  themeString: string;
}
function PastTripsComponent({
  setPastLength,
  isSidebarHidden,
  themeString,
}: PastTripProps) {
  const [loadingPastTrips, setLoadingPastTrips] = useState(true);
  const [pastTrips, setPastTrips] = useState([]);

  const [pastTripsError, setPastTripsError] = useState(false);

  const getLinkHistory = () => {
    GetUrlCytricNewTab(RedirectionTypeEnum.PAST_TRIPS);
    telemetryService.trackEvent({ name: "travel-pastTrips-historyLink" });
  };

  useEffect(() => {
    setLoadingPastTrips(true);

    getPastTrips()
      .then(async (dataPastTrips) => {
        setPastTrips(dataPastTrips);
        setPastLength(dataPastTrips.length);
      })
      .catch(() => {
        setPastTripsError(true);
      })
      .finally(() => {
        setLoadingPastTrips(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function ShowPastTripsSkeleton() {
    return <PastTripsSkeleton isSidebarHidden={isSidebarHidden} />;
  }

  const pastTripsElement = () => {
    if (pastTripsError) {
      return <ErrorPastTrip themeString={themeString} />;
    }
    if (pastTrips && pastTrips.length) {
      return (
        <PastTripsList
          trips={pastTrips}
          isSidebarHidden={isSidebarHidden}
          themeString={themeString}
          getLinkHistory={getLinkHistory}
        />
      );
    }
    return <EmptyStatePastTrips getLinkHistory={getLinkHistory} />;
  };

  return loadingPastTrips ? ShowPastTripsSkeleton() : pastTripsElement();
}

export default PastTripsComponent;
