/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/no-array-index-key */
import {
  DateFormat,
  DateUtils,
  telemetryService,
} from "@amadeus-cytric/cytric-teams-react-common-library";
import { Button, Flex, Text } from "@fluentui/react-northstar";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import RedirectionTypeEnum from "../../../../../../../../common/models/redirection/RedirectionTypeEnum";
import { complexTitleNameManager } from "../../../../../../../../helpers/TripTitleName";
import i18n from "../../../../../../../../i18n/i18n";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/StoreHooks";
import { getFlightPermissions } from "../../../../../../../../store/flows/FlowsPermissionsSelector";
import {
  getProductsByTypeV1,
  selectTripDetailsV1,
} from "../../../../../../../../store/trip-details-v1/selectors/trip-detail-v1-selectors";
import {
  getFlightDetailsGroupedByDateV1,
  getInboundDateOfFlightBoundGroupV1,
} from "../../../../../../../../store/trip-details-v1/selectors/trip-detail-v1-selectors-flight";
import CheckFeatureToggle from "../../../../../../../../utils/CheckFeatureToggle";
import { FeatureToggleDefinition } from "../../../../../../../../utils/constants";
import { SegmentType } from "../../../../../../UpcomingTrips/CardBookTripButtons/model/Permissions";
import { TRIP_PRODUCT_AIR } from "../../../../../../data/constants";
import firstLetterUppercase from "../../../../../../utils/firstLetterUppercase";
import BookingRedirections from "../../../../BookingRedirections";
import CheckFeatureEnabled from "../../../../CheckFeatureEnabled";
import Placeholder from "../../Placeholder/Placeholder";
import AriaStyle from "../../SegmentTabs.module.scss";
import HandleSegmentPermissions from "../Common/HandleSegmentPermissions";
import Accordion from "../components/Accordion/Accordion";
import { IBound } from "../components/Accordion/Accordion.model";
import NewProduct from "../components/NewProduct/NewProduct";
import Styles from "./Flight.module.scss";

function Flight({ pastTrip }: any) {
  const tripDetailV1 = useAppSelector((state) => selectTripDetailsV1(state));
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const permissions: SegmentType = useAppSelector((state) =>
    getFlightPermissions(state)
  );

  const isBlackListed = CheckFeatureToggle(
    FeatureToggleDefinition.tmsSettingsBookings.id
  );

  const bookFlight = () => {
    const name = complexTitleNameManager(t, tripDetailV1);
    const tripId = tripDetailV1.id;

    localStorage.removeItem("KeepSessionCache");
    localStorage.setItem("tripDetailTab", "");

    telemetryService.trackEvent(
      { name: "travel-booking-bookFlight" },
      {
        numOfFlights: 0,
        tripId,
      }
    );
    const redirectionInput = {
      segmentType: RedirectionTypeEnum.ADD_FLIGHT,
      tripId,
    };

    BookingRedirections(t, dispatch, history, redirectionInput, tripId, name);
  };

  const containsAir =
    tripDetailV1?.products?.filter(
      (product: any) => product.productType === "Trip_Product_Air"
    ).length > 0;

  const flightDetails = useAppSelector(getFlightDetailsGroupedByDateV1);

  const flightSegments = useAppSelector((state: any) =>
    getProductsByTypeV1(state, TRIP_PRODUCT_AIR)
  );

  if (containsAir) {
    const content = Object.keys(flightDetails).map((key: string, i: number) => {
      const endDate = getInboundDateOfFlightBoundGroupV1(flightDetails?.[key]);
      const departureLongDate = DateUtils.getDateFormat(
        key,
        i18n.language,
        DateFormat.DATE_LONG
      );
      const titleScreenReader = endDate
        ? `${t(
            "upcoming_trips_dashboard.tripcard.from"
          )} ${departureLongDate} ${t(
            "upcoming_trips_dashboard.tripcard.to"
          )} ${DateUtils.getDateFormat(
            endDate,
            i18n.language,
            DateFormat.DATE_LONG
          )} ${flightDetails?.[key]?.[0].length} ${t("tripDetails.item/s")}`
        : `${departureLongDate} ${flightDetails?.[key]?.[0].length} ${t(
            "tripDetails.item/s"
          )}`;

      const indexDateSection = `${key}-${i}-date-section`;

      return (
        <div key={indexDateSection} role="grid" aria-busy="true">
          <span className={AriaStyle.visuallyHidden}>{titleScreenReader}</span>
          <Text
            aria-hidden
            styles={(theme) => ({
              color: theme.theme.siteVariables.bookingDetail?.date,
            })}
          >
            <Text className={Styles.date} data-testid="date_trip_details">
              {firstLetterUppercase(key)}
              {endDate
                ? ` - ${firstLetterUppercase(
                    DateUtils.getDateFormat(
                      endDate,
                      i18n.language,
                      DateFormat.DATE_SHORT
                    )
                  )}`
                : null}
            </Text>
          </Text>

          {flightDetails[key].map((boundGroup: IBound[], index: number) => {
            const indexAccordion = `${key}-${index}-accordion`;
            return (
              <div role="row" key={indexAccordion} className={Styles.accordion}>
                <Accordion boundGroup={boundGroup} type="Flight" />
              </div>
            );
          })}
        </div>
      );
    });

    return (
      <>
        {content}
        {CheckFeatureEnabled() &&
          !pastTrip &&
          NewProduct({
            productType: "flight",
            numOfProducts: flightSegments.length,
          })}
      </>
    );
  }

  return (
    <Flex column hAlign="center" vAlign="center">
      <Placeholder
        text={t("upcoming_trips_dashboard.tripDetails.placeholder.flight")}
      />
      {CheckFeatureEnabled() &&
        HandleSegmentPermissions(permissions, isBlackListed) && (
          <Button
            aria-label={t(
              "upcoming_trips_dashboard.tripDetails.newProduct.buttons.flight"
            )}
            content={t(
              "upcoming_trips_dashboard.tripDetails.newProduct.buttons.flight"
            )}
            onClick={() => bookFlight()}
            flat
          />
        )}
    </Flex>
  );
}

export default Flight;
