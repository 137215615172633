import { BookingTargets, SegmentType } from "./model/Permissions";

const types = ["RAIL_BOOKING_RENFE"];

function checkPunchOuts(booking: BookingTargets) {
  return types.includes(booking.type)
    ? { ...booking, external: true }
    : booking;
}

function pushToArray(array: BookingTargets[], booking: BookingTargets) {
  if (booking) {
    array.push(checkPunchOuts(booking));
  }
  return array;
}

function rearrangRailData(
  bookingSegment: BookingTargets[],
  segment: SegmentType
) {
  segment.bookingTargets?.forEach((booking: BookingTargets) => {
    pushToArray(bookingSegment, booking);
  });
  return bookingSegment;
}

function rearrangeData(data: SegmentType[]): SegmentType[] {
  const result: SegmentType[] = [];
  data.forEach((segment: SegmentType) => {
    let bookingSegment: BookingTargets[] = [];
    if (segment.type === "RAIL") {
      bookingSegment = rearrangRailData(bookingSegment, segment);
    } else if (segment.bookingTargets) {
      bookingSegment = segment.bookingTargets;
    }
    result.push({ type: segment.type, bookingTargets: bookingSegment });
  });
  return result;
}

const flowsPermissionFixer = (originalSegments: SegmentType[]) =>
  rearrangeData(originalSegments);

export default flowsPermissionFixer;

export const isPunchOut = (type: string) => types.includes(type);
