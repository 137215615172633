import { TeamsUserCredential } from "@amadeus-cytric/cytric-teams-react-common-library";
import { Button, Flex, Image, Text } from "@fluentui/react-northstar";
import { app, dialog } from "@microsoft/teams-js";
import * as React from "react";
import { useEffect, useState } from "react";
import SubmitTaskModule from "../../../components/share-join/functions/SubmitEvents";
import i18next from "../../../i18n/i18n";
import { getDevice } from "../../../utils/device.utils";
import HostSettings from "../../../utils/host.settings";
import stylesShareFlight from "../../styles/share-flight.module.scss";

export async function increaseAttempts(
  dataToSend: any,
  accessToken: any,
  tryAgainAttempts: any,
  setTryAgainAttempts: any
) {
  const dataToSendObject = JSON.parse(dataToSend);
  dataToSendObject.accessToken = `${accessToken?.token}`;
  dataToSendObject.tryAgainAttempts = `${tryAgainAttempts + 1}`;
  setTryAgainAttempts(tryAgainAttempts + 1);
  sessionStorage.setItem("try_again_attempts", tryAgainAttempts.toString());
  SubmitTaskModule(dataToSendObject);
}

export function getParamValues(
  props: any,
  setTryAgainAttempts: any,
  setOrigin: any,
  setGraphError: any
) {
  let propValue;

  if (props.props) {
    propValue = props.props;
  } else {
    propValue = props;
  }

  const queryParams = propValue.location?.search.split("?");

  let tryAgainAttemptsParam = [];
  let originParam = [];
  let graphParam = [];

  if (queryParams?.length > 1) {
    const params = queryParams?.map((param: any) => ({
      param: param.split("=")[0],
      value: param.split("=")[1],
    }));
    tryAgainAttemptsParam = params?.filter(
      (param: any) => param.param === "tryAgainAttempts"
    );

    setTryAgainAttempts(Number(tryAgainAttemptsParam[0].value));

    originParam = params?.filter((param: any) => param.param === "origin");
    setOrigin(originParam[0].value);

    graphParam = params?.filter((param: any) => param.param === "graphError");
    if (graphParam.length > 0) {
      setGraphError(graphParam[0].value);
    }
  } else {
    setTryAgainAttempts(0);
  }
  return tryAgainAttemptsParam;
}

function TaskModuleResponseError(props: any) {
  const [themeName, setThemeName] = useState<any>();
  const [tryAgainAttempts, setTryAgainAttempts] = useState<any>(0);
  const [loadingShare, setLoadingShare] = useState(false);
  const [origin, setOrigin] = useState("");
  const [graphError, setGraphError] = useState("");

  const limitRetry = tryAgainAttempts > 2;

  const handleCancel = (): void => {
    dialog.url.submit();
  };

  const handleShare = async (): Promise<void> => {
    const credential = new TeamsUserCredential();
    const accessToken = await credential.getToken("User.Read");
    const dataToSend = localStorage.getItem("try_again_data");
    setTryAgainAttempts(Number(sessionStorage.getItem("try_again_attempts")));
    setLoadingShare(true);
    if (dataToSend !== null && dataToSend !== undefined) {
      increaseAttempts(
        dataToSend,
        accessToken,
        tryAgainAttempts,
        setTryAgainAttempts
      );
    }
  };

  useEffect(() => {
    app.initialize().then(() => {
      app.getContext().then((ctx: app.Context) => {
        setThemeName(ctx.app.theme === "default" ? "light" : "dark");
      });
    });
    setTryAgainAttempts(Number(sessionStorage.getItem("try_again_attempts")));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getParamValues(props, setTryAgainAttempts, setOrigin, setGraphError);
    setLoadingShare(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  let isMobile = false;

  const device = getDevice();
  if (device === "mobile") {
    isMobile = true;
  }

  const secondaryMessage = () => {
    if (origin === "classic-expenses-chat") {
      if (graphError) {
        return i18next.t("moduleResponse.error.graphErrorChat");
      }
      return i18next.t("moduleResponse.error.secondaryMessageChat");
    }
    return i18next.t("moduleResponse.error.secondaryMessage");
  };

  return (
    <Flex
      column
      gap="gap.smaller"
      className={[
        themeName === "light"
          ? stylesShareFlight.white
          : stylesShareFlight.dark,
        stylesShareFlight.shareContentResult,
      ].join(" ")}
    >
      <Flex column className={stylesShareFlight.result}>
        <Flex
          column
          hAlign="center"
          vAlign="center"
          className={stylesShareFlight.resultMessage}
        >
          {!limitRetry ? (
            <Image
              src={`${HostSettings.getCDNIcons}generic-error-${themeName}.svg`}
              className="generic-info__img"
              alt="info-message"
            />
          ) : (
            <Image
              src={`${HostSettings.getCDNIcons}request-error-${themeName}.svg`}
              className="generic-info__img"
              alt="info-message"
            />
          )}
          <Text
            content={
              !limitRetry
                ? i18next.t("moduleResponse.error.primaryMessage")
                : i18next.t("moduleResponse.error.primaryMessageLimitRetry")
            }
            weight="bold"
            className={stylesShareFlight.resultMessageText}
          />
          <Text
            content={
              !limitRetry
                ? secondaryMessage()
                : i18next.t("moduleResponse.error.secondaryMessageLimitRetry")
            }
            className={stylesShareFlight.resultMessageSubtext}
          />
        </Flex>

        <Flex
          hAlign="end"
          gap="gap.small"
          className={stylesShareFlight.actions}
        >
          {graphError && (
            <Button
              primary
              content={i18next.t("moduleResponse.error.closeButton")}
              onClick={() => handleCancel()}
              fluid={isMobile}
            />
          )}

          {!graphError && (
            <>
              <Button
                content={i18next.t("moduleResponse.error.cancelButton")}
                onClick={() => handleCancel()}
                fluid={isMobile}
              />
              {!limitRetry && (
                <Button
                  primary
                  disabled={loadingShare}
                  loading={loadingShare}
                  content={i18next.t("App.Try_again")}
                  onClick={() => handleShare()}
                  data-testid="tryagain"
                  fluid={isMobile}
                />
              )}
            </>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
}

export default TaskModuleResponseError;
