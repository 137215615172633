/* eslint-disable react/react-in-jsx-scope */
import { Flex, Table, Text } from "@fluentui/react-northstar";
import { useTranslation } from "react-i18next";
import PeopleAvailable from "../../../../common/components/people-available/PeopleAvailable";
import useScreenSize from "../../../../common/components/responsive/useScreenSize";
import { useDispatch, useStore } from "../../context/ShareMyRideContext";
import { types } from "../../context/ShareMyRideReducer";
import ShareMyRideButton from "../share-my-ride-button/ShareMyRideButton";
import Styles from "./SelectiveSharing.module.scss";

function SelectiveSharing({ themeName }: any) {
  const { productsToShare } = useStore();
  const tablet = 640;
  const screenSize = useScreenSize();
  const isMobile = screenSize < tablet;
  const dispatch = useDispatch();
  const { t } = useTranslation("translation", {
    keyPrefix: "shareMyRide",
  });

  const getTripInfo = (segment: any) => {
    const peopleCount =
      segment.numberOfPeopleSharing !== 0
        ? `, ${segment.numberOfPeopleSharing} ${t("peopleAvailableTrip")}`
        : ``;
    return (
      <Flex
        className={Styles.tripInfoContent}
        onClick={() => {
          dispatch({ type: types.setActiveSegment, payload: segment });
        }}
        data-testid="tripInfoContent"
        onKeyDown={(event: any) => {
          if (event.keyCode === 32 || event.keyCode === 13) {
            dispatch({ type: types.setActiveSegment, payload: segment });
          }
        }}
        role="button"
        tabIndex={0}
        aria-label={`Trip ${segment.productDetails.productTitle} at ${
          segment.formattedDate
        }${isMobile ? peopleCount : ""}`}
      >
        <div className={Styles.itinerary}>
          <Text
            content={segment.productDetails.productTitle}
            className={isMobile ? Styles.infoRowMobile : Styles.infoRow}
            styles={(theme) => ({
              color: theme.theme.siteVariables?.colorScheme?.brand.foreground2,
            })}
          />
          <Text
            color="grey"
            content={segment.formattedDate}
            className={isMobile ? Styles.infoRowDateMobile : Styles.infoRowDate}
          />
          {segment.numberOfPeopleSharing > 0 && isMobile && (
            <PeopleAvailable
              peopleList={segment.peopleSharing}
              peopleCount={segment.numberOfPeopleSharing}
              listEnabled={segment.sharingEnabled}
              themeName={themeName}
              className={Styles.tablePeopleAvailable}
            />
          )}
        </div>
      </Flex>
    );
  };

  const header = {
    key: "share-my-ride-header",
    className: Styles.tableHeader,
    items: [
      {
        content: t("transfer"),
        key: `share-my-ride-header-trip`,
        className: [Styles.headerTrip, Styles.tableSegmentNameCol].join(" "),
      },
      !isMobile && {
        content: t("peopleAvailable"),
        key: `share-my-ride-header-available`,
        className: [
          Styles.headerPeopleAvailable,
          Styles.tablePeopleAvailable,
        ].join(" "),
      },
      {
        content: t("enableButton"),
        key: `share-my-ride-header-enable`,
        className: Styles.lastCol,
      },
    ],
  };

  const rows = productsToShare.map((segment: any, index: number) => ({
    key: `${index}-row`,
    className:
      themeName === "dark" ? Styles.tableRowDark : Styles.tableRowDefault,
    items: [
      {
        content: getTripInfo(segment),
        key: `${index}-tripinfo`,
        className: Styles.tableSegmentNameCol,
      },
      !isMobile && {
        content: segment.numberOfPeopleSharing > 0 && (
          <PeopleAvailable
            peopleList={segment.peopleSharing}
            peopleCount={segment.numberOfPeopleSharing}
            listEnabled={segment.sharingEnabled}
            themeName={themeName}
          />
        ),
        key: `${index}-people-available`,
        className: Styles.tablePeopleAvailable,
      },
      {
        content: (
          <ShareMyRideButton
            date={segment.date}
            segment={segment}
            isMobile={isMobile}
            userIsSharing={segment.sharingEnabled}
            loading={segment.loading}
            origin="selection"
          />
        ),
        key: `${index}-enable-button`,
        className: isMobile ? Styles.buttonCellMobile : Styles.lastCol,
      },
    ],
  }));

  return (
    <div className={Styles.tableWrapper}>
      <Table
        header={header}
        rows={rows}
        aria-label=""
        className={Styles.table}
      />
    </div>
  );
}

export default SelectiveSharing;
