import { app } from "@microsoft/teams-js";
import HostSettings from "../../../utils/host.settings";
import SearchModel from "../models/SearchModel";

export default function SearchFlightRedirection(
  airObject: SearchModel | undefined,
  isTRIPPUser: boolean
) {
  if (airObject) {
    const dataToEncode = {
      ...airObject,
      deprecatedMMT: true,
    };
    const urlEncodedDeepLinkData: any = {
      subEntityId: JSON.stringify(dataToEncode),
    };
    const redirectionTarget = isTRIPPUser ? "cytricweb" : "travel";

    const deepLink = `https://teams.microsoft.com/l/entity/${
      HostSettings.teamsAppId
    }/${redirectionTarget}?context=${encodeURIComponent(
      JSON.stringify(urlEncodedDeepLinkData)
    )}`;
    app.openLink(deepLink);
  }
}
