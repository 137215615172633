import HostSettings from "../../utils/host.settings";
import colorSchemeDark from "./color-scheme-dark";
import colorSchemeLight from "./color-scheme-light";

const checkboxComponentStyle = {
  checkbox: {
    backgroundRepeat: "repeat",
  },
};

export const light = {
  styles: {
    backgroundColor: colorSchemeLight.default.background2,
    bodyBackground: colorSchemeLight.default.background2,
    color: colorSchemeLight.default.foreground1,
    bodyColor: colorSchemeLight.default.foreground,
    subTitle: "#292929",
  },

  theme: {
    componentStyles: {
      Checkbox: checkboxComponentStyle,
    },
    siteVariables: {
      colorScheme: colorSchemeLight,

      welcome: {
        subtitle: "#292929",
      },
      dashboardHeader: {
        title: "#242424",
      },
      tripBreadcrumb: {
        background: "#F5F5F5",
        path: "#424242",
        currentPage: "#242424",
        mobileBorderBottom: "#E0E0E0",
      },
      widgetContainer: { widget: "#FFFFFF" },
      upcomingTrips: {
        header: {
          title: "#242424",
        },
        title: "#242424",
      },
      tripCard: {
        background: "#FFFFFF",
        divider: "#E0E0E0",
        title: "#242424",
      },
      tabNavItem: { tabActive: "#4F52B2", tabInactive: "#605E5C" },
      segmentIcons: {
        icons: "#1f74e2",
        text: "#616161",
      },
      divider: {
        bookingDetail: "#E0E0E0",
        moreInfoBox: "#D1D1D1",
      },
      acordion: { arrowIcon: "#424242", arrowIconHover: "#5B5FC7" },
      bookingDetail: {
        background: "#FFFFFF",
        borderColor: "#F0F0F0",
        lighterText: "#484644",
        providerLogoBorder: "#EDEBE9",
        accordionFooterOpen: "#252423",
        accordionFooterClosed: "#000000",
        date: "#424242",
        mainInfo: "#242424",
        placeholderImage: `transparent url('${HostSettings.getCDNIcons}no-items-ballon-light.svg') no-repeat center center`,
        placeholderText: "#242424",
        onHold: "#835c00",
      },
      dialogHeader: {
        mainContent: "#242424",
      },
      toDoList: {
        helperText: "#616161",
        heading: "#242424",
        background: "#F5F5F5",
        backgroundInnerBox: "#FFFFFF",
        borderColor: "#F0F0F0",
        checkboxLabel: "#242424",
        checkboxLabelDone: "#616161",
        date: "#616161",
        dateOver: "#C4314B",
        dateDone: "#616161",
        button: "#242424",
        moreIcon: "#424242",
        image: `transparent url('${HostSettings.getCDNIcons}to-do-list-light.svg') no-repeat bottom left`,
        imageMobile: `transparent url('${HostSettings.getCDNIcons}to-do-list-light-mobile.svg') no-repeat center center`,
        dialog: {
          background: "#FFFFFF",
          closeIcon: "#242424",
          label: "#616161",
          borderColor: "#E0E0E0",
          greyBackground: "#f5f5f5",
        },
      },
      coachmarks: {
        background: "#5B5FC7",
        text: "#FFFFFF",
      },
      simplifiedBookingDetails: {
        title: "#242424",
        subtitle: "#424242",
        imagePlaceholder: `transparent url('${HostSettings.getCDNIcons}no-items-desert-light.svg') no-repeat center center`,
      },
      extendedDetails: {
        circleColor: "#4F52B2",
        duration: "#616161",
        destinationBorder: "#E9EAF6",
        stop: {
          background: "#E8EBFA",
          borderColor: "#9299F7",
          color: "#4F52B2",
          backgroundAlert: "#FBF6D9",
          borderAlert: "#F2E384",
          colorAlert: "#835C00",
        },
        tripData: "#616161",
        serviceClass: "#237B4B",
        services: "#616161",
        moreInfoButton: "#4F52B2",
        moreInfoBox: {
          background: "#F5F5F5",
        },
      },
      hotelDetails: {
        imageBorder: "#EDEBE9",
        textColor: "#242424",
        addressColor: "#616161",
        contactColor: "#4F52B2",
        contactInfoText: "#424242",
        cardColor: "#F5F5F5",
      },
      carDetails: {
        textColor: "#616161",
        iconColor: "#424242",
        imageBorder: "#EDEBE9",
        headingColor: "#242424",
        background: "#FFFFFF",
      },
      status: {
        "to-be-approved": {
          background: "#e0e0e0",
          borderColor: "#c7c7c7",
          color: "#292929",
        },
        preapproved: {
          background: "#e5e5f1",
          borderColor: "#bdbde6",
          color: "#585a96",
        },
        approved: {
          background: "#e7f8d1",
          borderColor: "#237b4b",
          color: "#237b4b",
        },
        rejected: {
          background: "#fcf4f6",
          borderColor: "#f3d6d8",
          color: "#c4314b",
        },
        "rejected-not-canceled": {
          background: "#fcf4f6",
          borderColor: "#f3d6d8",
          color: "#c4314b",
        },
        "rejected-not-cancelled": {
          background: "#fcf4f6",
          borderColor: "#f3d6d8",
          color: "#c4314b",
        },
        unknown: {
          background: "#F5F5F5",
          borderColor: "#D1D1D1",
          color: "#616161",
        },
      },
      statusPill: {
        grey: {
          background: colorSchemeLight.default.background5,
          borderColor: colorSchemeLight.default.border,
          color: colorSchemeLight.default.foreground,
        },
        blue: {
          background: colorSchemeLight.brand.background1,
          borderColor: colorSchemeLight.brand.border2,
          color: colorSchemeLight.brand.foreground2,
        },
        green: {
          background: colorSchemeLight.green.background2,
          borderColor: colorSchemeLight.green.border,
          color: colorSchemeLight.green.foreground,
        },
        red: {
          background: colorSchemeLight.pink.background,
          borderColor: colorSchemeLight.red.border,
          color: colorSchemeLight.red.foreground,
        },
        unknown: {
          background: colorSchemeLight.default.background2,
          borderColor: colorSchemeLight.default.border,
          color: colorSchemeLight.default.foreground2,
        },
      },
      formLabel: {
        background: "transparent",
      },
      formDropdown: {
        background: "#FFFFFF",
      },
      tagSelected: {
        background: "#E5EFFC",
        color: "#1F74E2",
      },
      tag: {
        background: "#EBEBEB",
      },
      tagIconSelected: {
        color: "#1F74E2",
      },
      tagIcon: {
        color: "#616161",
      },
      co2Container: {
        background: "#F5F5F5",
      },
      editSearchContainer: {
        background: "#FFFFFF",
      },
      frequentFlyer: {
        background: "#E2D5E8",
      },
      preferredLevel: {
        background: "rgba(165, 199, 243, 0.8)",
      },
      shareMyRide: {
        image: {
          backgroundImage: `url(share-my-ride/sharemyride-2.0.svg)`,
        },
      },
      expenseDashboard: {
        todoList: {
          button: colorSchemeLight.brand.foreground,
          label: {
            text: colorSchemeLight.default.foreground2,
            background: colorSchemeLight.brand.background1,
          },
        },
        popUps: {
          folderImage: `transparent url(${HostSettings.getCDNIcons}folder-brand-light.svg) no-repeat center center`,
        },
        summary: {
          icon: colorSchemeLight.default.foreground2,
        },
        chart: {
          periodButton: colorSchemeLight.brand.foreground,
        },
      },
      settings: {
        divider: "#E0E0E0",
        mobileButtonColor: "#5B5FC7",
        desktopButtonColor: "#242424",
      },
    },
  },
};
export const dark = {
  styles: {
    backgroundColor: colorSchemeDark.default.background2,
    bodyBackground: colorSchemeDark.default.background2,
    color: colorSchemeDark.default.foreground1,
    bodyColor: colorSchemeDark.default.foreground,
    subTitle: "#D6D6D6",
  },
  theme: {
    componentStyles: {
      Provider: {
        root: {
          backgroundColor: colorSchemeDark.default.background2,
        },
      },
      Checkbox: checkboxComponentStyle,
    },
    siteVariables: {
      colorScheme: colorSchemeDark,
      welcome: {
        subtitle: "#D6D6D6",
      },
      dashboardHeader: {
        title: "#FFFFFF",
      },
      tripBreadcrumb: {
        background: "#292929",
        path: "##D6D6D6",
        currentPage: "#FFFFFF",
        mobileBorderBottom: "#0F0F0F",
      },
      widgetContainer: { widget: "#292929" },
      upcomingTrips: {
        header: {
          title: "#FFFFFF",
        },
        title: "#FFFFFF",
      },
      tripCard: {
        background: "#292929",
        divider: "#0F0F0F",
        title: "#FFFFFF",
      },
      tabNavItem: { tabActive: "#7F85F5", tabInactive: "#EBEBEB" },
      segmentIcons: {
        icons: "#A5C7F3",
        text: "#ADADAD",
      },
      divider: {
        bookingDetail: "#0F0F0F",
        moreInfoBox: "#616161",
      },
      acordion: { arrowIcon: "#D6D6D6", arrowIconHover: "#7F85F5" },
      bookingDetail: {
        background: "#292929",
        borderColor: "#0F0F0F",
        lighterText: "#ADADAD",
        providerLogoBorder: "#292929",
        accordionFooterOpen: "#FFFFFF",
        accordionFooterClosed: "#FFFFFF",
        date: "#D6D6D6",
        mainInfo: "#FFFFFF",
        placeholderImage: `transparent url('${HostSettings.getCDNIcons}no-items-ballon-dark.svg') no-repeat center center`,
        placeholderText: "#FFFFFF",
        onHold: "#F2E384",
      },
      dialogHeader: {
        mainContent: "#FFFFFF",
      },
      toDoList: {
        helperText: "#FFFFFF",
        heading: "#FFFFFF",
        background: "#1F1F1F",
        backgroundInnerBox: "#292929",
        borderColor: "#3D3D3D",
        checkboxLabel: "#FFFFFF",
        checkboxLabelDone: "#8c8c8c",
        date: "#ADADAD",
        dateOver: "#F9526B",
        dateDone: "#8c8c8c",
        button: "#FFFFFF",
        moreIcon: "#D6D6D6",
        image: `transparent url('${HostSettings.getCDNIcons}to-do-list-dark.svg') no-repeat bottom left`,
        imageMobile: `transparent url('${HostSettings.getCDNIcons}to-do-list-dark-mobile.svg') no-repeat center center`,
        dialog: {
          background: "#292929",
          closeIcon: "#FFFFFF",
          label: "#ADADAD",
          borderColor: "#0F0F0F",
          greyBackground: "#1F1F1F",
        },
      },
      coachmarks: {
        background: "#5B5FC7",
        text: "#FFFFFF",
      },
      simplifiedBookingDetails: {
        title: "#FFFFFF",
        subtitle: "#D6D6D6",
        imagePlaceholder: `transparent url('${HostSettings.getCDNIcons}no-items-desert-dark.svg') no-repeat center center`,
      },
      extendedDetails: {
        circleColor: "#7F85F5",
        duration: "#ADADAD",
        destinationBorder: "#0F0F0F",
        stop: {
          background: "#0A0A0A",
          borderColor: "#444791",
          color: "#7F85F5",
          backgroundAlert: "#463100",
          borderAlert: "#032003",
          colorAlert: "#F2E384",
        },
        tripData: "#ADADAD",
        serviceClass: "#92C353",
        services: "#ADADAD",
        moreInfoButton: "#7F85F5",
        moreInfoBox: {
          background: "#1F1F1F",
        },
      },
      hotelDetails: {
        imageBorder: "#292929",
        textColor: "#FFFFFF",
        addressColor: "#ADADAD",
        contactColor: "#7F85F5",
        contactInfoText: "#D6D6D6",
        cardColor: "#1F1F1F",
      },
      carDetails: {
        textColor: "#ADADAD",
        iconColor: "#D6D6D6",
        imageBorder: "#292929",
        headingColor: "#FFFFFF",
        background: "#FFFFFF",
      },
      status: {
        "to-be-approved": {
          background: "#3D3D3D",
          borderColor: "#616161",
          color: "#FFFFFF",
        },
        preapproved: {
          background: "#323348",
          borderColor: "#464775",
          color: "#C7C9FF",
        },
        approved: {
          background: "#0D2E0D",
          borderColor: "#032003",
          color: "#92C353",
        },
        rejected: {
          background: "#3E1F25",
          borderColor: "#1E040A",
          color: "#F9526B",
        },
        "rejected-not-canceled": {
          background: "#3E1F25",
          borderColor: "#1E040A",
          color: "#F9526B",
        },
        "rejected-not-cancelled": {
          background: "#3E1F25",
          borderColor: "#1E040A",
          color: "#F9526B",
        },
        unknown: {
          background: "#1F1F1F",
          borderColor: "#616161",
          color: "#ADADAD",
        },
      },
      statusPill: {
        grey: {
          background: colorSchemeDark.default.background5,
          borderColor: colorSchemeDark.default.border,
          color: colorSchemeDark.default.foreground,
        },
        blue: {
          background: colorSchemeDark.brand.background1,
          borderColor: colorSchemeDark.brand.border2,
          color: colorSchemeDark.brand.foreground3,
        },
        green: {
          background: colorSchemeDark.green.background2,
          borderColor: colorSchemeDark.yellow.border,
          color: colorSchemeDark.green.foreground,
        },
        red: {
          background: colorSchemeDark.pinkDark.background,
          borderColor: colorSchemeDark.red.border,
          color: colorSchemeDark.red.foreground,
        },
        unknown: {
          background: colorSchemeDark.default.background2,
          borderColor: colorSchemeDark.default.border,
          color: colorSchemeDark.default.foreground2,
        },
      },
      formLabel: {
        background: "transparent",
        color: "white",
      },
      formDropdown: {
        background: "#0A0A0A",
        color: "#FFFFFF",
      },
      tagSelected: {
        background: "#1F74E2",
      },
      tag: {
        background: "#0A0A0A",
        color: "#616161",
      },
      tagIconSelected: {
        color: "#E8F1FC",
      },
      tagIcon: {
        color: "#ADADAD",
      },
      co2Container: {
        background: "#1F1F1F",
      },
      editSearchContainer: {
        background: "#282829",
      },
      frequentFlyer: {
        background: "#E2D5E8",
      },
      preferredLevel: {
        background: "#A5C7F3",
      },
      expenseDashboard: {
        todoList: {
          button: colorSchemeLight.brand.foreground3,
          label: {
            text: colorSchemeLight.silver.foreground,
            background: "#444791",
          },
        },
        popUps: {
          folderImage: `transparent url(${HostSettings.getCDNIcons}folder-brand-dark.svg) no-repeat center center`,
        },
        summary: {
          icon: "#8a8a8a",
        },
        chart: {
          periodButton: colorSchemeDark.brand.foreground1,
        },
      },
      settings: {
        divider: "#616161",
        mobileButtonColor: "#FFFFFF",
        desktopButtonColor: "#FFFFFF",
      },
    },
  },
};
