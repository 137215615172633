import {
  createMicrosoftGraphClient,
  DateFormat,
  DateUtils,
  GraphService,
  TeamsUserCredential,
  telemetryService,
} from "@amadeus-cytric/cytric-teams-react-common-library";
import { createAsyncThunk } from "@reduxjs/toolkit";
import * as ACData from "adaptivecards-templating";
import i18next from "i18next";
import TripDestination from "../../../../common/models/TripDestination";
import { CollaboratorsModel } from "../../../../components/collaborators/close-collaborators/models/CloseCollaboratorsModel";
import CloseCollaboratorService from "../../../../services/close-collaborators/close-collaborators.service";
import HostSettings from "../../../../utils/host.settings";
import { setNotificationSentConfirmation } from "../CloseCollaboratorsSlice";
import { searchCloseCollaborators } from "../CloseCollabroratorsAction";
import closeCollaboratorNotificationCard from "./card-template/closeCollaboratorNotificationCard";

export const CLOSE_COLLABORATORS_NOTIFCATION_STORE_NAME = "closeCollaborators";
export const NOTIFY_CLOSE_COLLABORATORS = "notifyCloseCollaborators";

export const createCards = async (
  graphClient: any,
  senderData: any,
  data: {
    checkedCollaboratorIds: string[];
    collaborators: CollaboratorsModel[];
    tripDestination: TripDestination;
    customMessage?: string;
  }
) => {
  const images = await GraphService.getGraphUsersPhotos(graphClient, [
    senderData.id,
  ]);

  const userProfilePicture = images[0].body;

  const cards = data.checkedCollaboratorIds.map((id) => {
    const adaptiveCardTemplate = new ACData.Template(
      closeCollaboratorNotificationCard
    );

    const collaboratorData = data.collaborators.find(
      (collab) => collab.id === id
    );

    const deeplink = `https://teams.microsoft.com/l/entity/${
      HostSettings.teamsAppId
    }/travel?context=${encodeURIComponent(
      JSON.stringify({ subEntityId: "openSidebar" })
    )}`;

    const startDate = DateUtils.getDateFormat(
      data.tripDestination.startDate!,
      i18next.language,
      DateFormat.DATE_LONG
    );
    const endDate = data.tripDestination.endDate
      ? DateUtils.getDateFormat(
          data.tripDestination.endDate,
          i18next.language,
          DateFormat.DATE_LONG
        )
      : null;

    const senderText = i18next.t(
      "reverseCollaborators.notification.senderText",
      {
        senderUserName: `${senderData.displayName}`,
      }
    );
    const receiverTextPart1 = i18next.t(
      "reverseCollaborators.notification.receiverText1",
      {
        receiverUserName: `${
          collaboratorData ? collaboratorData.displayName : ""
        }`,
      }
    );
    const receiverTextPart2 = i18next.t(
      "reverseCollaborators.notification.receiverText2",
      {
        cityToVisit: `${data.tripDestination.city}`,
      }
    );
    const receiverTextPart3 = i18next.t(
      "reverseCollaborators.notification.receiverText3",
      {
        startDate: `${startDate}`,
        endDate: `${endDate}`,
      }
    );
    const receiverTextPart4 = i18next.t(
      "reverseCollaborators.notification.receiverText4"
    );

    const buttonText = i18next.t(
      "reverseCollaborators.notification.buttonText"
    );

    const receiverText =
      data.customMessage ||
      `${receiverTextPart1}\n\n${receiverTextPart2}${
        endDate ? receiverTextPart3 : "."
      }\n\n${receiverTextPart4}`;

    const adaptiveContext = {
      $root: {
        senderUser: {
          name: senderData.displayName,
          profilePicture: userProfilePicture,
          email: senderData.userPrincipalName,
          deeplink,
          text: senderText,
        },
        receiverUser: {
          name: collaboratorData ? collaboratorData.displayName : "",
          text: receiverText,
        },
        trip: {
          cityToVisit: data.tripDestination.city,
          startDate,
          endDate,
          buttonText,
        },
      },
    };
    const cardPayload = adaptiveCardTemplate.expand(adaptiveContext);

    return {
      recipient: {
        id,
        userPrincipalName: collaboratorData?.userPrincipalName || "",
      },
      payload: JSON.stringify(cardPayload),
    };
  });

  return {
    senderUserPrincipalName: senderData.userPrincipalName,
    senderId: senderData.id,
    cards,
  };
};

export const notifyCloseCollaborators = createAsyncThunk(
  `${CLOSE_COLLABORATORS_NOTIFCATION_STORE_NAME}/${NOTIFY_CLOSE_COLLABORATORS}`,
  async (
    data: {
      checkedCollaboratorIds: string[];
      collaborators: CollaboratorsModel[];
      tripDestination: TripDestination;
      tripId: string;
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      telemetryService.trackEvent(
        { name: "travel-closeCollaborators-notify" },
        {
          numOfItems: data.checkedCollaboratorIds.length,
          tripId: data.tripId,
        }
      );

      const credential = new TeamsUserCredential();
      const graphClient = createMicrosoftGraphClient(credential, "User.Read");
      const senderData = await GraphService.getMe(graphClient);

      const cardData = await createCards(graphClient, senderData, data);

      CloseCollaboratorService.notifyCloseCollaborators(
        cardData.senderId,
        cardData.senderUserPrincipalName,
        cardData.cards
      )
        .then(() => {
          CloseCollaboratorService.addReverseCollaborators(
            {
              id: senderData.id,
              userPrincipalName: senderData.userPrincipalName,
              displayName: senderData.displayName,
              jobTitle: senderData.jobTitle,
            },
            data.checkedCollaboratorIds.map(
              (id) => data.collaborators.find((collab) => collab.id === id)!
            ),
            {
              tripId: data.tripId,
              startDate: data.tripDestination.startDate,
              endDate: data.tripDestination.endDate,
              cityLocation: data.tripDestination.city,
            }
          );
        })
        .then(() => {
          const fetchTimoutId = setTimeout(() => {
            dispatch(
              searchCloseCollaborators({
                countryCode: data.tripDestination.countryCode || "",
                latitude: data.tripDestination.latitude,
                longitude: data.tripDestination.longitude,
                iataCode: data.tripDestination.iataCode,
                tripId: data.tripId,
              })
            );
            dispatch(setNotificationSentConfirmation({ isVisible: false }));
          }, 10 * 1000);

          dispatch(
            setNotificationSentConfirmation({
              isVisible: true,
              fetchTimoutId,
            })
          );
        })
        .catch(() => {
          dispatch(
            setNotificationSentConfirmation({ isVisible: true, isError: true })
          );
          setTimeout(() => {
            dispatch(setNotificationSentConfirmation({ isVisible: false }));
          }, 10 * 1000);
        });

      return true;
    } catch (error: any) {
      return rejectWithValue(false);
    }
  }
);
