import moment from "moment";

export default class DateHelper {
  public static formatDateTimeToDateRange(
    startDate: string,
    endDate: string
  ): string {
    const dateFormat = "ddd, DD MMMM YYYY";
    const formattedStartDate = startDate
      ? moment.utc(startDate).format(dateFormat)
      : null;
    const formattedEndDate = endDate
      ? moment.utc(endDate).format(dateFormat)
      : null;

    if (
      formattedStartDate &&
      formattedEndDate &&
      formattedStartDate !== formattedEndDate
    ) {
      return `${formattedStartDate} - ${formattedEndDate}`;
    }
    if (formattedStartDate) {
      return formattedStartDate;
    }
    if (formattedEndDate) {
      return formattedEndDate;
    }
    return "";
  }

  public static formatDateTimeToRedirectionDateTime(dateTime: string): string {
    return dateTime.split(/[zZ\\+.\s]/)[0]; // .format("YYYY-MM-DD HH:mm");
  }
}

export function diffMinutes(dt2: Date, dt1: Date) {
  let diff = (dt2.getTime() - dt1.getTime()) / 1000;
  diff /= 60;
  return Math.abs(Math.round(diff));
}

export function getDateFromDateTime(dateTime: string): string {
  return moment.utc(dateTime).format("YYYY-MM-DD");
}
