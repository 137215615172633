import {
  statusIcon,
  statusIconClass,
} from "@amadeus-cytric/cytric-teams-react-common-library";
import stylesUserStatus from "@amadeus-cytric/cytric-teams-react-common-library/dist/components/user-status/UserStatus.module.scss";
import {
  AirplaneRegular,
  BedRegular,
  VehicleCarRegular,
  VehicleSubwayRegular,
} from "@fluentui/react-icons";
import { Avatar, Flex, Text, Tooltip } from "@fluentui/react-northstar";
import { Dispatch, SetStateAction } from "react";
import { getStatusTitle } from "../share-my-ride/utils/shareMyRideUtils";
import Styles from "./EventAttendees.module.scss";

export const dontClose = (e: any) => {
  if (e.keyCode === 13) {
    e.preventDefault();
  }
};

export const getUser = (user: any, index: any, t: any, currentUser: any) => {
  const { image, status } = user.props.dataContacts;
  let { fullName } = user.props.dataContacts;
  const { id } = user.props.dataContacts;
  if (id === currentUser.id) {
    fullName += ` ${t("events.youTag")}`;
  }
  return (
    <Flex gap="gap.small" vAlign="center">
      <Flex.Item data-testid={`Attendee-row-${index}`}>
        <Avatar
          name={fullName}
          image={image}
          status={
            user.status !== null && {
              styles: (theme) => ({
                backgroundColor:
                  theme.theme.siteVariables.widgetContainer?.widget,
                color:
                  theme.theme.siteVariables?.colorScheme?.default.background,
              }),
              icon: statusIcon(status.availability),
              title: getStatusTitle(status.activity),
              className: `${stylesUserStatus.statusIndicator} ${
                statusIconClass(status.availability).icon.class
              }`,
            }
          }
          aria-hidden
        />
      </Flex.Item>
      <Flex.Item>
        <Flex column>
          <Text
            content={fullName}
            title={fullName}
            styles={(theme) => ({
              color: theme.theme.siteVariables.colorScheme?.default.foreground,
            })}
          />
        </Flex>
      </Flex.Item>
    </Flex>
  );
};

export const getTravelIcon = (
  type: string,
  hasShared: boolean,
  IconComponent: any,
  testId: string,
  ICON_STYLE: any,
  ICON_STYLE_DISABLED: any,
  t: any
) => (
  <Flex
    styles={hasShared ? ICON_STYLE : ICON_STYLE_DISABLED}
    className={Styles.iconMedium}
    aria-label={t(`eventManagerAgenda.attendeeView.${hasShared}${type}Shared`)}
    data-testid={testId}
    role="img"
  >
    <Tooltip
      content={t(`eventManagerAgenda.attendeeView.${hasShared}${type}Shared`)}
      data-testid={`icontooltip-${type}`}
      trigger={<IconComponent />}
      pointing
      position="below"
      subtle={false}
    />
  </Flex>
);

export const getFlightIcon = (
  hasFlight: boolean,
  ICON_STYLE: any,
  ICON_STYLE_DISABLED: any,
  t: any
) =>
  getTravelIcon(
    "Flight",
    hasFlight,
    AirplaneRegular,
    "travel-icon-flight",
    ICON_STYLE,
    ICON_STYLE_DISABLED,
    t
  );

export const getRailIcon = (
  hasRail: boolean,
  ICON_STYLE: any,
  ICON_STYLE_DISABLED: any,
  t: any
) =>
  getTravelIcon(
    "Rail",
    hasRail,
    VehicleSubwayRegular,
    "travel-icon-rail",
    ICON_STYLE,
    ICON_STYLE_DISABLED,
    t
  );

export const getHotelIcon = (
  hasHotel: boolean,
  ICON_STYLE: any,
  ICON_STYLE_DISABLED: any,
  t: any
) =>
  getTravelIcon(
    "Hotel",
    hasHotel,
    BedRegular,
    "travel-icon-hotel",
    ICON_STYLE,
    ICON_STYLE_DISABLED,
    t
  );

export const getCarIcon = (
  hasCar: boolean,
  ICON_STYLE: any,
  ICON_STYLE_DISABLED: any,
  t: any
) =>
  getTravelIcon(
    "CarTransfer",
    hasCar,
    VehicleCarRegular,
    "travel-icon-car",
    ICON_STYLE,
    ICON_STYLE_DISABLED,
    t
  );

export const getRows = (
  attendees: any,
  ICON_STYLE: any,
  ICON_STYLE_DISABLED: any,
  t: any,
  currentUser: any
) => {
  if (!attendees) {
    return [];
  }
  attendees.sort((a: any, b: any) => {
    const nameA = a.props.dataContacts.displayName.toLowerCase();
    const nameB = b.props.dataContacts.displayName.toLowerCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });

  const youIndex = attendees.findIndex(
    (attendee: any) => attendee.props.dataContacts.id === currentUser.id
  );
  if (youIndex !== -1) {
    const [youAttendee] = attendees.splice(youIndex, 1);
    attendees.unshift(youAttendee);
  }
  return attendees.map((owner: any, index: any) => ({
    key: `${index}-row`,
    role: "none",
    items: [
      {
        content: getUser(owner, index, t, currentUser),
        key: `${index}-userName`,
        className: Styles.firstCol,
      },
      {
        content: (
          <Text
            content={
              owner.props.dataContacts.dates !== ""
                ? owner.props.dataContacts.dates
                : t("events.eventSharetrip.tripSummary.tripContacts.noData")
            }
            title={owner.props.dataContacts.dates}
            styles={(theme) => ({
              color: theme.theme.siteVariables.colorScheme?.default.foreground,
            })}
            aria-label={owner.props.dataContacts.dates}
          />
        ),
        key: `${index}-tripDates`,
        className: Styles.secondCol,
      },
      {
        content: (
          <Flex
            data-testid="shared-bookings-icons"
            styles={{
              justifyContent: "space-between",

              width: "100%",
            }}
          >
            {getFlightIcon(
              owner.props.dataContacts.hasSharedFlight,
              ICON_STYLE,
              ICON_STYLE_DISABLED,
              t
            )}
            {getRailIcon(
              owner.props.dataContacts.hasSharedRail,
              ICON_STYLE,
              ICON_STYLE_DISABLED,
              t
            )}
            {getHotelIcon(
              owner.props.dataContacts.hasSharedHotel,
              ICON_STYLE,
              ICON_STYLE_DISABLED,
              t
            )}
            {getCarIcon(
              owner.props.dataContacts.hasSharedCar,
              ICON_STYLE,
              ICON_STYLE_DISABLED,
              t
            )}
          </Flex>
        ),
        key: `${index}-bookings`,
        className: Styles.thirdCol,
      },
    ],
    className: Styles.tableColumns,
  }));
};

export const getFilteredSearch = (items: any[], query: string) => {
  if (!items) {
    return [];
  }

  return items.filter(
    (item) =>
      item.props.dataContacts?.fullName &&
      typeof query === "string" &&
      item.props.dataContacts.fullName
        .toLowerCase()
        .includes(query.toLowerCase())
  );
};

export const getDropdownItems = (items: any[], query: string) =>
  getFilteredSearch(items, query)?.map((item) => ({
    key: item.props.dataContacts?.id,
    header: item.props.dataContacts?.fullName,
    image: { src: item.props.dataContacts?.image, avatar: true },
  }));

export const handleClear = (
  setQuery: Dispatch<SetStateAction<string>>,
  setUpdatedAttendeesObject: Dispatch<SetStateAction<any>>,
  initialAttendeesObject: React.MutableRefObject<any>
) => {
  setQuery("");
  setUpdatedAttendeesObject(initialAttendeesObject.current);
};

export const handleOnChange = (
  _e: any,
  param: any,
  setQuery: Dispatch<SetStateAction<string>>,
  setUpdatedAttendeesObject: Dispatch<SetStateAction<any>>,
  initialAttendeesObject: React.MutableRefObject<any>
): void => {
  if (_e === null && param.value) {
    setQuery(param.value?.header || "");
    setUpdatedAttendeesObject(() =>
      getFilteredSearch(initialAttendeesObject.current, param.value?.header)
    );
  } else {
    setQuery("");
    setUpdatedAttendeesObject(initialAttendeesObject.current);
  }
};

export const handleSearchQueryChange = (
  _e: any,
  { searchQuery }: any,
  setQuery: Dispatch<SetStateAction<string>>,
  handleClear2: () => void
) => {
  setQuery(searchQuery.trim() || "");
  if (searchQuery === "") {
    handleClear2();
  }
};
