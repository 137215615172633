import { telemetryService } from "@amadeus-cytric/cytric-teams-react-common-library";
import { Button, Flex } from "@fluentui/react-northstar";
import { dialog } from "@microsoft/teams-js";
import { useTranslation } from "react-i18next";
import TripApprovalFooterStyles from "./TripApprovalFooter.module.scss";

function TripApprovalFooter({
  action,
  isConfirmed,
  noComments,
  isDisable,
  isGeneralError,
  handleClick,
  themeStyle,
}: any) {
  const { t } = useTranslation("translation", {
    keyPrefix: "tripApproval.button",
  });

  const getTitle = () => {
    if (isGeneralError) {
      return t("tryAgain");
    }
    switch (action) {
      case "approve":
        return t("confirmApproval");
      case "reject":
        return t("confirmRejection");
      default:
        return t("close");
    }
  };

  const handleCancel = (): void => {
    telemetryService.trackEvent(
      { name: "travel-tripApproval-approveRejectActionCancel" },
      {
        action: "cancel",
      }
    );
    dialog.url.submit();
  };

  return (
    <Flex
      className={[
        noComments
          ? TripApprovalFooterStyles.noCommentsFooter
          : TripApprovalFooterStyles.withCommentsFooter,
        TripApprovalFooterStyles.footer,
      ].join(" ")}
      styles={(theme) => ({
        backgroundColor:
          theme.theme.siteVariables?.colorScheme?.default.background,
      })}
    >
      <Flex className={TripApprovalFooterStyles.buttons}>
        {(!isConfirmed && (action === "approve" || action === "reject")) ||
        isGeneralError ? (
          <>
            <Button
              className={TripApprovalFooterStyles.cancel}
              data-testid="trip-approval-cancel-button"
              content={t("cancel")}
              aria-label={t("cancel")}
              size="medium"
              onClick={() => {
                handleCancel();
              }}
            />
            <Button
              className={[themeStyle, TripApprovalFooterStyles.continue].join(
                " "
              )}
              data-testid="trip-approval-continue-button"
              content={getTitle()}
              aria-label={getTitle()}
              size="medium"
              primary
              onClick={handleClick}
              disabled={isDisable}
            />
          </>
        ) : (
          <Button
            className={[themeStyle, TripApprovalFooterStyles.continue].join(
              " "
            )}
            data-testid="approval-response-close-button"
            content={t("close")}
            size="medium"
            primary
            onClick={() => {
              handleCancel();
            }}
            aria-label={t("close")}
          />
        )}
      </Flex>
    </Flex>
  );
}

export default TripApprovalFooter;
