/* eslint-disable no-debugger */
import { useEffect, useState } from "react";
import { CP_FLOW_IDENTIFIER } from "../../../../../utils/constants";
import { ShareJoinFooterModel } from "../../../models/ShareJoinFooterModel";
import EventsButtons from "./components/events-buttons/EventsButtons";
import ShareButtons from "./components/share-buttons/ShareButtons";

function determineShareButtonToggle(
  segmentsLength: number | undefined,
  sharingFromDashboard: boolean,
  origin: string,
  shareWithSelected: boolean,
  setDisableShare: any
) {
  if (segmentsLength && segmentsLength > 0) {
    if (
      (sharingFromDashboard || origin === CP_FLOW_IDENTIFIER) &&
      shareWithSelected
    ) {
      setDisableShare(false);
    } else if (!sharingFromDashboard && origin !== CP_FLOW_IDENTIFIER) {
      // we want to share without recipients in messaging extension
      setDisableShare(false);
    } else {
      setDisableShare(true);
    }
  } else {
    setDisableShare(true);
  }
}

function ShareJoinFooter({
  handleClick,
  closeTask,
  themeName,
  disableShare,
  setDisableShare,
  shareWithSelected,
  sharingFromDashboard,
  loadingShare,
  device,
  classTabletDevice,
  segments,
  origin,
  postOnChannel,
  setPostOnChannel,
  context,
}: ShareJoinFooterModel) {
  const [segmentsLength, setSegmentsLength] = useState<number | undefined>(
    segments?.length
  );

  const setButtonDisabled = () => {
    determineShareButtonToggle(
      segmentsLength,
      sharingFromDashboard,
      origin,
      shareWithSelected,
      setDisableShare
    );
  };

  const getSegmentsLength = () => {
    if (segments) {
      setSegmentsLength(segments.length);
    } else {
      setSegmentsLength(0);
    }
  };

  useEffect(() => {
    getSegmentsLength();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segments]);

  useEffect(() => {
    setButtonDisabled();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segmentsLength, shareWithSelected]);

  return origin === "events" ? (
    <EventsButtons
      handleClick={handleClick}
      closeTask={closeTask}
      themeName={themeName}
      disableShare={disableShare}
      loadingShare={loadingShare}
      segmentsLength={segmentsLength}
      device={device}
      classTabletDevice={classTabletDevice}
      postOnChannel={postOnChannel}
      setPostOnChannel={setPostOnChannel}
      context={context}
    />
  ) : (
    <ShareButtons
      handleClick={handleClick}
      closeTask={closeTask}
      themeName={themeName}
      disableShare={disableShare}
      loadingShare={loadingShare}
      segmentsLength={segmentsLength}
      device={device}
      classTabletDevice={classTabletDevice}
    />
  );
}

export default ShareJoinFooter;
