import { Text } from "@fluentui/react-northstar";
import { useEffect } from "react";
import ProductHotel from "../../../../../common/models/ProductHotel";
import { getDateFromDateTime } from "../../../../../helpers/DateHelper";
import TripHelperV1, {
  FormattedDate,
} from "../../../../../helpers/TripHelperV1";
import {
  IAddress,
  IProductV1,
} from "../../../../upcoming-trips-dashboard/UpcomingTripDetail/UpcomingTripDetailV1.model";
import { useDispatch } from "../../../context/ShareJoinContext";
import { types } from "../../../context/ShareJoinReducer";
import SegmentWrapper from "../segments/SegmentWrapper";

interface HotelSegmentContentProps {
  hotelSegment: IProductV1;
  topDate: FormattedDate | null;
  id: number;
}

function getCheckInDate(checkInDate: string | undefined): string {
  return checkInDate ?? "";
}

function getCheckOutDate(checkOutDate: string | undefined): string {
  return checkOutDate ?? "";
}

function getName(name: string | undefined): string {
  return name ?? "";
}

function getCityName(address: IAddress | undefined): string {
  return address?.cityName ?? "";
}

function getCountryCode(address: IAddress | undefined): string {
  return address?.cityName ?? "";
}

function getLines(address: IAddress | undefined): string[] {
  return address?.lines ?? [];
}

function getPostalCode(address: IAddress | undefined): string {
  return address?.cityName ?? "";
}

function getLatitude(latitude: number | undefined): number {
  return latitude ?? 0;
}

function getLongitude(longitude: number | undefined): number {
  return longitude ?? 0;
}

function HotelSegmentContentV1(props: HotelSegmentContentProps) {
  const dispatch = useDispatch();
  const { hotelSegment, topDate, id } = props;
  const {
    address,
    name,
    checkInDate,
    checkOutDate,
    breakfast,
    latitude,
    longitude,
  } = hotelSegment;
  let range;
  if (topDate) {
    // eslint-disable-next-line prefer-destructuring
    range = topDate.range;
  }
  function formatAddress(): string {
    if (address) {
      return [
        address.lines[0],
        address.postalCode,
        address.cityName,
        address.countryCode,
      ]
        .filter((value) => value && value !== "")
        .join(", ");
    }
    return "";
  }

  const header = <Text weight="bold"> {name} </Text>;

  const content = <Text>{formatAddress()}</Text>;

  const getHotelProductId = (): string =>
    `${getDateFromDateTime(getCheckInDate(checkInDate))}#${getDateFromDateTime(
      getCheckOutDate(checkOutDate)
    )}#${getName(name)}`;

  const productToShare: ProductHotel = {
    productType: "hotel",
    checkInDate: getCheckInDate(checkInDate),
    checkOutDate: getCheckOutDate(checkOutDate),
    hotelPictures: [],
    name: getName(name),
    breakfastInfo: TripHelperV1.getBreakfastInfo(breakfast ?? ""),
    hotelNights: TripHelperV1.getHotelNights(
      getCheckInDate(checkInDate),
      getCheckOutDate(checkOutDate)
    ),
    location: {
      name: getCityName(address),
      iataCode: "",
      countryCode: getCountryCode(address),
      countryName: getCountryCode(address),
      cityName: getCityName(address),
      address: {
        lines: getLines(address),
        postalCode: getPostalCode(address),
        cityName: getCityName(address),
        countryCode: getCountryCode(address),
      },
      geoCode: {
        latitude: getLatitude(latitude),
        longitude: getLongitude(longitude),
      },
    },
  };

  useEffect(() => {
    dispatch({
      types: types.setTrip,
      payload: productToShare,
    });
    dispatch({
      type: types.addSegment,
      payload: { Trip_Product_Hotel: productToShare, id: getHotelProductId() },
    });
    dispatch({
      type: types.setInitialSegments,
      payload: { Trip_Product_Hotel: productToShare, id: getHotelProductId() },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <SegmentWrapper
      segment={{ Trip_Product_Hotel: productToShare, id: getHotelProductId() }}
      topDate={range}
      segmentType="hotel"
    >
      {header}
      {content}
    </SegmentWrapper>
  );
}

export default HotelSegmentContentV1;
