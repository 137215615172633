import { app } from "@microsoft/teams-js";
import ExpenseRedirectionInput from "../../../common/models/redirection/ExpenseRedirectionInput";
import RedirectionTypeEnum from "../../../common/models/redirection/RedirectionTypeEnum";
import HostSettings from "../../../utils/host.settings";

export function redirectToExpenseDashboard() {
  const deeplinkData: ExpenseRedirectionInput = {
    segmentType: RedirectionTypeEnum.EXPENSE_DASHBOARD,
  };

  const urlEncodedDeepLinkData: any = {
    subEntityId: JSON.stringify(deeplinkData),
  };
  const deepLink = `https://teams.microsoft.com/l/entity/${
    HostSettings.teamsAppId
  }/expenses?context=${encodeURIComponent(
    JSON.stringify(urlEncodedDeepLinkData)
  )}`;
  app.openLink(deepLink);
}

export function redirectToExpenseStatement(
  redirectionExpenseId: string | number
) {
  const deeplinkData: ExpenseRedirectionInput = {
    segmentType: RedirectionTypeEnum.EXPENSE_DASHBOARD,
    expenseId: redirectionExpenseId,
  };

  const urlEncodedDeepLinkData: any = {
    subEntityId: JSON.stringify(deeplinkData),
  };
  const deepLink = `https://teams.microsoft.com/l/entity/${
    HostSettings.teamsAppId
  }/expenses?context=${encodeURIComponent(
    JSON.stringify(urlEncodedDeepLinkData)
  )}`;
  app.openLink(deepLink);
}

export function redirectToCreateExpenseStatement() {
  const deeplinkData: ExpenseRedirectionInput = {
    segmentType: RedirectionTypeEnum.EXPENSE_CREATE,
  };

  const urlEncodedDeepLinkData: any = {
    subEntityId: JSON.stringify(deeplinkData),
  };
  const deepLink = `https://teams.microsoft.com/l/entity/${
    HostSettings.teamsAppId
  }/expenses?context=${encodeURIComponent(
    JSON.stringify(urlEncodedDeepLinkData)
  )}`;
  app.openLink(deepLink);
}

export function isExpenseRelatedSegmentType(segmentType: string | null) {
  return (
    segmentType === RedirectionTypeEnum.EXPENSE_DASHBOARD ||
    segmentType === RedirectionTypeEnum.EXPENSE_CREATE ||
    segmentType === RedirectionTypeEnum.CHAT_TRAVELER
  );
}
