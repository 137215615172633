import { Http } from "@amadeus-cytric/cytric-teams-react-common-library";
import AirRedirectionInput from "../../common/models/redirection/AirRedirectionInput";
import AirRedirectionParam from "../../common/models/redirection/AirRedirectionParam";
import CarRedirectionInput from "../../common/models/redirection/CarRedirectionInput";
import CarRedirectionParam from "../../common/models/redirection/CarRedirectionParam";
import ConfirmationPageRedirectionInput from "../../common/models/redirection/ConfirmationPageRedirectionInput";
import ExpenseRedirectionInput from "../../common/models/redirection/ExpenseRedirectionInput";
import HotelRedirectionInput from "../../common/models/redirection/HotelRedirectionInput";
import HotelRedirectionParam from "../../common/models/redirection/HotelRedirectionParam";
import RailRedirectionInput from "../../common/models/redirection/RailRedirectionInput";
import RailRedirectionParam from "../../common/models/redirection/RailRedirectionParam";
import RedirectionInput from "../../common/models/redirection/RedirectionInput";
import RedirectionTypeEnum from "../../common/models/redirection/RedirectionTypeEnum";
import TripApprovalRedirectionInput from "../../common/models/redirection/TripApprovalRedirectionInput";
import TripSegmentInput from "../../common/models/redirection/TripSegmentInput";
import RedirectionParamaterHelper from "../../helpers/RedirectionParamaterHelper";
import HostSettings from "../../utils/host.settings";

export default class RedirectionService {
  private static readonly SERVER_URL = HostSettings.getBaseAPI;

  private static readonly REDIRECTION_PATH = "api/corporation/redirect/";

  private static readonly KAFKA_REDIRECTION_PATH = "api/corporation/";

  private static async getDashboardCytricUrl(): Promise<any> {
    return Http.get(`${this.SERVER_URL}${this.REDIRECTION_PATH}dashboard`);
  }

  private static async getFlightCytricUrl(
    params: AirRedirectionParam
  ): Promise<any> {
    return Http.get(`${this.SERVER_URL}${this.REDIRECTION_PATH}flight`, {
      params,
    });
  }

  private static async postFlightCytricUrl(
    data: AirRedirectionParam
  ): Promise<any> {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return Http.post(`${this.SERVER_URL}${this.REDIRECTION_PATH}flight`, data);
  }

  private static async getRailCytricUrl(
    params: RailRedirectionParam
  ): Promise<any> {
    return Http.get(`${this.SERVER_URL}${this.REDIRECTION_PATH}rail`, {
      params,
    });
  }

  private static async postRailCytricUrl(
    data: RailRedirectionParam
  ): Promise<string> {
    return Http.post(`${this.SERVER_URL}${this.REDIRECTION_PATH}rail`, data);
  }

  private static async getRailCardCytricUrl(param: string): Promise<any> {
    return Http.get(
      `${this.SERVER_URL}${this.REDIRECTION_PATH}rail?bookingService=${param}`
    );
  }

  private static async getCarCytricUrl(
    params: CarRedirectionParam
  ): Promise<any> {
    return Http.get(`${this.SERVER_URL}${this.REDIRECTION_PATH}car`, {
      params,
    });
  }

  private static async postCarCytricUrl(
    data: CarRedirectionParam
  ): Promise<string> {
    return Http.post(`${this.SERVER_URL}${this.REDIRECTION_PATH}car`, data);
  }

  private static async getHotelCytricUrl(
    params: HotelRedirectionParam
  ): Promise<any> {
    return Http.get(`${this.SERVER_URL}${this.REDIRECTION_PATH}hotel`, {
      params,
    });
  }

  private static async postHotelCytricUrl(
    data: HotelRedirectionParam
  ): Promise<string> {
    return Http.post(`${this.SERVER_URL}${this.REDIRECTION_PATH}hotel`, data);
  }

  private static async getAddFlightCytricUrl(param: string): Promise<string> {
    return Http.get(
      `${this.SERVER_URL}${this.REDIRECTION_PATH}trips/${param}/segments/flight`
    );
  }

  private static async getAddRailCytricUrl(param: string): Promise<string> {
    return Http.get(
      `${this.SERVER_URL}${this.REDIRECTION_PATH}trips/${param}/segments/rail`
    );
  }

  private static async getAddHotelCytricUrl(param: string): Promise<string> {
    return Http.get(
      `${this.SERVER_URL}${this.REDIRECTION_PATH}trips/${param}/segments/hotel`
    );
  }

  private static async getAddCarCytricUrl(
    param: TripSegmentInput
  ): Promise<string> {
    return Http.get(
      `${this.SERVER_URL}${this.REDIRECTION_PATH}trips/${param.tripId}/segments/car?bookingService=${param.segmentType}`
    );
  }

  private static async getViewExpensesCytricUrl(params: any): Promise<any> {
    return Http.get(`${this.SERVER_URL}${this.REDIRECTION_PATH}your-expenses`, {
      params,
    });
  }

  private static async getCreateExpenseCytricUrl(): Promise<any> {
    return Http.get(
      `${this.SERVER_URL}${this.REDIRECTION_PATH}new-expense-statement`
    );
  }

  private static async getRedirectCytricUserProfileUrl(): Promise<any> {
    // NOTE: context=NULL is necesary to open "My User Profile" page in the new browser tab with all of user profile settings
    // as opposed to default limited settings used elsewhere in this teams app when context param is missing or is explicitly set to MST
    return Http.get(
      `${this.SERVER_URL}${this.REDIRECTION_PATH}user-profile?context=NULL`
    );
  }

  private static async getRedirectCytricPastTripsUrl(): Promise<any> {
    return Http.get(
      `${this.SERVER_URL}${this.REDIRECTION_PATH}past-trips?context=NULL`
    );
  }

  private static async getConfirmationPage(param: string): Promise<string> {
    return Http.get(
      `${this.SERVER_URL}${this.REDIRECTION_PATH}trips/${param}/overview`
    );
  }

  private static async getTravelArrangerDashboard(): Promise<string> {
    return Http.get(
      `${this.SERVER_URL}${this.REDIRECTION_PATH}travel-arranger/dashboard`
    );
  }

  private static async getTripApprovalPage(
    param: TripApprovalRedirectionInput
  ): Promise<string> {
    return Http.get(
      `${this.SERVER_URL}${this.REDIRECTION_PATH}trip-approval/${param.tripId}`
    );
  }

  private static async getTripConfirmApproveUrl(
    param: TripApprovalRedirectionInput
  ): Promise<string> {
    return Http.post(
      `${this.SERVER_URL}${this.KAFKA_REDIRECTION_PATH}trips/${param.tripId}/approve`,
      param
    );
  }

  private static async getTripConfirmRejectUrl(
    param: TripApprovalRedirectionInput
  ): Promise<string> {
    return Http.post(
      `${this.SERVER_URL}${this.KAFKA_REDIRECTION_PATH}trips/${param.tripId}/reject`,
      param
    );
  }

  public static async getCytricUrl(redirectionInput: RedirectionInput) {
    switch (redirectionInput.segmentType) {
      case RedirectionTypeEnum.FLIGHT: {
        const airParam =
          RedirectionParamaterHelper.transformAirRedirectionInput(
            redirectionInput as AirRedirectionInput
          );
        return this.getFlightCytricUrl(airParam);
      }
      case RedirectionTypeEnum.ADD_FLIGHT: {
        const flightParam =
          RedirectionParamaterHelper.transformAddFlightRedirectionInput(
            redirectionInput as ConfirmationPageRedirectionInput
          );
        return this.getAddFlightCytricUrl(flightParam);
      }
      case RedirectionTypeEnum.CAR: {
        const carParam =
          RedirectionParamaterHelper.transformCarRedirectionInput(
            redirectionInput as CarRedirectionInput
          );
        return this.getCarCytricUrl(carParam);
      }
      case RedirectionTypeEnum.ADD_CAR: {
        const addCarParam =
          RedirectionParamaterHelper.transformAddCarRedirectionInput(
            redirectionInput as TripSegmentInput
          );
        return this.getAddCarCytricUrl(addCarParam);
      }
      case RedirectionTypeEnum.ADD_TRANSFER: {
        const addTransferParam =
          RedirectionParamaterHelper.transformAddTransferRedirectionInput(
            redirectionInput as TripSegmentInput
          );
        return this.getAddCarCytricUrl(addTransferParam);
      }
      case RedirectionTypeEnum.HOTEL_STOP:
      case RedirectionTypeEnum.HOTEL: {
        const hotelParam =
          RedirectionParamaterHelper.transformHotelRedirectionInput(
            redirectionInput as HotelRedirectionInput
          );
        return this.getHotelCytricUrl(hotelParam);
      }
      case RedirectionTypeEnum.ADD_HOTEL: {
        const hotelParam =
          RedirectionParamaterHelper.transformAddHotelRedirectionInput(
            redirectionInput as ConfirmationPageRedirectionInput
          );
        return this.getAddHotelCytricUrl(hotelParam);
      }
      case RedirectionTypeEnum.ADD_RAIL: {
        const railParam =
          RedirectionParamaterHelper.transformAddRailRedirectionInput(
            redirectionInput as ConfirmationPageRedirectionInput
          );
        return this.getAddRailCytricUrl(railParam);
      }
      case RedirectionTypeEnum.RAIL: {
        const railParam =
          RedirectionParamaterHelper.transformRailRedirectionInput(
            redirectionInput as RailRedirectionInput
          );
        return this.getRailCytricUrl(railParam);
      }
      case RedirectionTypeEnum.EXPENSE_DASHBOARD: {
        const expenseParam =
          RedirectionParamaterHelper.transformExpenseRedirectionInput(
            redirectionInput as ExpenseRedirectionInput
          );
        return this.getViewExpensesCytricUrl(expenseParam);
      }
      case RedirectionTypeEnum.EXPENSE_CREATE: {
        return this.getCreateExpenseCytricUrl();
      }
      case RedirectionTypeEnum.DASHBOARD: {
        return this.getDashboardCytricUrl();
      }
      case RedirectionTypeEnum.USER_PROFILE: {
        return this.getRedirectCytricUserProfileUrl();
      }
      case RedirectionTypeEnum.PAST_TRIPS: {
        return this.getRedirectCytricPastTripsUrl();
      }
      case RedirectionTypeEnum.RAIL_CARD: {
        const railCardParam =
          RedirectionParamaterHelper.transformRailCardRedirectionInput(
            redirectionInput as any
          );

        return this.getRailCardCytricUrl(railCardParam);
      }
      case RedirectionTypeEnum.CAR_CARD: {
        const carCardParam =
          RedirectionParamaterHelper.transformCarCardRedirectionInput(
            redirectionInput as CarRedirectionInput
          );

        return this.getCarCytricUrl(carCardParam);
      }
      case RedirectionTypeEnum.FLIGHT_CARD: {
        const flightCardParam =
          RedirectionParamaterHelper.transformFlightCardRedirectionInput(
            redirectionInput as any
          );

        return this.getFlightCytricUrl(flightCardParam);
      }
      case RedirectionTypeEnum.CONFIRMATION_PAGE: {
        const confirmationPageParam =
          RedirectionParamaterHelper.transformConfirmationPageRedirectionInput(
            redirectionInput as ConfirmationPageRedirectionInput
          );
        return this.getConfirmationPage(confirmationPageParam);
      }
      case RedirectionTypeEnum.TRAVEL_ARRANGER_DASHBOARD: {
        return this.getTravelArrangerDashboard();
      }
      case RedirectionTypeEnum.TRAVEL_ARRANGER_FLIGHT: {
        const airParam =
          RedirectionParamaterHelper.transformAirRedirectionInput(
            redirectionInput as AirRedirectionInput
          );
        return this.postFlightCytricUrl(airParam);
      }
      case RedirectionTypeEnum.TRAVEL_ARRANGER_RAIL: {
        const railParam =
          RedirectionParamaterHelper.transformRailRedirectionInput(
            redirectionInput as RailRedirectionInput
          );
        return this.postRailCytricUrl(railParam);
      }
      case RedirectionTypeEnum.TRAVEL_ARRANGER_CAR: {
        const carParam =
          RedirectionParamaterHelper.transformCarRedirectionInput(
            redirectionInput as CarRedirectionInput
          );
        return this.postCarCytricUrl(carParam);
      }
      case RedirectionTypeEnum.TRAVEL_ARRANGER_HOTEL: {
        const hotelParam =
          RedirectionParamaterHelper.transformHotelRedirectionInput(
            redirectionInput as HotelRedirectionInput
          );
        return this.postHotelCytricUrl(hotelParam);
      }
      case RedirectionTypeEnum.TRIP_APPROVAL_PAGE: {
        const tripApprovalPageParam =
          RedirectionParamaterHelper.transformTripApprovalRedirectionInput(
            redirectionInput as TripApprovalRedirectionInput
          );
        return this.getTripApprovalPage(tripApprovalPageParam);
      }
      case RedirectionTypeEnum.TRIP_APPROVE_ACTION: {
        const tripApprovalPageParam =
          RedirectionParamaterHelper.transformTripApprovalRedirectionInput(
            redirectionInput as TripApprovalRedirectionInput
          );
        return this.getTripConfirmApproveUrl(tripApprovalPageParam);
      }
      case RedirectionTypeEnum.TRIP_REJECT_ACTION: {
        const tripApprovalPageParam =
          RedirectionParamaterHelper.transformTripApprovalRedirectionInput(
            redirectionInput as TripApprovalRedirectionInput
          );
        return this.getTripConfirmRejectUrl(tripApprovalPageParam);
      }
      default: {
        return this.getDashboardCytricUrl();
      }
    }
  }
}
