import { telemetryService } from "@amadeus-cytric/cytric-teams-react-common-library";
import { DismissCircle16Regular } from "@fluentui/react-icons";
import {
  Button,
  MenuButton,
  MoreIcon,
  ShareGenericIcon,
} from "@fluentui/react-northstar";
import { BotUrlDialogInfo, dialog } from "@microsoft/teams-js";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import GetAppRoot from "../../../../utils/GetAppRoot";
import { KeepSessionActions } from "../../../../utils/KeepSessionFunctions";
import ActivityClass from "../../../../utils/KeepUserActivityContext";
import HostSettings from "../../../../utils/host.settings";
import CancelPopUp from "../../cancel/CancelPopUp";
import { useCancelPopUp } from "../../cancel/context/CancelPopUpContext";
import Styles from "./ContextMenu.module.scss";

export const onShowShareTrip = (trip: any, t: any): void => {
  if (trip) {
    const taskModuleUrl = "share-join";
    const taskModuleInfo: BotUrlDialogInfo = {
      title: t("upcoming_trips_dashboard.upcomingTrips.tripCardShareTitle"),
      url: `${GetAppRoot()}/index.html#/${taskModuleUrl}?origin=sharetrip&tripId=${
        trip.id
      }`,
      completionBotId: HostSettings.getBotId,
      size: {
        width: 600,
        height: 570,
      },
    };
    telemetryService.trackEvent({ name: "travel-tripDashboard-sharePopup" });
    dialog.url.bot.open(taskModuleInfo);
  }
};

function ContextMenu({ tripName, trip }: any) {
  const [open, setOpen] = useState(false);
  const { dialogParams, setDialogParams } = useCancelPopUp();
  const { openDialog } = dialogParams;
  const { t } = useTranslation();

  const userActivity = new ActivityClass();

  useEffect(() => {
    const userActivityValue = userActivity.get(
      KeepSessionActions.DASHBOARD_CANCELPOPUP
    );
    if (userActivityValue) {
      setDialogParams(userActivityValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = (e: any) => {
    e.stopPropagation();
    if (
      e.target.innerText ===
        t(
          "upcoming_trips_dashboard.tripDetails.breadcrumb.buttons.cancelTrip"
        ) &&
      e.target.className.includes("ui-menu__item")
    ) {
      userActivity.update(KeepSessionActions.DASHBOARD_CANCELPOPUP, {
        openDialog: true,
        id: e.currentTarget.id,
      });

      setDialogParams({ openDialog: true, id: e.currentTarget.id });
    }
  };

  const handlePopUpClick = (e: any) => {
    e.stopPropagation();
    setDialogParams({ openDialog: false, id: "" });
  };
  const shareButtonLabel = `${t(
    "upcoming_trips_dashboard.upcomingTrips.contextMenuShareButtonAltText"
  )} ${tripName}`;

  const getItems = () => [
    {
      key: "share-button",
      content: t("upcoming_trips_dashboard.upcomingTrips.tripCardShare"),
      icon: <ShareGenericIcon outline />,
      "aria-label": shareButtonLabel,
      "data-testid": "share-trip-button",
      onClick: () => onShowShareTrip(trip, t),
    },
    {
      key: `cancel-button-${trip.id}`,
      icon: <DismissCircle16Regular />,
      content: t(
        "upcoming_trips_dashboard.tripDetails.breadcrumb.buttons.cancelTrip"
      ),
      "data-testid": "cancel-trip-button",
      id: trip.id,
    },
  ];

  return (
    <>
      <MenuButton
        trigger={
          <Button
            icon={<MoreIcon />}
            className={Styles.contextMenuButton}
            iconOnly
            text
            aria-label={t("contextMenuIconLabel")}
            title={t("upcoming_trips_dashboard.upcomingTrips.contextMenuLabel")}
            data-testid="share-trip-card-button"
          />
        }
        open={open}
        onOpenChange={() => setOpen(!open)}
        onMenuItemClick={(e: any) => handleClick(e)}
        onClick={(e: any) => handleClick(e)}
        menu={{
          items: getItems(),
        }}
        aria-label={t(
          "upcoming_trips_dashboard.upcomingTrips.contextMenuLabel"
        )}
        data-testid="trip-card-menu"
      />

      {openDialog && (
        <div
          role="presentation"
          onClick={handlePopUpClick}
          onKeyDown={handlePopUpClick}
          data-testid="cancel-trip-dialog-parent"
        >
          <CancelPopUp id={trip.id} location="dashboard" />
        </div>
      )}
    </>
  );
}

export default ContextMenu;
