import { Dropdown, Label } from "@fluentui/react-northstar";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import WithCommentsStyles from "./WithCommentsContainer.module.scss";

function ReasonsContainer({
  reasonsOption,
  rejectReasons,
  setReason,
  setIsReasonEmpty,
  defaultReason,
}: any) {
  const [selectedReason, setSelectedReason] = useState<any>();

  const { t } = useTranslation("translation", {
    keyPrefix: "tripApproval.comments",
  });

  const CHOOSE_AN_OPTION = t("reject.chooseAnOption");

  const reasons = useMemo(() => {
    if (!rejectReasons || (rejectReasons && rejectReasons.length === 0)) {
      return [];
    }

    if (reasonsOption === "optional") {
      const placeholder = {
        text: CHOOSE_AN_OPTION,
        value: "placeholder",
      };
      return [placeholder, ...rejectReasons].map((reason: any) => reason.text);
    }
    return rejectReasons.map((reason: any) => reason.text);
  }, [reasonsOption, rejectReasons, CHOOSE_AN_OPTION]);

  useEffect(() => {
    setReason(defaultReason);
    setIsReasonEmpty(defaultReason?.length === 0);
  }, [setIsReasonEmpty, setReason, defaultReason]);

  function handleOnChange(_e: any, data: any): void {
    if (data.value !== CHOOSE_AN_OPTION) {
      setReason(data.value);
      setIsReasonEmpty(data?.value?.length === 0);
    }
    setSelectedReason(data.value);
  }

  return (
    <div className={WithCommentsStyles.reasonsContainer}>
      <Label
        fluid
        content={[t("reject.selectAReason"), t(reasonsOption)].join(" ")}
        className={WithCommentsStyles.label}
        styles={(theme) => ({
          color: theme.theme.siteVariables.colorScheme?.default.foreground2,
          backgroundColor:
            theme.theme.siteVariables?.colorScheme?.default.background,
        })}
      />

      <Dropdown
        id="dropDown"
        fluid
        data-testid="dropDown-reason"
        placeholder={CHOOSE_AN_OPTION}
        items={reasons}
        onChange={(e: any, data: any) => handleOnChange(e, data)}
        aria-label={selectedReason}
        defaultValue={defaultReason}
        className={WithCommentsStyles.dropdown}
      />
    </div>
  );
}

export default ReasonsContainer;
