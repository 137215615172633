import CheckFeatureToggle from "../../../../utils/CheckFeatureToggle";
import { FeatureToggleDefinition } from "../../../../utils/constants";

const CheckFeatureEnabled = () => {
  const isTRIPPUser = CheckFeatureToggle(
    FeatureToggleDefinition.TRIPPUpcomingTrips.id
  );

  if (!isTRIPPUser) {
    return true;
  }
  return false;
};

export default CheckFeatureEnabled;
