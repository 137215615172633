import { Checkbox } from "@fluentui/react-northstar";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import CancelContext from "../../../upcoming-trips-dashboard/cancel/CancelContext";
import { useDispatch, useStore } from "../../context/ShareMyRideContext";
import { types } from "../../context/ShareMyRideReducer";
import getEnableParams from "../../utils/GetEnableParams";
import { appInsightsTracker } from "../../utils/shareMyRideUtils";
import {
  disableShare,
  enableShare,
} from "../share-my-ride-container/functions/shareMyRideApi";
import Styles from "./ShareMyRideButton.module.scss";

interface ShareMyRideButtonModel {
  date: string;
  isMobile: boolean;
  segment: any;
  userIsSharing: boolean;
  loading: boolean;
  origin: string;
}

function ShareMyRideButton({
  date,
  isMobile,
  segment,
  userIsSharing,
  loading,
  origin,
}: ShareMyRideButtonModel) {
  const dispatch = useDispatch();
  const { tripId, productsToShare } = useStore();
  const buttonClass = origin === "content" ? Styles.content : Styles.selection;

  const { t } = useTranslation("translation", {
    keyPrefix: "shareMyRide",
  });

  const currentProduct = productsToShare.filter(
    (prod: any) => prod.date === date
  )[0];

  const enableParams = getEnableParams(segment);

  const handleEnabling = async () => {
    const polling = "POST";
    if (enableParams.locationCode) {
      dispatch({ type: types.setGlobalLoading, payload: true });
      enableShare(tripId, enableParams, dispatch, polling, currentProduct);
    } else {
      dispatch({
        type: types.setErrorEnableAll,
        payload: "errorEnableIndividual",
      });
    }
    appInsightsTracker(
      { name: "travel-shareRide-activation" },
      {
        tripId,
        isActivated: true,
        numberOfPeople: segment.numberOfPeopleSharing,
      }
    );
  };

  const handleDisabling = () => {
    const polling = "DELETE";
    const enableAll = false;
    if (origin === "content") {
      dispatch({
        type: types.setActiveSegment,
        payload: { ...currentProduct, sharingEnabled: false },
      });
    } else {
      dispatch({ type: types.setActiveSegment, payload: null });
    }
    disableShare(
      tripId,
      enableParams,
      dispatch,
      polling,
      enableAll,
      currentProduct
    );
    appInsightsTracker(
      { name: "travel-shareRide-activation" },
      {
        tripId,
        isActivated: false,
        numberOfPeople: segment.numberOfPeopleSharing,
      }
    );
  };

  const isSharingArgs = {
    handleOnClicK: userIsSharing ? handleDisabling : handleEnabling,
    label: userIsSharing ? t("disableButton") : t("enableButton"),
  };

  const cancelContext = useContext(CancelContext);

  return (
    <Checkbox
      toggle
      onClick={isSharingArgs.handleOnClicK}
      disabled={loading}
      aria-disabled={loading}
      checked={userIsSharing}
      aria-checked={userIsSharing}
      role="checkbox"
      data-joyride-id="shareTransfer"
      data-testid="smr-toggle"
      aria-label="smr-toggle"
      className={[
        Styles.SMRToggle,
        isMobile ? Styles.disableButtonMobile : Styles.removeFocus,
        buttonClass,
      ].join(" ")}
      aria-hidden={cancelContext.cancelPopup}
    />
  );
}

export default ShareMyRideButton;
